import React from "react";
import "./datetimeInput.css"

export const DatetimeInput = (props) => {
  return (
    <div className="date-input-container">
      <label className="input-label" htmlFor={props.fieldName + "-input"}>{props.labelName}</label>
      <input
        id={props.fieldName + "-input"}
        name={props.fieldName + "-input"}
        className="datetime-field"
        type="datetime-local"
        value={props.valueProp}
        onChange={props.onChangeFunc}
      />
    </div>
  )
}