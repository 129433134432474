import React from "react";
import { PageTemplate } from "../../components/page-template/PageTemplate";
import "./dashboardPage.css";
import { Frame } from "../../components/frame/Frame";
import { TimeSlots } from "../../components/time-slots/TimeSlots";
import { UpcomingEvents } from "./upcoming-events/UpcomingEvents";
import { NewNotifications } from "./new-notifications/NewNotifications";

export const DashboardPage = () => {
  // View
  return (
    <PageTemplate pageHeader="Dashboard" layout="dual-layout">
      <div className="frame-container-left">
        <Frame header="On Today" line={true} fullSize={true}>
          <div className="on-today-inner-frame-container inner-frame-container scrollable-frame">
            <TimeSlots num={24} />
          </div>
        </Frame>
      </div>
      <div className="frame-group">
        <div className="frame-container-right">
          <Frame header="Upcoming Events" line={true} fullSize={true}>
            <UpcomingEvents />
          </Frame>
        </div>
        <div className="frame-container-right">
          <Frame header="Notifications" line={true} fullSize={true}>
            <NewNotifications />
          </Frame>
        </div>
      </div>
    </PageTemplate>
  );
};
