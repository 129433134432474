import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_ENDPOINT } from "../api";

const initialState = {
  searchType: "id",
  searchTerm: "",
  selectedProducts: [],
  selectedProductsList: [],
};

export const getProductsData = createAsyncThunk(
  "productsData/getProductsData",
  async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}products`, {
        credentials: "include",
      });
      let result = await response.json();
      if (result.success) {
        return result.data;
      }
    } catch (err) {
      console.error(err);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload.toLowerCase();
    },

    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },

    setSelectedProducts: (state, action) => {
      state.selectedProducts.push(action.payload);
    },

    removeSelectedProducts: (state, action) => {
      state.selectedProducts = state.selectedProducts.filter(
        (product) => product !== action.payload
      );
    },

    clearSelectedProducts: (state, action) => {
      state.selectedProducts = [];
    },

    setSelectedProductsList: (state, action) => {
      state.selectedProductsList = action.payload;
    },

    clearSelectedProductsList: (state, action) => {
      state.selectedProductsList = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProductsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productsList = action.payload;
      })
      .addCase(getProductsData.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setSearchTerm,
  setSearchType,
  setSelectedProducts,
  removeSelectedProducts,
  clearSelectedProducts,
  setSelectedProductsList,
  clearSelectedProductsList,
} = productsSlice.actions;

export default productsSlice.reducer;
