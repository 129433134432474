import React from "react";
import "./createNewEventFormAddTasks.css";
import { TextInput } from "../../../../../components/input-fields/text-input/TextInput";
import { DateInput } from "../../../../../components/input-fields/date-input/DateInput";
import { TimeInput } from "../../../../../components/input-fields/time-input/TimeInput";
import { ProductSelector } from "../../../../../components/selectors/product-selector/ProductSelector";
import { TaskTag } from "./task-tag/TaskTag";
import { LargeTextInput } from "../../../../../components/input-fields/large-text-input/LargeTextInput";
import { NoContentMessage } from "../../../../../components/no-content-messages/no-content-message/NoContentMessage";
import { AssigneeSelector } from "../../../../../components/selectors/assignee-selector/AssigneeSelector";
import { ClipLoader } from "react-spinners";

export const CreateNewEventFormAddTasks = (props) => {
  // Model
  const { taskState } = props;

  // Controllers

  // View
  return (
    <div className="create-event-form">
      <div className="create-event-form-add-tasks scrollable-frame">
        <div className="create-event-form-add-tasks-inputs">
          <div className="create-event-form-add-tasks-inputs-section">
            <div className="create-event-name-container">
              <TextInput
                labelName="Task Name"
                fieldName="task-name"
                valueProp={taskState.name}
                onChangeFunc={props.taskNameFunc}
                maxLengthProp={60}
                isRequired={true}
              />
            </div>
            <div className="create-event-dates-container">
              <div className="create-event-date-time">
                <DateInput
                  labelName="Start Date"
                  fieldName="task-start-date"
                  valueProp={taskState.startDate}
                  onChangeFunc={props.taskStartDateFunc}
                  isRequired={true}
                  minDate={props.minStartDate}
                  maxDate={props.maxDate}
                  dateConflict={props.startDateConflict}
                />
                <TimeInput
                  labelName="*Start Time"
                  time={taskState.startTime}
                  handleTimeSelect={props.taskStartTimeFunc}
                  isChecked={props.taskStartTimeCheck}
                  handleCheck={props.taskStartTimeCheckFunc}
                />
              </div>
              <div className="create-event-date-time">
                <DateInput
                  labelName="End Date"
                  fieldName="task-end-date"
                  valueProp={taskState.endDate}
                  onChangeFunc={props.taskEndDateFunc}
                  isRequired={true}
                  minDate={props.minEndDate}
                  maxDate={props.maxDate}
                  dateConflict={props.endDateConflict}
                />
                <TimeInput
                  labelName="*End Time"
                  time={taskState.endTime}
                  handleTimeSelect={props.taskEndTimeFunc}
                  isChecked={props.taskEndTimeCheck}
                  handleCheck={props.taskEndTimeCheckFunc}
                />
              </div>
            </div>
          </div>

          <div className="create-event-form-add-tasks-inputs-section">
            <div className="create-event-task-descripion-container">
              <LargeTextInput
                labelName="Task Description"
                fieldName="task-description"
                valueProp={taskState.description}
                onChangeFunc={props.taskDescriptionFunc}
              />
            </div>
          </div>
        </div>

        <div className="create-event-product-selector-container">
          <ProductSelector
            addProductsBtnFunc={props.addProductsBtnFunc}
            productList={props.productList}
            selectedProducts={props.selectedProducts}
            addToSelectedProducts={props.addToSelectedProducts}
            removeFromSelectedProducts={props.removeFromSelectedProducts}
            productTagRemoveFunc={props.productTagRemoveFunc}
          />
        </div>
        <div className="create-event-assignees-list">
          <AssigneeSelector
            assigneeList={props.assigneeList}
            handleAddAssigneeBtn={props.handleAddAssigneeBtn}
            handleRemoveAssigneeBtn={props.handleRemoveAssigneeBtn}
          />
        </div>
      </div>
      <div className="create-event-add-task-btn-container">
        {props.editingTask ? (
          <div className="create-event-edit-task-btns-container">
            {props.saveBtnState ? (
              <button className="btn" onClick={props.saveEditTask}>
                Save
              </button>
            ) : (
              <button className="disabled-btn" disabled>
                Save
              </button>
            )}
            <button className="btn" onClick={props.cancelEditTask}>
              Cancel
            </button>
          </div>
        ) : props.addTaskBtnState ? (
          <button
            className="create-event-add-task-btn btn"
            onClick={props.addTaskBtnFunc}
          >
            Add
          </button>
        ) : (
          <button className="create-event-add-task-btn disabled-btn" disabled>
            Add
          </button>
        )}
      </div>
      <hr className="create-event-task-list-divider" />
      {/* ----------------------------- Task List --------------------------------- */}
      <div className="create-event-tasks-list">
        <label className="input-label">Tasks List</label>
        <div className="create-event-tasks-list-container">
          <div className="create-event-tasks-list-container-inner  horizontal-scroll">
            {props.taskList.length > 0 ? (
              props.taskList.map((task) => {
                return (
                  <TaskTag
                    key={task.name}
                    taskName={task.name}
                    onClickFunc={props.removeTaskBtn}
                    dateConflict={task.dateConflict}
                    taskTagClickFunc={props.taskTagClickFunc}
                    isSelected={task.isSelected}
                  />
                );
              })
            ) : (
              <NoContentMessage message="No Tasks" />
            )}
          </div>
        </div>
      </div>
      {/* ----------------------------- Footer --------------------------------- */}
      <div className="create-event-footer">
        <div className="create-event-footer-section">
          <button
            className="back-new-event-btn btn"
            onClick={props.backBtnFunc}
          >
            Back
          </button>
        </div>
        <div className="create-event-footer-section">
          {props.isLoading ? (
            <div>
              <ClipLoader
                size={20}
                loading={props.isLoading}
                color="var(--activeGrey)"
              />
            </div>
          ) : props.confirmBtnState ? (
            <button
              className="confirm-new-event-btn btn"
              onClick={props.confirmBtnFunc}
            >
              Confirm
            </button>
          ) : (
            <button className="confirm-new-event-btn disabled-btn" disabled>
              Confirm
            </button>
          )}
        </div>
        <div className="create-event-footer-section">
          <button
            className="cancel-new-event-btn btn"
            onClick={props.cancelBtnFunc}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
