import React, { useState, useEffect } from "react";
import "./newNotificationRow.css";
import { trimStringToMaxLength } from "../../../../functions/trimStringToMaxLength";

export const NewNotificationRow = (props) => {
  // Model
  const [maxMessageState, setMaxMessageState] = useState("");
  const message = trimStringToMaxLength(props.message, maxMessageState);
  
  // Controllers
  useEffect(() => {
    if (window.innerWidth < 1300) {
      setMaxMessageState(50);
    } else if (window.innerWidth < 1400) {
      setMaxMessageState(60);
    } else if (window.innerWidth < 1624) {
      setMaxMessageState(70);
    } else if (window.innerWidth < 1700) {
      setMaxMessageState(80);
    } else {
      setMaxMessageState(90);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1300) {
        setMaxMessageState(50);
      } else if (window.innerWidth < 1400) {
        setMaxMessageState(60);
      } else if (window.innerWidth < 1624) {
        setMaxMessageState(70);
      } else if (window.innerWidth < 1700) {
        setMaxMessageState(80);

      } else {
        setMaxMessageState(90);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  // View
  return (
    <div className="new-notification-row-background">
      <div className="new-notification-row-container">
          <span>{message}</span>
      </div>
    </div>
  );
};
