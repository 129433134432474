import React from "react";
import "./manageUsersRow.css";
import { ItemRow } from "../../../../components/item-row/ItemRow";
import { OptionButton } from "../../../../components/option-button/OptionButton";

export const ManageUsersRow = (props) => {
  // Model
  const user = props.user;
  const date = new Date(user.last_sign_in);
  const lastSignIn = date.toLocaleString()
  // Controllers

  // View
  return (
    <ItemRow>
      <div className="manage-users-row">
        <div className="segment">
          <span>{user.full_name}</span>
        </div>
        <div className="segment">
          <span>{user.sign_in_email}</span>
        </div>
        <div className="segment">
          <span>{lastSignIn}</span>
        </div>
        <div className="segment">
          <div className="manage-user-btns-container">
            {user.activated ? (
              <button
                className="activate-deactivate-btn"
                onClick={props.handleActivatedBtn}
              >
                <svg
                  className="activated-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 15 21"
                >
                  <g>
                    <path d="m6.63,16.51c-.3,0-.58-.13-.77-.36l-1.63-1.94c-.35-.42-.3-1.05.12-1.41.42-.35,1.05-.3,1.41.12l.86,1.03,2.6-3.1c.36-.42.99-.48,1.41-.12.42.35.48.99.12,1.41l-3.37,4.02c-.19.23-.47.36-.77.36Z" />
                    <path d="m7.5,6c-.69,0-1.36.09-2,.28v-2.28c0-1.1.9-2,2-2s2,.9,2,2v1h2v-1c0-2.2-1.8-4-4-4S3.5,1.8,3.5,4v3.17c-2.11,1.32-3.5,3.66-3.5,6.33,0,4.14,3.36,7.5,7.5,7.5s7.5-3.36,7.5-7.5-3.36-7.5-7.5-7.5Zm0,13c-3.03,0-5.5-2.47-5.5-5.5s2.47-5.5,5.5-5.5,5.5,2.47,5.5,5.5-2.46,5.5-5.5,5.5Z" />
                  </g>
                </svg>
              </button>
            ) : (
              <button
                className="activate-deactivate-btn"
                onClick={props.handleDeactivatedBtn}
              >
                <svg
                  className="deactivated-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 15 20"
                >
                  <g>
                    <path d="m11.5,6.16v-2.16c0-2.2-1.8-4-4-4S3.5,1.8,3.5,4v2.16c-2.1,1.32-3.5,3.67-3.5,6.34,0,4.14,3.36,7.5,7.5,7.5s7.5-3.36,7.5-7.5c0-2.67-1.4-5.02-3.5-6.34Zm-6-2.16c0-1.1.9-2,2-2s2,.9,2,2v1.28c-.63-.19-1.31-.28-2-.28s-1.37.09-2,.28v-1.28Zm2,14c-3.03,0-5.5-2.47-5.5-5.5s2.47-5.5,5.5-5.5,5.5,2.47,5.5,5.5-2.47,5.5-5.5,5.5Z" />
                    <path d="m10.46,14.04c.39.39.39,1.03,0,1.42-.2.19-.45.29-.71.29s-.51-.1-.71-.29l-1.54-1.54-1.54,1.54c-.2.19-.45.29-.71.29s-.51-.1-.71-.29c-.39-.39-.39-1.03,0-1.42l1.54-1.54-1.54-1.54c-.39-.39-.39-1.03,0-1.42.39-.39,1.03-.39,1.42,0l1.54,1.54,1.54-1.54c.39-.39,1.03-.39,1.42,0,.39.39.39,1.03,0,1.42l-1.54,1.54,1.54,1.54Z" />
                  </g>
                </svg>
              </button>
            )}
            <OptionButton
              type="user"
              handleResetPassword={props.handleResetPasswordBtn}
            />
          </div>
        </div>
      </div>
    </ItemRow>
  );
};
