import React from "react";
import "./dropdownInput.css";

export const DropdownInput = (props) => {
  if (props.isRequired) {
    return (
      <div className="dropdown-input-container">
        <label className="input-label" htmlFor={props.fieldName + "-input"}>
          {`*${props.labelName}`}
        </label>
        <select
          id={props.fieldName + "-input"}
          name={props.fieldName + "-input"}
          className="dropdown-field"
          value={props.valueProp}
          onChange={props.onChangeFunc}
          required
        >
          <option key="default" value="">N/A</option>
          {props.options.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div className="dropdown-input-container">
      <label className="input-label" htmlFor={props.fieldName + "-input"}>
        {props.labelName}
      </label>
      <select
        id={props.fieldName + "-input"}
        name={props.fieldName + "-input"}
        className="dropdown-field"
        value={props.valueProp}
        onChange={props.onChangeFunc}
      >
        <option key="default" value="">N/A</option>
        {props.options.map((option) => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};
