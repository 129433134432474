import { API_ENDPOINT } from "..";
import { logEvents } from "../log-calls/logEvents";
import { getTimeStamp } from "../../functions/getTimeStamp";

export const deleteEventById = async (eventId) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}events/delete-event/${eventId}`,
      {
        method: "DELETE",
      }
    );
    const res = await response.json();
    await logEvents({
      eventId,
      action: "Event deleted.",
      response: res.success ? "Success" : "Fail",
      dateTime: getTimeStamp(),
    });
    return res;
  } catch (error) {
    console.error(`There was an error ${error}`);
    throw error;
  }
};
