import React from "react";
import { FrameHeader } from "../frame-header/FrameHeader";
import "./frame.css";

export const Frame = (props) => {
  const { children } = props;
  if (props.header && !props.fullSize) {
    return (
      <div className="frame include-header">
        <FrameHeader header={props.header} line={props.line} />
        {children}
      </div>
    );
  } else if (props.fullSize && !props.header) {
    return <div className="frame full-size">{children}</div>;
  } else if (props.fullSize && props.header) {
    return (
      <div className="frame include-header full-size">
        <FrameHeader header={props.header} line={props.line} />
        {children}
      </div>
    );
  } else {
    return <div className="frame">{children}</div>;
  }
};
