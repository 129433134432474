import React from "react";
import "./dateInput.css";

export const DateInput = (props) => {
  const currentDate = new Date();
  // Get the current date in the user's timezone
  const userTimezoneOffset = new Date().getTimezoneOffset();
  const userCurrentDate = new Date(
    currentDate.getTime() - userTimezoneOffset * 60 * 1000
  );
  // Format the date for the minDate property
  const formattedMinDate = userCurrentDate.toISOString().split("T")[0];
  const minDate = props.minDate || formattedMinDate;
  const maxDate = props.maxDate || null;
  if (props.isRequired) {
    return (
      <div className="date-input-container">
        <label className="input-label" htmlFor={props.fieldName + "-input"}>
        {`*${props.labelName}`}
        </label>
        <input
          id={props.fieldName + "-input"}
          name={props.fieldName + "-input"}
          className={
            props.dateConflict
              ? "date-input date-conflict"
              : "date-input no-date-conflict"
          }
          type="date"
          value={props.valueProp}
          onChange={props.onChangeFunc}
          min={minDate}
          max={maxDate}
          required
        />
      </div>
    );
  } else {
    return (
      <div className="date-input-container">
        <label className="input-label" htmlFor={props.fieldName + "-input"}>
          {props.labelName}
        </label>
        <input
          id={props.fieldName + "-input"}
          name={props.fieldName + "-input"}
          className={
            props.dateConflict
              ? "date-input date-conflict"
              : "date-input no-date-conflict"
          }
          type="date"
          value={props.valueProp}
          min={minDate}
          max={maxDate}
          onChange={props.onChangeFunc}
        />
      </div>
    );
  }
};
