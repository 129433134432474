import React from "react";
import "./textInput.css";

export const TextInput = (props) => {
  // Model
  const maxLength = props.maxLengthProp || 100;

  // Controllers
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  // View
  if (props.isRequired) {
    return (
      <div className="text-input-container">
        <div>
          <label className="input-label" htmlFor={props.fieldName + "-input"}>
            {`*${props.labelName}`}
          </label>
          {props.valueProp.length === maxLength && (
            <span className="max-character-limit">
              Character Limit {maxLength}
            </span>
          )}
        </div>
        <input
          id={props.fieldName + "-input"}
          name={props.fieldName + "-input"}
          className="text-input"
          type="text"
          placeholder={props.labelName}
          value={props.valueProp}
          onChange={props.onChangeFunc}
          autoComplete={props.autoComplete || "off"}
          maxLength={maxLength}
          onKeyDown={handleKeyPress}
          required
        />
      </div>
    );
  }

  return (
    <div className="text-input-container">
      <label className="input-label" htmlFor={props.fieldName + "-input"}>
        {props.labelName}
      </label>
      <input
        id={props.fieldName + "-input"}
        name={props.fieldName + "-input"}
        className="text-input"
        type="text"
        placeholder={props.labelName}
        value={props.valueProp}
        onChange={props.onChangeFunc}
        autoComplete={props.autoComplete || "off"}
        onKeyDown={handleKeyPress}
        maxLength={maxLength}
      />
      {props.valueProp.length === maxLength && (
        <span>Max Characters {maxLength}</span>
      )}
    </div>
  );
};
