import React, { useEffect, useState } from "react";
import "./eventDetailsModal.css";
import { getAllTasksByEventId } from "../../../api/task-calls/getAllTasksByEventId";
import { IsLoadingMessage } from "../../no-content-messages/is-loading-message/IsLoadingMessage";
import { NoContentMessage } from "../../no-content-messages/no-content-message/NoContentMessage";
import { getProductsByTaskId } from "../../../api/product-calls/getProductsByTaskId";

export const EventDetailsModal = (props) => {
  // Model
  const { eventDetails } = props;
  const [tasksState, setTasksState] = useState({});
  const [selectedTaskState, setSelectedTaskState] = useState({});
  const [productsState, setProductsState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isProductsLoadingState, setIsProductsLoadingState] = useState(false);

  // Controllers
  const getTasksData = async () => {
    setIsLoadingState(true);
    const res = await getAllTasksByEventId(eventDetails.id);
    setTasksState(res);
    setIsLoadingState(false);
  };

  const handleSelectTask = async (task) => {
    setIsProductsLoadingState(true);
    setSelectedTaskState(task);
    const res = await getProductsByTaskId(task.id);
    setProductsState(res);
    setIsProductsLoadingState(false);
  };

  useEffect(() => {
    getTasksData();
  }, []);
  // View
  return (
    <div className="modal-background">
      <div className="event-details-modal-container">
        <div className="event-details-modal-header-container">
          <h2 className="modal-header">Event Details</h2>
          <hr className="modal-header-line" />
        </div>
        <div className="event-details-inner-container scrollable-frame">
          <div className="event-details-section">
            <div>
              <div className="event-details-detail-container">
                <label className="input-label">Event Name</label>
                <div
                  className={`event-details-detail event-details-event-name${
                    isLoadingState ? " skeleton" : ""
                  }`}
                >
                  {!isLoadingState && eventDetails.name}
                </div>
              </div>
              <div className="event-details-date-time">
                <div className="event-details-detail-container">
                  <label className="input-label">Start Date</label>
                  <div
                    className={`event-details-detail${
                      isLoadingState ? " skeleton" : ""
                    }`}
                  >
                    {!isLoadingState && eventDetails.start_date}
                  </div>
                </div>
                <div className="time-input-container">
                  <label className="input-label">Start Time</label>
                  <div
                    className={`event-details-time${
                      isLoadingState ? " skeleton" : ""
                    }`}
                  >
                    {!isLoadingState && eventDetails.start_time}
                  </div>
                </div>
              </div>
              <div className="event-details-date-time">
                <div className="event-details-detail-container">
                  <label className="input-label">End Date</label>
                  <div
                    className={`event-details-detail${
                      isLoadingState ? " skeleton" : ""
                    }`}
                  >
                    {!isLoadingState && eventDetails.end_date}
                  </div>
                </div>
                <div className="time-input-container">
                  <label className="input-label">End Time</label>
                  <div
                    className={`event-details-time${
                      isLoadingState ? " skeleton" : ""
                    }`}
                  >
                    {!isLoadingState && eventDetails.end_time}
                  </div>
                </div>
              </div>
              <div>
                <div className="event-details-detail-container">
                  <label className="input-label">Status</label>
                  <div
                    className={`event-details-detail event-details-detail-status${
                      isLoadingState ? " skeleton" : ""
                    }`}
                  >
                    {!isLoadingState && eventDetails.status}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="event-details-detail-container">
                <label className="input-label">Event Description</label>
                <div
                  className={`event-details-detail event-details-detail-description${
                    isLoadingState ? " skeleton" : ""
                  }`}
                >
                  {!isLoadingState && eventDetails.description}
                </div>
              </div>
            </div>
          </div>
          <hr className="event-details-seperator" />
          <div className="event-details-detail-container">
            <label className="input-label">Tasks List</label>
            <div className="tasks-list-for-event-details">
              <div className="tasks-list-for-event-details-inner horizontal-scroll">
                {!isLoadingState ? (
                  tasksState.length > 0 ? (
                    <div className="list-of-all-tasks">
                      {tasksState.map((task) => {
                        return (
                          <div
                            key={task.id}
                            className="task-for-event-tag"
                            onClick={() => handleSelectTask(task)}
                          >
                            {task.name}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <NoContentMessage message="No Tasks" />
                  )
                ) : (
                  <IsLoadingMessage isLoading={isLoadingState} />
                )}
              </div>
            </div>
          </div>
          <hr className="event-details-seperator" />
          <div className="event-details-section">
            <div>
              <div className="event-details-detail-container">
                <label className="input-label">Task Name</label>
                <div className="event-details-detail event-details-task-name">
                  {selectedTaskState.name}
                </div>
              </div>
              <div className="event-details-dates">
                <div className="event-details-date-time">
                  <div className="event-details-detail-container">
                    <label className="input-label">Start Date</label>
                    <div className="event-details-detail">
                      {selectedTaskState.start_date}
                    </div>
                  </div>
                  <div className="time-input-container">
                    <label className="input-label">Start Time</label>
                    <div className="event-details-time">
                      {selectedTaskState.start_time}
                    </div>
                  </div>
                </div>
                <div className="event-details-date-time">
                  <div className="event-details-detail-container">
                    <label className="input-label">End Date</label>
                    <div className="event-details-detail">
                      {selectedTaskState.end_date}
                    </div>
                  </div>
                  <div className="time-input-container">
                    <label className="input-label">End Time</label>
                    <div className="event-details-time">
                      {selectedTaskState.end_time}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="event-details-detail-container">
                  <label className="input-label">Status</label>
                  <div className="event-details-detail event-details-detail-status">
                    {selectedTaskState.task_status}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="event-details-detail-container">
                <label className="input-label">Task Description</label>
                <div className="event-details-detail event-details-detail-description">
                  {selectedTaskState.description}
                </div>
              </div>
            </div>
          </div>
          <div className="event-details-detail-container">
            <label className="input-label">Product List</label>
            <div className="products-list-for-task-details">
              <div className="products-list-for-task-details-inner horizontal-scroll">
                {!isProductsLoadingState ? (
                  productsState.length > 0 ? (
                    <div className="list-of-all-products">
                      {productsState.map((product) => {
                        return (
                          <div
                            key={product.primary_identifier}
                            className="product-for-task-tag"
                          >
                            {product.primary_identifier}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <NoContentMessage message="No Products" />
                  )
                ) : (
                  <IsLoadingMessage isLoading={isProductsLoadingState} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="event-details-footer-section">
          <button className="btn" onClick={props.handleCloseBtn}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
