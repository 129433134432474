import React from "react";
import "./contactsList.css";
import { ContactRow } from "./contact-row/ContactRow";
import { NoContentMessage } from "../../../components/no-content-messages/no-content-message/NoContentMessage";
import { IsLoadingMessage } from "../../../components/no-content-messages/is-loading-message/IsLoadingMessage";
import { useSelector } from "react-redux";

export const ContactsList = () => {
  // Model
  const contacts = useSelector((state) => state.contacts.contactsList);
  const isLoading = useSelector((state) => state.contacts.isLoading);
  const contactsList =
    contacts.length > 0 ? (
      contacts.map((contact) => {
        return (
          <ContactRow
            key={contact.id}
            id={contact.id}
            name={contact.full_name}
            email={contact.sign_in_email}
          />
        );
      })
    ) : (
      <NoContentMessage message="No Contacts" />
    );
  // View
  return (
    <div className="contacts-list-container scrollable-frame">
      {!isLoading ? contactsList : <IsLoadingMessage isLoading={isLoading} />}
    </div>
  );
};
