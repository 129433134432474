import React, { useEffect, useState } from "react";
import { TextInput } from "../../../components/input-fields/text-input/TextInput";
import { DropdownInput } from "../../../components/input-fields/dropdown-input/DropdownInput";
import { DateInput } from "../../../components/input-fields/date-input/DateInput";
import { DatetimeInput } from "../../../components/input-fields/datetime-input/DatetimeInput";
import "./editProductModal.css";
import { formatAndUpdateProduct } from "../../../functions/formatAndUpdateProduct";
import { ErrorNotif } from "../../../components/notifications/error-notif/ErrorNotif";

export const EditProductModal = (props) => {
  // Model
  const { productDetails } = props;
  const initialState = {
    id: productDetails.id,
    longName: productDetails.long_name,
    shortName: productDetails.short_name || "",
    abbrName: productDetails.long_name || "",
    isin: productDetails.isin_code || "",
    apir: productDetails.apir_code || "",
    sedol: productDetails.sedol_code || "",
    cusip: productDetails.cusip_code || "",
    morningstar: productDetails.morningstar_code || "",
    bloomberg: productDetails.bloomberg_code || "",
    reuters: productDetails.reuters || "",
    iressMarketId: productDetails.iress_market_identifier || "",
    exchangeTicker: productDetails.exchange_ticker || "",
    usi: productDetails.usi_code || "",
    userDefinedOne: productDetails.user_defined_1 || "",
    userDefinedTwo: productDetails.user_defined_2 || "",
    primaryIdentifierType: productDetails.primary_identifier_type || "",
    issuerName: productDetails.issuer_name || "",
    issuerEntityType: productDetails.issuer_entity_type || "",
    issuerEntityId: productDetails.issuer_entity_identifier || "",
    productType: productDetails.product_type || "",
    subType: productDetails.sub_type || "n/a",
    fundManager: productDetails.fund_manager || "",
    domicile: productDetails.domicile || "",
    trusteeResponsibleEntity: productDetails.trustee_responsible_entity || "",
    custodian: productDetails.custodian || "",
    transferAgent: productDetails.transfer_agent || "",
    abn: productDetails.abn || "",
    arsn: productDetails.arsn || "",
    arbn: productDetails.arbn || "",
    rseNumber: productDetails.rse_number || "",
    superannuationClass: productDetails.superannuation_class || "",
    legalStructure: productDetails.legal_structure || "",
    shareClass: productDetails.share_class || "",
    exchange: productDetails.exchange || "",
    openDate: productDetails.open_date || "",
    status: productDetails.status || "",
    currency: productDetails.currency || "",
    assetClass: productDetails.asset_class || "",
    assetSubCategory: productDetails.asset_class_sub_category || "",
    assetIncomeType: productDetails.asset_listing_fixed_income_type || "",
    hedgeStrategy: productDetails.hedge_strategy || "",
    benchmark: productDetails.benchmark || "",
    priceFrequency: productDetails.price_frequency || "",
    publicationTime: productDetails.publication_time || "",
    distributionFrequency: productDetails.distribution_frequency || "",
    merTer: productDetails.mer_ter || "",
    icr: productDetails.icr || "",
    private: productDetails.private || "",
  };

  const [productState, setProductState] = useState(initialState);
  const [subTypeOptionsState, setSubTypeOptionsState] = useState([]);
  const [errNotifState, setErrNotifState] = useState(false);
  const [errMsgState, setErrMsgState] = useState("");
  // Controller

  useEffect(() => {
    if (productState.openDate) {
      const date = new Date(productState.openDate);
      const dateFormatted = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const dateSplit = dateFormatted.split("/");
      setProductState((prevState) => ({
        ...prevState,
        openDate: `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`,
      }));
    }
  }, []);

  useEffect(() => {
    //determines the options for subtype based on product type
    switch (productState.productType) {
      case "Managed Fund":
        setSubTypeOptionsState(["Wholesale", "Retail"]);
        break;
      case "Superannuation":
        setSubTypeOptionsState(["Wholesale", "Retail", "Annuity"]);
        break;
      case "ETF":
        setSubTypeOptionsState(["Passive", "Active"]);
        break;
      case "Insurance":
        setSubTypeOptionsState([
          "Life",
          "TPD",
          "Trauma",
          "Income Protection",
          "Other",
        ]);
        break;
      case "Managed Account":
        setSubTypeOptionsState(["Sma"]);
        break;
      default:
        setSubTypeOptionsState([]);
    }
  }, [productState.productType]);

  const handleSubmit = async (e) => {
    // handles the submittion of the form
    e.preventDefault();
    const response = await formatAndUpdateProduct(productState);
    if (!response) {
      props.onClickFunc();
      props.getAllProductsFunc();
    } else {
      setErrMsgState(response);
      setErrNotifState(true);
      setTimeout(() => setErrNotifState(false), 3500);
    }
  };

  // View
  return (
    <div className="modal-background">
      {errNotifState && <ErrorNotif errMsg={errMsgState} />}
      <div className="edit-product-modal">
        <div className="edit-product-modal-header-container">
          <h2 className="modal-header">Edit Product</h2>
          <hr className="modal-header-line" />
        </div>
        <div className="edit-product-inner-container">
          <form className="edit-product-form" onSubmit={handleSubmit}>
            <div className="edit-product-form-main scrollable-frame">
              <section className="inputs-container-grid">
                <div className="edit-product-group-header">
                  <h3>Product Names</h3>
                </div>
                <div className="product-names-grid edit-product-grid">
                  <TextInput
                    labelName="Product Long Name"
                    fieldName="long-name"
                    isRequired={true}
                    valueProp={productState.longName}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        longName: e.target.value,
                      })
                    }
                    required
                  />
                  <TextInput
                    labelName="Product Short Name"
                    fieldName="short-name"
                    valueProp={productState.shortName}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        shortName: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Product Abbreviated Name"
                    fieldName="abbreviated-name"
                    valueProp={productState.abbrName}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        abbrName: e.target.value,
                      })
                    }
                  />
                </div>
                <hr className="product-form-separator" />
                {/* ------------------------ Identifier Fields ------------------------ */}
                <div className="edit-product-group-header">
                  <h3>Identifiers</h3>
                </div>
                <div className="identifiers-grid edit-product-grid">
                  <DropdownInput
                    labelName="Primary Identifier Type"
                    fieldName="primary-id"
                    isRequired={true}
                    options={[
                      "ISIN",
                      "APIR",
                      "SEDOL",
                      "CUSIP",
                      "Morningstar",
                      "Bloomberg",
                      "Reuters",
                      "Iress Market Identifier",
                      "Exchange Ticker",
                      "USI",
                      "User Defined 1",
                      "User Defined 2",
                    ]}
                    valueProp={productState.primaryIdentifierType}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        primaryIdentifierType: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="ISIN"
                    fieldName="isin"
                    valueProp={productState.isin}
                    isRequired={
                      productState.primaryIdentifierType === "ISIN"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        isin: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="APIR"
                    fieldName="apir"
                    valueProp={productState.apir}
                    isRequired={
                      productState.primaryIdentifierType === "APIR"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        apir: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="SEDOL"
                    fieldName="sedol"
                    valueProp={productState.sedol}
                    isRequired={
                      productState.primaryIdentifierType === "SEDOL"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        sedol: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="CUSIP"
                    fieldName="cusip"
                    valueProp={productState.cusip}
                    isRequired={
                      productState.primaryIdentifierType === "CUSIP"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        cusip: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Morningstar Identifier"
                    fieldName="morningstar"
                    valueProp={productState.morningstar}
                    isRequired={
                      productState.primaryIdentifierType === "Morningstar"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        morningstar: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Bloomberg Identifier"
                    fieldName="bloomberg"
                    valueProp={productState.bloomberg}
                    isRequired={
                      productState.primaryIdentifierType === "Bloomberg"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        bloomberg: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Reuters"
                    fieldName="reuters"
                    valueProp={productState.reuters}
                    isRequired={
                      productState.primaryIdentifierType === "Reuters"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        reuters: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Iress Market Identifier"
                    fieldName="iress-market-id"
                    valueProp={productState.iressMarketId}
                    isRequired={
                      productState.primaryIdentifierType ===
                      "Iress Market Identifier"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        iressMarketId: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Exchange Ticker"
                    fieldName="exchange-ticker"
                    valueProp={productState.exchangeTicker}
                    isRequired={
                      productState.primaryIdentifierType === "Exchange Ticker"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        exchangeTicker: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="USI"
                    fieldName="usi"
                    valueProp={productState.usi}
                    isRequired={
                      productState.primaryIdentifierType === "USI"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        usi: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="User Defined 1"
                    fieldName="user-defined-one"
                    valueProp={productState.userDefinedOne}
                    isRequired={
                      productState.primaryIdentifierType === "User Defined 1"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        userDefinedOne: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="User Defined 2"
                    fieldName="user-defined-two"
                    valueProp={productState.userDefinedTwo}
                    isRequired={
                      productState.primaryIdentifierType === "User Defined 2"
                        ? true
                        : false
                    }
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        userDefinedTwo: e.target.value,
                      })
                    }
                  />
                </div>
                <hr className="product-form-separator" />
                {/* ------------ Issuer Fields -------------- */}
                <div className="edit-product-group-header">
                  <h3>Issuer Details</h3>
                </div>
                <div className="issuer-details-grid edit-product-grid">
                  <TextInput
                    labelName="Issuer Name"
                    fieldName="issuer-name"
                    isRequired={true}
                    valueProp={productState.issuerName}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        issuerName: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Issuer Entity Type"
                    fieldName="issuer-entity-type"
                    options={[
                      "Adviser",
                      "AFSL",
                      "Credit Licensee",
                      "Corporation",
                      "Other",
                    ]}
                    valueProp={productState.issuerEntityType}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        issuerEntityType: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Issuer Entity Identifier"
                    fieldName="issuer-entity-identifier"
                    valueProp={productState.issuerEntityId}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        issuerEntityId: e.target.value,
                      })
                    }
                  />
                </div>
                <hr className="product-form-separator" />
                {/* ------------ Type Fields -------------- */}
                <div className="edit-product-group-header">
                  <h3>Product Types</h3>
                </div>
                <div className="product-types-grid edit-product-grid">
                  <DropdownInput
                    labelName="Product Type"
                    fieldName="product-type"
                    isRequired={true}
                    options={[
                      "Managed Fund",
                      "Superannuation",
                      "ETF",
                      "Insurance",
                      "Credit",
                      "Managed Account",
                      "IDPS",
                      "Insurance Bond",
                      "Derivatives",
                      "Other",
                    ]}
                    valueProp={productState.productType}
                    onChangeFunc={(e) => {
                      setProductState({
                        ...productState,
                        productType: e.target.value,
                        subType: "",
                      });
                      document.querySelector("#sub-type-input").value = "";
                    }}
                  />
                  <DropdownInput
                    labelName="Sub Type"
                    fieldName="sub-type"
                    isRequired={subTypeOptionsState.length > 0 ? true : false}
                    options={subTypeOptionsState}
                    valueProp={productState.subType}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        subType: e.target.value,
                      })
                    }
                  />
                </div>
                <hr className="product-form-separator" />
                {/* ------------ More Fields -------------- */}
                <div className="edit-product-group-header">
                  <h3>Details</h3>
                </div>
                <div className="product-details-grid edit-product-grid">
                  <TextInput
                    labelName="Fund Manager"
                    fieldName="fund-manager"
                    valueProp={productState.fundManager}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        fundManager: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Domicile"
                    fieldName="domicile"
                    isRequired={true}
                    options={["AUS", "NZL"]}
                    valueProp={productState.domicile}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        domicile: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Trustee Responsible Entity"
                    fieldName="trustee-responsible-entity"
                    valueProp={productState.trusteeResponsibleEntity}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        trusteeResponsibleEntity: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Custodian"
                    fieldName="custodian"
                    valueProp={productState.custodian}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        custodian: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Transfer Agent"
                    fieldName="transfer-agent"
                    valueProp={productState.transferAgent}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        transferAgent: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="ABN"
                    fieldName="abn"
                    valueProp={productState.abn}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        abn: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="ARSN"
                    fieldName="arsn"
                    valueProp={productState.arsn}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        arsn: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="ARBN"
                    fieldName="arbn"
                    valueProp={productState.arbn}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        arbn: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="RSE Number"
                    fieldName="rse-number"
                    valueProp={productState.rseNumber}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        rseNumber: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Superannuation Class"
                    fieldName="superannuation-class"
                    options={["Fund", "Div", "Investment Option"]}
                    valueProp={productState.superannuationClass}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        superannuationClass: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Legal Structure (FORM)"
                    fieldName="legal-structure"
                    options={[
                      "Managed Investment Scheme",
                      "AMIT",
                      "UCIT",
                      "CIV",
                      "OEIC",
                      "CCIV",
                      "VCLLP",
                    ]}
                    valueProp={productState.legalStructure}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        legalStructure: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Share Class"
                    fieldName="share-class"
                    valueProp={productState.shareClass}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        shareClass: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Exchange"
                    fieldName="exchange"
                    valueProp={productState.exchange}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        exchange: e.target.value,
                      })
                    }
                  />
                  <DateInput
                    labelName="Open Date"
                    fieldName="open-date"
                    valueProp={productState.openDate}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        openDate: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Status"
                    fieldName="status"
                    isRequired={true}
                    options={["Open", "Closed", "Terminated", "Suspended"]}
                    valueProp={productState.status}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        status: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Currency"
                    fieldName="currency"
                    isRequired={true}
                    options={["AUD", "USD", "NZD", "YEN"]}
                    valueProp={productState.currency}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        currency: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Asset Class"
                    fieldName="asset-class"
                    options={[
                      "Cash",
                      "Fixed Income",
                      "Equity",
                      "Property",
                      "Infrastructure",
                      "Commodities",
                      "Other",
                    ]}
                    valueProp={productState.assetClass}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        assetClass: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Asset Class Sub-Category"
                    fieldName="asset-class-sub-category"
                    options={["Australian", "International"]}
                    valueProp={productState.assetSubCategory}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        assetSubCategory: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Asset Listing Fixed Income Type"
                    fieldName="asset-class-fixed-income-type"
                    options={[
                      "Government Debt",
                      "Non Government Debt",
                      "Mortgage Debt",
                      "Credit",
                      "Listed",
                      "Unlisted",
                    ]}
                    valueProp={productState.assetIncomeType}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        assetIncomeType: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Hedge Strategy"
                    fieldName="hedge-strategy"
                    valueProp={productState.hedgeStrategy}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        hedgeStrategy: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="Benchmark"
                    fieldName="benchmark"
                    valueProp={productState.benchmark}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        benchmark: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Pricing Frequency"
                    fieldName="pricing-frequency"
                    options={[
                      "Daily",
                      "Weekly",
                      "Monthly",
                      "Quarterly",
                      "Semi-Annual",
                      "Annual",
                    ]}
                    valueProp={productState.priceFrequency}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        priceFrequency: e.target.value,
                      })
                    }
                  />
                  <DatetimeInput
                    labelName="Publication Time"
                    fieldName="publication-time"
                    valueProp={productState.publicationTime}
                    onChangeFunc={(e) => {
                      setProductState({
                        ...productState,
                        publicationTime: e.target.value,
                      });
                    }}
                  />

                  <DropdownInput
                    labelName="Distribution Frequency"
                    fieldName="distribution-frequency"
                    options={[
                      "Daily",
                      "Weekly",
                      "Monthly",
                      "Quarterly",
                      "Semi-Annual",
                      "Annual",
                    ]}
                    valueProp={productState.distributionFrequency}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        distributionFrequency: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="MER/TER"
                    fieldName="mer-ter"
                    valueProp={productState.merTer}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        merTer: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    labelName="ICR"
                    fieldName="icr"
                    valueProp={productState.icr}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        icr: e.target.value,
                      })
                    }
                  />
                  <DropdownInput
                    labelName="Private"
                    fieldName="private"
                    options={["True", "False"]}
                    valueProp={productState.private}
                    onChangeFunc={(e) =>
                      setProductState({
                        ...productState,
                        private: e.target.value,
                      })
                    }
                  />
                </div>
              </section>
            </div>
            {/* ---------------- form footer ------------------- */}
            <div className="edit-product-form-footer">
              <input
                type="submit"
                value="Save"
                className="edit-product-submit-btn btn"
              />
            </div>
          </form>
          <button
            className="edit-product-cancel-btn btn"
            onClick={props.onClickFunc}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
