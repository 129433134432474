import React, { useEffect, useState } from "react";
import "./eventsList.css";
import { EventRow } from "./event-row/EventRow";
import { IsLoadingMessage } from "../../../components/no-content-messages/is-loading-message/IsLoadingMessage";
import { NoContentMessage } from "../../../components/no-content-messages/no-content-message/NoContentMessage";
import { ConfirmationModal } from "../../../components/modals/confirmation-modal/ConfirmationModal";
import { deleteEventById } from "../../../api/event-calls/deleteEventById";
import { downloadEventData } from "../../../api/event-calls/downloadEventData";

export const EventsList = (props) => {
  // Model
  const { eventsData } = props;
  const [eventState, setEventState] = useState([]);
  const [confirmDeleteModalState, setConfirmDeleteModalState] = useState(false);
  const [eventToDeleteState, setEventToDeleteState] = useState({});
  const [expandedEventId, setExpandedEventId] = useState(null);

  // Controllers
  const handleDownloadBtn = (eventId) => {
    downloadEventData(eventId);
  };

  const handleDeleteBtn = (eventId, eventName) => {
    setConfirmDeleteModalState(true);
    setEventToDeleteState({ id: eventId, name: eventName });
  };

  const handleModalCancelBtn = () => {
    setConfirmDeleteModalState(false);
    setEventToDeleteState({});
  };

  const handleModalConfirmBtn = async () => {
    const response = await deleteEventById(eventToDeleteState.id);
    if (response.success) {
      setConfirmDeleteModalState(false);
      setEventToDeleteState({});
      props.getEventsFunc();
    } else {
      console.log("Server Error!!!!");
    }
  };

  const handleEventContainerExpand = (eventId) => {
    // Toggle the expanded state based on the clicked eventId
    setExpandedEventId((prevId) => (prevId === eventId ? null : eventId));
  };

  // -------------------------------------------

  useEffect(() => {
    if (eventsData.length > 0) {
      setEventState(eventsData);
    } else {
      setEventState([]);
    }
    setExpandedEventId(null);
  }, [eventsData]);

  // View
  return (
    <div className="events-list-inner">
      {confirmDeleteModalState && (
        <ConfirmationModal
          typeProp="event"
          nameProp={eventToDeleteState.name}
          handleConfirmBtn={handleModalConfirmBtn}
          handleCancelBtn={handleModalCancelBtn}
        />
      )}
      {!props.isLoading ? (
        eventState.length > 0 ? (
          eventState.map((event) => {
            return (
              <EventRow
                key={event.id}
                id={event.id}
                event={event}
                isExpanded={expandedEventId === event.id}
                handleExpand={() => handleEventContainerExpand(event.id)}
                handleViewBtn={() => {
                  setExpandedEventId(null);
                  props.handleViewBtn(event);
                }}
                handleDownloadBtn={() => handleDownloadBtn(event.id)}
                handleEditBtn={() => {
                  setExpandedEventId(null);
                  props.handleEditBtn(event);
                }}
                handleDeleteBtn={() => {
                  setExpandedEventId(null);
                  handleDeleteBtn(event.id, event.name);
                }}
              />
            );
          })
        ) : (
          <NoContentMessage message="No Events" />
        )
      ) : (
        <IsLoadingMessage isLoading={props.isLoading} />
      )}
    </div>
  );
};
