import { generateUniqueId } from "./generateUniqueId";
import { getTimeStamp } from "./getTimeStamp";
import { createNewTask } from "../api/task-calls/createNewTask";
import { createTaskUserLink } from "../api/task-calls/createTaskUserLink";
import { createEventUserLink } from "../api/event-calls/createEventUserLink";
import { createNewNotification } from "../api/notification-calls/createNewNotification";
import { logTasks } from "../api/log-calls/logTasks";
import { createTaskProductLink } from "../api/task-calls/createTaskProductLink";
import { formatDateTimeForDatabase } from "./formatDateTimeForDatabase";

export const formatAndCreateTask = async (taskData, userName) => {
  // Create timestamp
  const timestamp = getTimeStamp();
  // Set id property
  const taskId = await generateUniqueId(21, "tasks");
  taskData.id = taskId;
  // Set createdAt
  taskData.createdAt = timestamp;
  // Format Dates
  taskData.startDate = formatDateTimeForDatabase(
    taskData.startDate,
    taskData.startTime
  );
  taskData.endDate = formatDateTimeForDatabase(
    taskData.endDate,
    taskData.endTime
  );
  // Replace empty string with null
  for (let i in taskData) {
    if (taskData[i] === "") {
      taskData[i] = null;
    }
  }
  const res = await createNewTask(taskData);
  if (res.success) {
    await logTasks({
      taskId,
      action: "New task created.",
      response: "Success",
      dateTime: timestamp,
    });
    await createTaskUserLink({ userId: "", taskId, role: "owner" });
    for (let user of taskData.assignees) {
      await createEventUserLink({
        userId: user.id,
        eventId: taskData.eventId,
        role: "assignee",
      });
      const eventNotifId = await generateUniqueId(24, "notifications");
      await createNewNotification({
        id: eventNotifId,
        recipientId: user.id,
        message: `You have been assigned to event ${taskData.eventName} by ${userName}.`,
        createdAt: timestamp,
      });

      await createTaskUserLink({
        userId: user.id,
        taskId,
        role: "assignee",
      });
      const taskNotifId = await generateUniqueId(24, "notifications");
      await createNewNotification({
        id: taskNotifId,
        recipientId: user.id,
        message: `You have been assigned to task ${taskData.name} by ${userName}.`,
        createdAt: timestamp,
      });
    }
    await createTaskProductLink({ taskId, products: taskData.products });
  } else {
    await logTasks({
      taskId,
      action: "New task created.",
      response: "Fail",
      dateTime: timestamp,
    });
  }
  return res;
};
