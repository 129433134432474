import React from "react";
import "./frameHeader.css";

export const FrameHeader = (props) => {
  return (
    <div>
      <h2 className="frame-header">{props.header}</h2>
      {props.line === true ? <hr className="frame-header-line" /> : null}
    </div>
  )
}