import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../nav-bar/NavBar";
import "./pageTemplate.css";
import { logOutUser } from "../../api/user-calls/logOutUser";
import { isLoggedIn } from "../../api/user-calls/isLoggedIn";
import { useDispatch, useSelector } from "react-redux";
import { clearUserData, getUserDetails } from "../../features/userSlice";

export const PageTemplate = (props) => {
  //  Model
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { children } = props;
  const providedClass = `page-content-inner-container ${props.layout}`;
  const [userNameState, setUserNameState] = useState("");
  const firstName = useSelector((state) => state.user.userDetails.firstName);
  const lastName = useSelector((state) => state.user.userDetails.lastName);

  // Controller
  const handleClick = async () => {
    const result = await logOutUser();
    if (result) {
      dispatch(clearUserData());
      navigate("/sign-in");
    }
  };

  const isUserLoggedIn = async () => {
    const result = await isLoggedIn();
    if (!result.loggedIn) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    isUserLoggedIn();
    if (firstName.length === 0 || lastName.length === 0) {
      dispatch(getUserDetails());
    }
  }, []);

  useEffect(() => {
    setUserNameState(firstName + " " + lastName);
  }, [firstName, lastName]);

  // View
  return (
    <div className="page-container">
      <div className="navigation-bar">
        <NavBar />
      </div>
      <main className="page-content-container">
        <header>
          <h1 className="page-header">{props.pageHeader}</h1>
          <div className="logout-container">
            <span className="username">{userNameState}</span>
            <div className="username-logout-divider"></div>
            <button className="logout-btn" onClick={handleClick}>
              Logout
            </button>
          </div>
        </header>
        <section className={providedClass}>{children}</section>
      </main>
    </div>
  );
};
