import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_ENDPOINT } from "../api";

const initialState = {
  userDetails: {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    companyName: "",
    companyAbn: "",
    companyId: "",
    role: "",
  },
  userSettings: {},
  isLoading: false,
};

export const getUserDetails = createAsyncThunk(
  "userDetails/getUserDetails",
  async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}users/get-user-details`, {
        credentials: "include",
      });
      let result = await response.json();
      return result.rows[0];
    } catch (err) {
      console.log(err.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUserSettings: (state, action) => {
      state.userSettings = action.payload;
    },
    clearUserData: (state) => {
      state.userDetails.firstName = "";
      state.userDetails.lastName = "";
      state.userDetails.email = "";
      state.userDetails.mobile = "";
      state.userDetails.companyName = "";
      state.userDetails.companyAbn = "";
      state.userDetails.companyId = "";
      state.userDetails.role = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        const payload = action.payload;
        if (payload) {
          state.userDetails.firstName = payload.first_name;
          state.userDetails.lastName = payload.last_name;
          state.userDetails.email = payload.sign_in_email;
          state.userDetails.mobile = payload.phone_number;
          state.userDetails.companyName = payload.company_name;
          state.userDetails.companyAbn = payload.company_abn;
          state.userDetails.companyId = payload.company_id;
          state.userDetails.role = payload.role;
        }
      })
      .addCase(getUserDetails.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { addUserSettings, clearUserData } = userSlice.actions;

export default userSlice.reducer;
