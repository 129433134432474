import React, { useState } from "react";
import "./userSearchBar.css";

export const UserSearchBar = (props) => {
  // Model
  const [searchState, setSearchState] = useState("");
  const [typeState, setTypeState] = useState("");
  const [showInputState, setShowInputState] = useState("");
  const [btnState, setBtnState] = useState(false);

  // Controller
  const handleTypeChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setTypeState(value);
    setSearchState("");
    if (value === "name" || value === "email") {
      setShowInputState("text");
    } else if (value === "activation") {
      setShowInputState("dropdown");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeState) {
      props.filterUsersFunc(typeState, searchState);
      setBtnState(true);
    }
    if (!searchState) {
      setBtnState(false);
    }
  };

  const handleClick = () => {
    setBtnState(false);
    setTypeState("");
    setSearchState("");
    setShowInputState("");
    document.querySelector("#user-select").value = "DEFAULT";
    props.getAllUsersFunc();
  };

  const handleDateChange = (e) => {
    setSearchState(e.target.value);
    props.filterTasksFunc(typeState, e.target.value);
    setBtnState(true);
  };

  const handleActivationChange = (e) => {
    props.filterUsersFunc(typeState, e.target.value);
    setBtnState(true);
  };

  // View
  return (
    <div className="task-search-bar-container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="user-select" className="search-by-label">
          Search by:
        </label>
        <select
          id="user-select"
          name="user-select"
          defaultValue={"DEFAULT"}
          onChange={handleTypeChange}
        >
          <option value="DEFAULT" disabled>
            Select...
          </option>
          <option value="name">Name</option>
          <option value="email">Email</option>
          <option value="activation">Activation</option>
        </select>
        {showInputState === "text" && (
          <input
            className="text-input"
            type="text"
            value={searchState}
            placeholder="Type here"
            onChange={(e) => setSearchState(e.target.value)}
          />
        )}
        {showInputState === "date" && (
          <input
            className="date-input"
            type="date"
            value={searchState}
            placeholder="Type here"
            onChange={handleDateChange}
          />
        )}
        {showInputState === "dropdown" && (
          <select
            id="status-select"
            name="status-select"
            defaultValue={"DEFAULT"}
            onChange={handleActivationChange}
          >
            <option value="DEFAULT" disabled>
              Select ...
            </option>
            <option value="true">Activated</option>
            <option value="false">Deactivated</option>
          </select>
        )}
      </form>
      {btnState && (
        <button className="show-all-btn btn" onClick={handleClick}>
          Show All
        </button>
      )}
    </div>
  );
};
