import { configureStore } from "@reduxjs/toolkit";
import eventsReducer from "../features/eventsSlice";
import productsReducer from "../features/productsSlice";
import userReducer from "../features/userSlice";
import tasksReducer from "../features/tasksSlice";
import contactsReducer from "../features/contactsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    events: eventsReducer,
    tasks: tasksReducer,
    products: productsReducer,
    contacts: contactsReducer,
  },
});
