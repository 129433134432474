import React, { useEffect, useState } from "react";
import "./communityPage.css";
import { PageTemplate } from "../../components/page-template/PageTemplate";
import { Frame } from "../../components/frame/Frame";
import { ContactsList } from "./contacts-list/ContactsList";
import { InviteUserForm } from "./invite-user-form/InviteUserForm";
import { NotificationsList } from "./notifications/NotificationsList";
import { useDispatch } from "react-redux";
import { getContactsData } from "../../features/contactsSlice";
import { getAllNotifications } from "../../api/notification-calls/getAllNotifications";

export const CommunityPage = () => {
  //  Model
  const dispatch = useDispatch();
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [animationState, setAnimationState] = useState("");
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [notificationsState, setNotificationsState] = useState([]);
  // Controllers
  const getNotificationsData = async () => {
    setIsLoadingState(true);
    const result = await getAllNotifications();
    setNotificationsState(result);
    setIsLoadingState(false);
  };
  const handleAddContactBtn = async () => {
    setAnimationState("slide-out-left");
    await new Promise((resolve) => setTimeout(resolve, 145));
    setShowAddContactForm(true);
    setAnimationState("slide-in-left");
    await new Promise((resolve) => setTimeout(resolve, 150));
    setAnimationState("");
  };

  const handleBackBtn = async () => {
    setAnimationState("slide-out-right");
    await new Promise((resolve) => setTimeout(resolve, 145));
    setShowAddContactForm(false);
    setAnimationState("slide-in-right");
    await new Promise((resolve) => setTimeout(resolve, 150));
    setAnimationState("");
  };

  useEffect(() => {
    dispatch(getContactsData());
    getNotificationsData();
  }, []);

  // View
  return (
    <PageTemplate pageHeader="Community" layout="uneven-dual-layout">
      <div className="left-frame-container">
        <Frame
          header={!showAddContactForm ? "Contacts List" : "Add Contact"}
          line={true}
          fullSize={true}
        >
          {!showAddContactForm ? (
            <div className={`contacts-container ${animationState}`}>
              <ContactsList />
            </div>
          ) : (
            <div className={`contacts-container ${animationState}`}>
              <InviteUserForm />
            </div>
          )}
          <div className="contacts-btn-container">
            {!showAddContactForm ? (
              <button className="add-contact btn" onClick={handleAddContactBtn}>
                Invite User
              </button>
            ) : (
              <button className="add-contact btn" onClick={handleBackBtn}>
                Back
              </button>
            )}
          </div>
        </Frame>
      </div>
      <div className="right-frame-container">
        <Frame header="Notifications" line={true} fullSize={true}>
          <div className="notifications-inner-frame-container scrollable-frame">
            <NotificationsList
              notificationsData={notificationsState}
              isLoading={isLoadingState}
            />
          </div>
        </Frame>
      </div>
    </PageTemplate>
  );
};
