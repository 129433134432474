import { API_ENDPOINT } from "..";
import { formatDateTime } from "../../functions/formatDateTime";

export const getAllProductsByFilter = async (type, term) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}products/filter/?type=${type}&term=${term}`,
      {
        credentials: "include",
      }
    );
    let result = await response.json();
    if (result.success) {
      let products = result.data;
      for (let product of products) {
        if (product.publication_time) {
          product.publication_time = formatDateTime(product.publication_time);
        }
      }
      return products;
    }
  } catch (err) {
    console.error(err);
  }
};
