import React, { useEffect, useState } from "react";
import "./signUpCompanyDetails.css";
import { TextInput } from "../../../../components/input-fields/text-input/TextInput";
import validator from "validator";
import { checkCompanyAbn } from "../../../../api/company-calls/checkCompanyAbn";

export const SignUpCompanyDetails = (props) => {
  // Model
  const { companyDetails } = props;
  const [buttonState, setButtonState] = useState(false);
  const [isDisabledState, setIsDisabledState] = useState(true);
  // Controllers
  const handleSearchAbn = async () => {
    const res = await checkCompanyAbn(companyDetails.companyAbn);
    if (res.success) {
      if (res.match) {
        props.companyMatchFunc(res.data.name);
        props.userRoleFunc("user");
        setIsDisabledState(true);
      } else {
        props.companyMatchFunc("");
        props.userRoleFunc("company_admin");
        setIsDisabledState(false);
      }
    }
  };

  useEffect(() => {
    if (
      !validator.isEmpty(companyDetails.companyName) &&
      !validator.isEmpty(companyDetails.companyAbn)
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [companyDetails]);

  // View
  return (
    <div className="sign-up-form-frame">
      <div className="sign-up-user-details">
        <h3 className="sign-up-sub-header">Company Details</h3>
        <div className="sign-up-input-fields-company-details">
          <div className="company-abn-input-container">
            <TextInput
              labelName="Company ABN"
              fieldName="sign-up-company-abn"
              valueProp={companyDetails.companyAbn}
              onChangeFunc={props.companyAbnFunc}
              maxLengthProp={40}
              isRequired={true}
            />
            <div className="search-abn-btn-container">
              {companyDetails.companyAbn.length > 0 ? (
                <button
                  className="company-abn-search-btn btn"
                  onClick={handleSearchAbn}
                >
                  Search
                </button>
              ) : (
                <button
                  className="company-abn-search-btn disabled-btn"
                  onClick={handleSearchAbn}
                  disabled
                >
                  Search
                </button>
              )}

              {/* <div style={{ marginBottom: "10px" }}>hi</div> */}
            </div>
          </div>
          {!isDisabledState ? (
            <TextInput
              labelName="Company Name"
              fieldName="sign-up-company-name"
              valueProp={companyDetails.companyName}
              onChangeFunc={props.companyNameFunc}
              maxLengthProp={100}
              isRequired={true}
            />
          ) : (
            <div className="text-input-container">
              <label
                className="input-label"
                htmlFor="sign-up-company-name-input"
                style={{ color: "var(--activeGrey)" }}
              >
                *Company Name
              </label>
              <div
                id="sign-up-company-name-input"
                name="sign-up-company-name-input"
                className="text-input"
              >
                {companyDetails.companyName}
              </div>
            </div>
          )}
        </div>
      </div>
      {buttonState ? (
        <button className="btn" id="sign-up-btn" onClick={props.handleClick}>
          Next
        </button>
      ) : (
        <button className="disabled-btn" id="sign-up-btn">
          Next
        </button>
      )}
    </div>
  );
};
