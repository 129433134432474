import { API_ENDPOINT } from "..";

export const isLoggedIn = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}is-signed-in`, {
      credentials: "include",
    });
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
};