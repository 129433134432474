import { API_ENDPOINT } from "..";
import { logEvents } from "../log-calls/logEvents";
import { getTimeStamp } from "../../functions/getTimeStamp";

export const updateEventByEventId = async (eventId, data) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}events/update-event-details/${eventId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    const res = await response.json();
    await logEvents({
      eventId,
      action: "Event edited.",
      response: res.success ? "Success" : "Fail",
      dateTime: getTimeStamp(),
    });
    return res;
  } catch (error) {
    return error;
  }
};
