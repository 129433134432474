import { API_ENDPOINT } from ".";

export const checkIsUnique = async (data) => {
  try {
    const response = await fetch(`${API_ENDPOINT}check-unique-id`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    if (res.success === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};
