import React, { useEffect, useState } from "react";
import "./newNotifications.css";
import { Link } from "react-router-dom";
import { IsLoadingMessage } from "../../../components/no-content-messages/is-loading-message/IsLoadingMessage";
import { NoContentMessage } from "../../../components/no-content-messages/no-content-message/NoContentMessage";
import { getNewNotifications } from "../../../api/notification-calls/getNewNotifications";
import { NewNotificationRow } from "./new-notification-row/NewNotificationRow";
import { EventDetailsModal } from "../../../components/modals/event-details-modal/EventDetailsModal";

export const NewNotifications = () => {
  // Model
  const [notificationsListState, setNotificationsListState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);

  // Controllers
  const getNotificationsData = async () => {
    setIsLoadingState(true);
    const result = await getNewNotifications();
    setNotificationsListState(result);
    setIsLoadingState(false);
  };

  useEffect(() => {
    getNotificationsData();
  }, []);

  // View
  return (
    <div className="new-notifications-container">
      {!isLoadingState ? (
        notificationsListState.length > 0 ? (
          <div className="new-notifications-container-inner scrollable-frame">
            {notificationsListState.map((notif) => {
              return (
                <NewNotificationRow
                  key={notif.id}
                  message={notif.message}
                />
              );
            })}
          </div>
        ) : (
          <NoContentMessage message="No Notifications" />
        )
      ) : (
        <IsLoadingMessage />
      )}
      <div className="see-all-notifications-btn-container">
        <Link to="/community" className="link">
          View All
        </Link>
      </div>
    </div>
  );
};
