import React, { useState } from "react";
import "./assigneeSelector.css";
import { NoContentMessage } from "../../no-content-messages/no-content-message/NoContentMessage";
import { IsLoadingMessage } from "../../no-content-messages/is-loading-message/IsLoadingMessage";
import { AssigneeSelectorRow } from "./assignee-selector-row/AssigneeSelectorRow";
import { getUsersByEmail } from "../../../api/user-calls/getUsersByEmail";
import { getUsersByName } from "../../../api/user-calls/getUsersByName";
import { AssigneeTag } from "./assignee-tag/AssigneeTag";

export const AssigneeSelector = (props) => {
  // Model
  const [showModalState, setShowModalState] = useState(false);
  const [searchState, setSearchState] = useState("");
  const [assigneeListState, setAssigneeListState] = useState([]);
  const [filterState, setFilterState] = useState("name");
  const [isSearchingState, setIsSearchingState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Controller
  const handleClick = (e) => {
    e.preventDefault();
    setShowModalState(true);
  };

  const handleCancelBtn = () => {
    setShowModalState(false);
    setIsSearchingState(false);
    setAssigneeListState([]);
    setSearchState("");
    setFilterState("name");
  };

  const handleFilterChange = (e) => {
    setFilterState(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSearchingState(true);
    setIsLoading(true);
    if (filterState === "name") {
      const result = await getUsersByName(searchState.toLowerCase());
      if (result.success) {
        setAssigneeListState(result.data);
      }
    } else if (filterState === "email") {
      const result = await getUsersByEmail(searchState.toLowerCase());
      if (result.success) {
        setAssigneeListState(result.data);
      }
    }
    setIsLoading(false);
  };

  // View
  return (
    <div className="user-selector-container">
      {showModalState && (
        <div className="modal-background">
          <div className="add-assignees-modal">
            <div className="assignee-email-search">
              <div className="label-filter-container">
                <label
                  className="input-label"
                  htmlFor="search-assignee-email-input"
                >
                  Search for assignee by:
                </label>
                {filterState === "name" && (
                  <div className="filter-btn-container">
                    <button className="filter-btn-disabled" disabled>
                      Name
                    </button>
                    <button
                      className="filter-btn"
                      value="email"
                      onClick={handleFilterChange}
                    >
                      Email
                    </button>
                  </div>
                )}
                {filterState === "email" && (
                  <div className="filter-btn-container">
                    <button
                      className="filter-btn"
                      value="name"
                      onClick={handleFilterChange}
                    >
                      Name
                    </button>
                    <button className="filter-btn-disabled" disabled>
                      Email
                    </button>
                  </div>
                )}
              </div>
              <form
                className="assignee-email-search-form"
                onSubmit={handleSubmit}
              >
                <input
                  id="search-assignee-email-input"
                  className="text-input"
                  placeholder={filterState === "name" ? "Name" : "Email"}
                  value={searchState}
                  onChange={(e) => setSearchState(e.target.value)}
                />
                {searchState.trim().length > 0 ? (
                  <input
                    type="submit"
                    className="search-assignee-email-btn btn"
                    value="Search"
                  />
                ) : (
                  <input
                    type="submit"
                    className="search-assignee-email-btn disabled-btn"
                    value="Search"
                    disabled
                  />
                )}
              </form>
            </div>
            <hr className="assignees-modal-divider" />
            <div className="assignees-emails-list scrollable-frame">
              {isSearchingState ? (
                !isLoading ? (
                  assigneeListState.length > 0 ? (
                    assigneeListState.map((user) => {
                      const fullName = user.full_name;
                      return (
                        <AssigneeSelectorRow
                          key={user.id}
                          name={fullName}
                          email={user.sign_in_email}
                          handleAddBtn={() => {
                            props.handleAddAssigneeBtn(user);
                            setShowModalState(false);
                            setIsSearchingState(false);
                            setAssigneeListState([]);
                            setSearchState("");
                            setFilterState("name");
                          }}
                        />
                      );
                    })
                  ) : (
                    <NoContentMessage message="No Users" />
                  )
                ) : (
                  <IsLoadingMessage />
                )
              ) : (
                <NoContentMessage
                  message={`Search user by ${
                    filterState === "name" ? "name" : "email"
                  }`}
                />
              )}
            </div>
            <div className="add-assignees-modal-btn-container">
              <button className="btn" onClick={handleCancelBtn}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Container to display selected assignees ---------------------------------- */}
      <button className="select-assignees-btn btn" onClick={handleClick}>
        Add Assignees
      </button>
      <div className="assignees-list">
        <div className="assignees-list-container">
          <div className="assignees-list-container-inner horizontal-scroll">
            {props.assigneeList.length > 0 ? (
              props.assigneeList.map((user) => {
                return (
                  <AssigneeTag
                    key={user.id}
                    user={user}
                    handleRemoveAssigneeBtn={props.handleRemoveAssigneeBtn}
                  />
                );
              })
            ) : (
              <NoContentMessage message="No Assignees" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
