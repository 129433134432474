import { API_ENDPOINT } from "..";
import { logTasks } from "../log-calls/logTasks";
import { getTimeStamp } from "../../functions/getTimeStamp";

export const deleteTaskByTaskId = async (taskId) => {
  try {
    const response = await fetch(`${API_ENDPOINT}tasks/delete-task/${taskId}`, {
      method: "DELETE"
    });
    const res = await response.json();
    await logTasks({
      taskId,
      action: "Task deleted.",
      response: "Success",
      dateTime: getTimeStamp(),
    });
    return res;
  } catch (error) {
    console.error(`There was an error ${error}`);
    throw error;
  }
};