import { API_ENDPOINT } from "..";

export const updateLastSignIn = async (data) => {
  try {
    const response = await fetch(`${API_ENDPOINT}users/update-last-sign-in`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include",
    });
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
}