import { logProducts } from "../api/log-calls/logProducts";
import { updateProduct } from "../api/product-calls/updateProduct";
import { getTimeStamp } from "./getTimeStamp";

export const formatAndUpdateProduct = async (data) => {
  // Replace empty strings with null value
  for (let i in data) {
    if (data[i] === "") {
      data[i] = null;
    }
  }
  data.publication_time = new Date(data.publication_time);
  const response = await updateProduct(data);
  const timestamp = getTimeStamp();
  if (response === "success") {
    logProducts({
      productId: data.id,
      action: `Product edited.`,
      response: "Success",
      dateTime: timestamp,
    });
    return false;
  } else {
    const match = response.match(/\((.*?)\)/);
    const key = match[1];
    switch (key) {
      case "long_name":
        return "Long Name already exists.";
      case "short_name":
        return "Short Name already exists.";
      case "abbr_code":
        return "Abbreviated Name already exists.";
      case "apir_code":
        return "Apir Code already exists.";
      case "sedol_code":
        return "SEDOL Code already exists.";
      case "cusip_code":
        return "CUSIP Code already exists.";
      case "morningstar_code":
        return "Morningstar Code already exists.";
      case "bloomberg_code":
        return "Bloomberg Code already exists.";
      case "reuters":
        return "Reuters already exists.";
      case "iress_market_identifier":
        return "Iress Market Identifier already exists.";
      case "exchange_ticker":
        return "Exchange Ticker already exists.";
      case "usi_code":
        return "USI Code already exists.";
      case "user_defined_1":
        return "User Defined 1 Code already exists.";
      case "user_defined_2":
        return "User Defined 2 Code already exists.";
      default:
        logProducts({
          userId: data.createdBy,
          productId: null,
          action: "New product added.",
          response: "Failed",
          dateTime: timestamp,
        });
        return "Server error.";
    }
  }
};
