import React from "react";
import "./manageUsersList.css";
import { ManageUsersRow } from "./manage-users-row/ManageUsersRow";

export const ManageUsersList = (props) => {
  // Model
  const { users } = props;

  // Controllers


  // View
  return (
    <div className="manage-users-list-inner">
      {users.length > 0 &&
        users.map((user) => {
          return (
            <ManageUsersRow
              key={user.id}
              user={user}
              handleActivatedBtn={() =>
                props.handleActivatedBtn(user, "activate")
              }
              handleDeactivatedBtn={() =>
                props.handleDeactivatedBtn(user, "deactivate")
              }
              handleResetPasswordBtn={() => props.handleResetPasswordBtn(user)}
            />
          );
        })}
    </div>
  );
};
