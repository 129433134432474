import { API_ENDPOINT } from "..";

export const updateCompanyDetails = async (data) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}companies/update-company-details`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
};
