import React from "react";
import "./isLoadingMessage.css";
import { PulseLoader } from "react-spinners";

export const IsLoadingMessage = (props) => {
  return (
    <div className="loading-msg-container">
      <PulseLoader size={10} color="var(--activeGrey)" loading={props.isLoading} />
    </div>
  );
};
