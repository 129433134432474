import React, { useState, useEffect } from "react";
import { trimStringToMaxLength } from "../../../../../functions/trimStringToMaxLength";

export const TaskForEventRow = (props) => {
  // Model
  const { task, taskStatus } = props;
  const [maxNameLength, setMaxNameLength] = useState("");
  const taskName = trimStringToMaxLength(task.name, maxNameLength);

  // Controllers
  useEffect(() => {
    if (window.innerWidth < 1300) {
      setMaxNameLength(8);
    } else if (window.innerWidth < 1400) {
      setMaxNameLength(15);
    } else if (window.innerWidth < 1624) {
      setMaxNameLength(20);
    } else {
      setMaxNameLength(30);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the maximum length based on the window size
      if (window.innerWidth < 1300) {
        setMaxNameLength(8);
      } else if (window.innerWidth < 1400) {
        setMaxNameLength(15);
      } else if (window.innerWidth < 1624) {
        setMaxNameLength(20);
      } else {
        setMaxNameLength(30);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // View
  return (
    <div key={task.id} className="task-for-event">
      <div className="segment">
        <span>{taskName}</span>
      </div>
      <div className="segment">
        <span>{task.start_date}</span>
      </div>
      <div className="segment">
        <span>{task.end_date}</span>
      </div>
      <div className="segment">
        <div className={`status-tag${taskStatus.className}`}>
          <span>{taskStatus.status}</span>
        </div>
      </div>
    </div>
  );
};
