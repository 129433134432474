import React, { useState, useEffect } from "react";
import "./taskDetailsModal.css";
import { getProductsByTaskId } from "../../../api/product-calls/getProductsByTaskId";
import { NoContentMessage } from "../../no-content-messages/no-content-message/NoContentMessage";
import { IsLoadingMessage } from "../../no-content-messages/is-loading-message/IsLoadingMessage";
export const TaskDetailsModal = (props) => {
  // Model
  const { taskDetails } = props;
  const [productsState, setProductsState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);
  // Controllers
  const getProducts = async () => {
    setIsLoadingState(true);
    const res = await getProductsByTaskId(taskDetails.id);
    setProductsState(res);
    setIsLoadingState(false);
  };

  useEffect(() => {
    getProducts();
  }, []);
  // View
  return (
    <div className="modal-background">
      <div className="task-details-modal-container">
        <div className="task-details-modal-header-container">
          <h2 className="modal-header">Task Details</h2>
          <hr className="modal-header-line" />
        </div>
        <div className="task-details-inner-container scrollable-frame">
          <div className="task-details-section">
            <div>
              <div className="task-details-detail-container">
                <label className="input-label">Task Name</label>
                <div className="task-details-detail task-details-task-name">
                  {taskDetails.name}
                </div>
              </div>
              <div className="task-details-date-time">
                <div className="task-details-detail-container">
                  <label className="input-label">Start Date</label>
                  <div className="task-details-detail">
                    {taskDetails.start_date}
                  </div>
                </div>
                <div className="time-input-container">
                  <label className="input-label">Start Time</label>
                  <div className="task-details-time">
                    {taskDetails.start_time}
                  </div>
                </div>
              </div>
              <div className="task-details-date-time">
                <div className="task-details-detail-container">
                  <label className="input-label">End Date</label>
                  <div className="task-details-detail">
                    {taskDetails.end_date}
                  </div>
                </div>
                <div className="time-input-container">
                  <label className="input-label">End Time</label>
                  <div className="task-details-time">
                    {taskDetails.end_time}
                  </div>
                </div>
              </div>
              <div>
                <div className="task-details-detail-container">
                  <label className="input-label">Status</label>
                  <div className="task-details-detail task-details-detail-status">
                    {taskDetails.status}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="task-details-detail-container">
                <label className="input-label">Description</label>
                <div className="task-details-detail task-details-detail-description">
                  {taskDetails.description}
                </div>
              </div>
            </div>
          </div>
          <div className="task-details-detail-container">
            <label className="input-label">Product List</label>
            <div className="products-list-for-task-details">
              <div className="products-list-for-task-details-inner horizontal-scroll">
                {!isLoadingState ? (
                  productsState.length > 0 ? (
                    <div className="list-of-all-products">
                      {productsState.map((product) => {
                        return (
                          <div
                            key={product.primary_identifier}
                            className="product-for-task-tag"
                          >
                            {product.primary_identifier}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <NoContentMessage message="No Products" />
                  )
                ) : (
                  <IsLoadingMessage isLoading={isLoadingState} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="task-details-footer-section">
          <button className="btn" onClick={props.handleCloseBtn}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
