import React, { useEffect, useState } from "react";
import "./manageUsersPage.css";
import { useSelector } from "react-redux";
import { PageTemplate } from "../../components/page-template/PageTemplate";
import { Frame } from "../../components/frame/Frame";
import { ManageUsersList } from "./manage-users-list/ManageUsersList";
import { UserSearchBar } from "../../components/search-bars/user-search-bar/UserSearchBar";
import { ConfirmationModal } from "../../components/modals/confirmation-modal/ConfirmationModal";
import { getUsersByAbn } from "../../api/user-calls/getUsersByAbn";
import { getUsersByAbnFiltered } from "../../api/user-calls/getUsersByAbnFiltered";
import { activateDeactivateUser } from "../../api/user-calls/activateDeactivateUser";

export const ManageUsersPage = () => {
  // Model
  const manageUsersHeaders = [
    { id: "manage-users-name-header", label: "Name" },
    { id: "manage-users-email-header", label: "Email" },
    { id: "manage-users-last-signed-in-header", label: "Last Signed In" },
    { id: "manage-users-manage-header", label: "Manage" },
  ];
  const companyAbn = useSelector((state) => state.user.userDetails.companyAbn);
  const [usersState, setUsersState] = useState({});
  const [userState, setUserState] = useState({});
  const [showActivateModalState, setShowActivateModalState] = useState(false);
  const [showDeactivateModalState, setShowDeactivateModalState] =
    useState(false);
  const [showResetPasswordModalState, setShowResetPasswordModalState] =
    useState(false);

  const [modalLoadingState, setModalLoadingState] = useState(false);

  // Controllers
  const getUsersData = async () => {
    const res = await getUsersByAbn(companyAbn);
    setUsersState(res.data);
  };

  const handleCancelActivateModal = () => {
    setUserState({});
    setShowActivateModalState(false);
  };

  const handleCancelDeactivateModal = () => {
    setUserState({});
    setShowDeactivateModalState(false);
  };

  const handleCancelResetPasswordModal = () => {
    setUserState({});
    setShowResetPasswordModalState(false);
  };

  const handleActivatedBtn = (user) => {
    setUserState(user);
    setShowDeactivateModalState(true);
  };

  const handleDeactivatedBtn = (user) => {
    setUserState(user);
    setShowActivateModalState(true);
  };

  const handleDeactivateUser = async () => {
    setModalLoadingState(true);
    const res = await activateDeactivateUser(
      { activated: !userState.activated },
      userState.id
    );
    if (res.success) {
      setModalLoadingState(false);
      setShowDeactivateModalState(false);
      getUsersData();
    }
  };

  const handleActivateUser = async () => {
    setModalLoadingState(true);
    const res = await activateDeactivateUser(
      { activated: !userState.activated },
      userState.id
    );
    if (res.success) {
      setModalLoadingState(false);
      setShowActivateModalState(false);
      getUsersData();
    }
  };

  const handleResetPasswordBtn = async (user) => {
    setUserState(user);
    setShowResetPasswordModalState(true);
  };

  const handleFilterUsers = async (type, term) => {
    const res = await getUsersByAbnFiltered(companyAbn, type, term);
    setUsersState(res.data);
  };

  useEffect(() => {
    if (companyAbn) {
      getUsersData();
    }
  }, [companyAbn]);

  // View
  return (
    <PageTemplate pageHeader="Manage Users" layout="single-layout">
      <Frame fullSize={true}>
        {showDeactivateModalState && (
          <ConfirmationModal
            typeProp="deactivate"
            nameProp={userState.sign_in_email}
            handleConfirmBtn={handleDeactivateUser}
            handleCancelBtn={handleCancelDeactivateModal}
            isLoading={modalLoadingState}
          />
        )}
        {showActivateModalState && (
          <ConfirmationModal
            typeProp="activate"
            nameProp={userState.sign_in_email}
            handleConfirmBtn={handleActivateUser}
            handleCancelBtn={handleCancelActivateModal}
          />
        )}
        {showResetPasswordModalState && (
          <ConfirmationModal
            typeProp="reset-password"
            nameProp={"Work In Progress"}
            handleConfirmBtn={handleCancelResetPasswordModal}
            handleCancelBtn={handleCancelResetPasswordModal}
          />
        )}
        <div className="manage-users-page-container">
          <div className="tasks-top-section-container">
            <UserSearchBar
              getAllUsersFunc={getUsersData}
              filterUsersFunc={handleFilterUsers}
            />
          </div>
          <div className="manage-users-content-container">
            <div className="manage-users-header-group">
              {manageUsersHeaders.map((header) => (
                <h3
                  className="manage-users-header"
                  id={header.id}
                  key={header.id}
                >
                  {header.label}
                </h3>
              ))}
            </div>
            <hr className="manage-users-header-divider" />

            <div className="manage-users-list scrollable-frame">
              <ManageUsersList
                users={usersState}
                handleActivatedBtn={handleActivatedBtn}
                handleDeactivatedBtn={handleDeactivatedBtn}
                handleResetPasswordBtn={handleResetPasswordBtn}
              />
            </div>
          </div>
        </div>
      </Frame>
    </PageTemplate>
  );
};
