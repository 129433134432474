import React from "react";
import "./assigneeSelectorRow.css";

export const AssigneeSelectorRow = (props) => {
  return (
    <div className="assignee-selector-row">
      <div className="assignee-selector-row-container">
        <div className="assignee-selector-row-container-section">
          <span>{props.name}</span>
        </div>
        <div className="assignee-selector-row-container-section">
          <span>{props.email}</span>
        </div>
      </div>
      <button className="btn" onClick={props.handleAddBtn}>
        Add
      </button>
    </div>
  );
};
