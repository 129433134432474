import { API_ENDPOINT } from "..";

export const downloadTaskData = async (taskId) => {
  try {
    const response = await fetch(`${API_ENDPOINT}tasks/download/${taskId}`, {
      credentials: "include",
    });

    // Since you're expecting a file download, you might want to handle the response differently
    if (response.ok) {
      // Create a temporary anchor element to trigger the download
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'task_details.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

      return { success: true }; // Or any other success response you prefer
    } else {
      const errorResult = await response.json();
      return { success: false, error: errorResult.msg }; // Return error message
    }
  } catch (err) {
    console.error(err);
    return { success: false, error: "An error occurred during the download." };
  }
};