import React, { useEffect, useState } from "react";
import "./upcomingEvents.css";
import { Link } from "react-router-dom";
import { IsLoadingMessage } from "../../../components/no-content-messages/is-loading-message/IsLoadingMessage";
import { NoContentMessage } from "../../../components/no-content-messages/no-content-message/NoContentMessage";
import { getUpcomingEvents } from "../../../api/event-calls/getUpcomingEvents";
import { UpcomingEventRow } from "./upcoming-event-row/UpcomingEventRow";
import { EventDetailsModal } from "../../../components/modals/event-details-modal/EventDetailsModal";

export const UpcomingEvents = () => {
  // Model
  const [eventListState, setEventListState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [showModalState, setShowModalState] = useState(false);
  const [eventDetailsState, setEventDetailsState] = useState({});
  // Controllers
  const handleEventClick = (event) => {
    setShowModalState(true);
    setEventDetailsState(event);
  };

const handleCloseModalBtn = () => {
  setShowModalState(false);
  setEventDetailsState({});
}

  const getEventsData = async () => {
    setIsLoadingState(true);
    const result = await getUpcomingEvents();
    setEventListState(result);
    setIsLoadingState(false);
  };

  useEffect(() => {
    getEventsData();
  }, []);
  // View
  return (
    <div className="upcoming-events-container">
      {showModalState && <EventDetailsModal eventDetails={eventDetailsState} handleCloseBtn={handleCloseModalBtn} />}
      {!isLoadingState ? (
        eventListState.length > 0 ? (
          <div className="upcoming-events-container-inner scrollable-frame">
            {eventListState.map((event) => {
              return (
                <UpcomingEventRow
                  key={event.id}
                  eventName={event.name}
                  startDate={event.start_date}
                  endDate={event.end_date}
                  handleClick={() => {
                    handleEventClick(event);
                  }}
                />
              );
            })}
          </div>
        ) : (
          <NoContentMessage message="No Upcoming Events" />
        )
      ) : (
        <IsLoadingMessage />
      )}
      <div className="see-all-events-btn-container">
        <Link to="/events" className="link">
          View All
        </Link>
      </div>
    </div>
  );
};
