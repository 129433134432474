import React, { useState } from "react";
import "./bulkUploadProductsModal.css";
import { bulkUploadProducts } from "../../../api/product-calls/bulkUploadProducts";

export const BulkUploadProductsModal = (props) => {
  // Model
  const [uploadBtnState, setUploadBtnState] = useState(false);
  const [selectedFileState, setSelectedFileState] = useState(null);
  // Controllers
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFileState(file);
    setUploadBtnState(true);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", selectedFileState);
    const response = await bulkUploadProducts(formData);
    if (response.success) {
      props.getAllProductsFunc();
      props.handleCancel();
    }
  };

  // View
  return (
    <div className="modal-background">
      <div className="bulk-upload-products-modal">
        <div className="bulk-upload-products-modal-header-container">
          <h2 className="modal-header">Bulk Upload Products</h2>
          <hr className="modal-header-line" />
        </div>
        <div className="bulk-upload-products-inner-container">
          <div className="bulk-upload-products-form">
            <div className="uploaded-file-name-container">
              <span>
                {selectedFileState
                  ? selectedFileState.name
                  : "No file selected."}
              </span>
            </div>
            <label className="btn">
              Browse
              <input
                type="file"
                accept=".csv, .xlsx"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </label>
          </div>
          <div className="bulk-upload-products-footer">
            <div className="bulk-upload-products-btn-container"></div>
            <div className="bulk-upload-products-btn-container">
              {uploadBtnState ? (
                <button className="btn" onClick={handleUpload}>
                  Upload
                </button>
              ) : (
                <button className="disabled-btn" disabled>
                  Upload
                </button>
              )}
            </div>
            <div className="bulk-upload-products-btn-container">
              <button className="btn" onClick={props.handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
