import { API_ENDPOINT } from "..";

export const deleteTaskUserLink = async (taskId, data) => {
  try {
    const response = await fetch(`${API_ENDPOINT}tasks/unassignee-user-from-task/${taskId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.error(`There was an error ${error}`);
    throw error;
  }
};
