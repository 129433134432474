import { API_ENDPOINT } from "..";
import { getTimeStamp } from "../../functions/getTimeStamp";
import { logTasks } from "../log-calls/logTasks";

export const updateTaskByTaskId = async (taskId, data) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}tasks/update-task-details/${taskId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    const res = await response.json();
    await logTasks({
      taskId,
      action: "Task edited.",
      response: res.success ? "Success" : "Fail",
      dateTime: getTimeStamp(),
    });
    return res;
  } catch (error) {
    return error;
  }
};
