import { v4 as uuid } from "uuid";
import { checkIsUnique } from "../api/checkIsUnique";

export const generateUniqueId = async (length, tableName) => {
  const uniqueId = uuid().replace(/-/g, "").slice(0, length);
  const response = await checkIsUnique({ id: uniqueId, tableName, }, );
  if (response) {
    return uniqueId;
  } else {
    generateUniqueId(length);
  }
};
