import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  searchType: "id",
  searchTerm: "",
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    deleteEvent: (state, action) => {
      const eventId = action.payload;
      state.eventsList = state.eventsList.filter(
        (event) => event.id !== eventId
      );
    },

    addNewEvent: (state, action) => {
      const newEventObj = action.payload;
      state.eventsList.push(newEventObj);
    },

    setSearchTerm: (state, action) => {
      if (state.searchType === "status") {
        state.searchTerm = action.payload;
      } else {
        state.searchTerm = action.payload.toLowerCase();
      }
    },

    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
  },
});

export const { deleteEvent, addNewEvent, setSearchTerm, setSearchType } =
  eventsSlice.actions;

export default eventsSlice.reducer;
