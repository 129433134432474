import React, { useState, useEffect } from "react";
import "./taskRow.css";
import { ItemRow } from "../../../../components/item-row/ItemRow";
import { trimStringToMaxLength } from "../../../../functions/trimStringToMaxLength";
import { ClipLoader } from "react-spinners";
import { updateTaskCompletionByTaskId } from "../../../../api/task-calls/updateTaskCompletionByTaskId";
import { getTaskStatusByTaskId } from "../../../../api/task-calls/getTaskStatusByTaskId";
import { OptionButton } from "../../../../components/option-button/OptionButton";

export const TaskRow = (props) => {
  const { task } = props;
  const [maxTaskNameLength, setMaxTaskNameLength] = useState("");
  const taskName = trimStringToMaxLength(task.name, maxTaskNameLength);
  const [maxEventNameLength, setMaxEventNameLength] = useState("");
  const eventName = trimStringToMaxLength(task.event_name, maxEventNameLength);
  const [taskStatusState, setTaskStatusState] = useState(task.status);
  const [taskStatusClassState, setTaskStatusClassState] = useState("");
  const [isCheckedState, setIsCheckedState] = useState(false);
  const [checkIsLoadingState, setCheckIsLoadingState] = useState(false);
  // Controllers
  const handleCheckBtn = async () => {
    setCheckIsLoadingState(true);
    await updateTaskCompletionByTaskId(task.id, {
      isCompleted: !isCheckedState,
    });
    setIsCheckedState(!isCheckedState);
    const result = await getTaskStatusByTaskId(task.id);
    const status = result[0].status;
    setTaskStatusState(status);
    if (status === "Upcoming") {
      setTaskStatusClassState(" status-upcoming");
    } else if (status === "In Progress") {
      setTaskStatusClassState(" status-in-progress");
    } else if (status === "Not Completed") {
      setTaskStatusClassState(" status-incompleted");
    } else if (status === "Completed") {
      setTaskStatusClassState(" status-completed");
    }
    setCheckIsLoadingState(false);
  };

  useEffect(() => {
    if (window.innerWidth < 1300) {
      setMaxTaskNameLength(10);
      setMaxEventNameLength(10);
    } else if (window.innerWidth < 1400) {
      setMaxTaskNameLength(20);
      setMaxEventNameLength(20);
    } else if (window.innerWidth < 1624) {
      setMaxTaskNameLength(30);
      setMaxEventNameLength(30);
    } else if (window.innerWidth < 1824) {
      setMaxTaskNameLength(40);
      setMaxEventNameLength(40);
    } else {
      setMaxTaskNameLength(60);
      setMaxEventNameLength(60);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the maximum length based on the window size
      if (window.innerWidth < 1300) {
        setMaxTaskNameLength(10);
        setMaxEventNameLength(10);
      } else if (window.innerWidth < 1400) {
        setMaxTaskNameLength(20);
        setMaxEventNameLength(20);
      } else if (window.innerWidth < 1624) {
        setMaxTaskNameLength(30);
        setMaxEventNameLength(30);
      } else if (window.innerWidth < 1824) {
        setMaxTaskNameLength(40);
        setMaxEventNameLength(40);
      } else {
        setMaxTaskNameLength(60);
        setMaxEventNameLength(60);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const status = task.status;
    const isCompleted = task.is_completed;
    setIsCheckedState(isCompleted);
    setTaskStatusState(status);
    if (status === "Upcoming") {
      setTaskStatusClassState(" status-upcoming");
    } else if (status === "In Progress") {
      setTaskStatusClassState(" status-in-progress");
    } else if (status === "Not Completed") {
      setTaskStatusClassState(" status-incompleted");
    } else if (status === "Completed") {
      setTaskStatusClassState(" status-completed");
    }
  }, []);

  return (
    <ItemRow>
      <div className="task-row">
        <div className="segment">
          <span>{taskName}</span>
        </div>
        <div className="segment">
          <span>{task.start_date}</span>
        </div>
        <div className="segment">
          <span>{task.end_date}</span>
        </div>
        <div className="segment">
          <span>{eventName}</span>
        </div>
        <div className="segment">
          <div className={`status-tag${taskStatusClassState}`}>
            <span>{taskStatusState}</span>
          </div>
        </div>
        <div className="segment">
          <div className="task-manage-btn-container">
            {checkIsLoadingState ? (
              <div className="bounce-loading-icon-container">
                <ClipLoader
                  size={20}
                  loading={checkIsLoadingState}
                  color="var(--activeGrey)"
                />
              </div>
            ) : (
              <button className="check-btn" onClick={handleCheckBtn}>
                <svg
                  className={
                    isCheckedState ? "checked-btn-icon" : "unchecked-btn-icon"
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 15 15"
                  aria-label="check task button icon"
                >
                  <g>
                    <path d="m7.5,0C3.36,0,0,3.36,0,7.5s3.36,7.5,7.5,7.5,7.5-3.36,7.5-7.5S11.64,0,7.5,0Zm4.3,5.46l-4.5,5.36c-.19.23-.47.36-.77.36s-.58-.13-.77-.36l-2.57-3.06c-.35-.42-.3-1.05.12-1.41.42-.35,1.05-.3,1.41.12l1.81,2.15,3.73-4.45c.35-.42.99-.48,1.41-.12.42.35.48.99.12,1.41Z" />
                  </g>
                </svg>
              </button>
            )}
            <OptionButton
              type="task"
              handleDownload={props.handleDownloadBtn}
              handleExport={props.handleExportBtn}
              handleEdit={props.handleEditBtn}
              handleDelete={props.handleDeleteBtn}
            />
          </div>
        </div>
      </div>
    </ItemRow>
  );
};
