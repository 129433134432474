import { API_ENDPOINT } from "..";

export const bulkUploadProducts = async (data) => {
  try {
    const response = await fetch(`${API_ENDPOINT}products/bulk-upload`, {
      method: "POST",
      body: data,
      credentials: "include",
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};
