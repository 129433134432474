import React, { useState } from "react";
import "./eventSearchBar.css";
import { formatDate } from "../../../functions/formatDate";

export const EventSearchBar = (props) => {
  // Model
  const [searchState, setSearchState] = useState("");
  const [typeState, setTypeState] = useState("");
  const [showInputState, setShowInputState] = useState("");
  const [btnState, setBtnState] = useState(false);

  // Controller
  const handleTypeChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setTypeState(value);
    setSearchState("");
    if (value === "name" || value === "description") {
      setShowInputState("text");
    } else if (value === "start_date" || value === "end_date") {
      setShowInputState("date");
    } else if (value === "status") {
      setShowInputState("dropdown");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeState) {
      props.filterEventsFunc(typeState, searchState);
      setBtnState(true);
    }
    if (!searchState) {
      setBtnState(false);
    }
  };

  const handleClick = () => {
    setBtnState(false);
    setTypeState("");
    setSearchState("");
    setShowInputState("");
    document.querySelector("#event-select").value = "DEFAULT";
    props.getAllEventsFunc();
  };

  const handleDateChange = (e) => {
    setSearchState(e.target.value);
    props.filterEventsFunc(typeState, e.target.value);
    setBtnState(true);
  };

  const handleStatusChange = (e) => {
    props.filterEventsFunc(typeState, e.target.value);
    setBtnState(true);
  };

  // View
  return (
    <div className="event-search-bar-container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="event-select" className="search-by-label">
          Search by:
        </label>
        <select
          id="event-select"
          name="event-select"
          defaultValue={"DEFAULT"}
          onChange={handleTypeChange}
        >
          <option value="DEFAULT" disabled>
            Select...
          </option>
          <option value="name">Event Name</option>
          <option value="start_date">Start Date</option>
          <option value="end_date">End Date</option>
          <option value="description">Description</option>
          <option value="status">Status</option>
        </select>
        {showInputState === "text" && (
          <input
            className="text-input"
            type="text"
            value={searchState}
            placeholder="Type here"
            onChange={(e) => setSearchState(e.target.value)}
          />
        )}
        {showInputState === "date" && (
          <input
            className="date-input"
            type="date"
            value={searchState}
            placeholder="Type here"
            onChange={handleDateChange}
          />
        )}
        {showInputState === "dropdown" && (
          <select
            id="status-select"
            name="status-select"
            defaultValue={"DEFAULT"}
            onChange={handleStatusChange}
          >
            <option value="DEFAULT" disabled>
              Select Status...
            </option>
            <option value="Upcoming">Upcoming</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
            <option value="Not Completed">Not Completed</option>
          </select>
        )}
      </form>
      {btnState && (
        <button className="show-all-btn btn" onClick={handleClick}>
          Show All
        </button>
      )}
    </div>
  );
};
