import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import opusLogo from "../../images/opus-logo.png";
import { SignInForm } from "./sign-in-form/SignInForm";
import "./signInPage.css";
import { isLoggedIn } from "../../api/user-calls/isLoggedIn";

export const SignInPage = () => {
  const navigate = useNavigate();
  const isUserLoggedIn = async () => {
    const result = await isLoggedIn();
    if (result.loggedIn) {
      navigate("/dashboard");
    }
  };
  useEffect(() => {
    isUserLoggedIn();
  }, []);
  return (
    <div className="sign-in-page-container">
      <section className="sign-in-page-left-container">
        <div className="sign-in-page-logo-container">
          <img className="sign-in-page-logo" src={opusLogo} alt="opus" />
        </div>
        <h1>Sign In</h1>
        <SignInForm />
        <div className="sign-up-link-container">
          <span>Don't have an account?</span>
          <Link to="/sign-up" id="sign-up-link-alt">Sign Up</Link>
        </div>
      </section>
      <section className="sign-in-page-right-container">
        <h2>Don't have an Account?</h2>
        <Link to="/sign-up" className="btn" id="sign-up-link">
          Sign Up
        </Link>
      </section>
    </div>
  );
};
