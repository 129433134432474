import { API_ENDPOINT } from "..";

export const activateDeactivateUser = async (data, userId) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}users/activate-deactivate/${userId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    if (!response.ok) {
      throw new Error("Failed to update activation of user");
    }
    const res = await response.json();
    return res;
  } catch (error) {
    console.error(error);
  }
};
