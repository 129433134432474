import { API_ENDPOINT } from "..";

export const checkProductTaskLinks = async (productId) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}products/check-product-task-link/${productId}`,
      {
        credentials: "include",
      }
    );
    let result = await response.json();
    return result.success;
  } catch (err) {
    console.error(err);
  }
};
