import { API_ENDPOINT } from "..";

export const getUsersByAbnFiltered = async (abn, type, term) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}users/get-users-by-abn/${abn}/filter/?type=${type}&term=${term}`,
      {
        credentials: "include",
      }
    );
    const result = await response.json();
    return result;
  } catch (err) {
    console.error(err);
  }
};
