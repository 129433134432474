import React from "react";
import "./confirmationModal.css";
import { ClipLoader } from "react-spinners";

export const ConfirmationModal = (props) => {
  // Model
  let confirmationMessage;
  if (props.typeProp === "event") {
    confirmationMessage =
      "All related tasks will also be deleted. Are you sure you want to delete event?";
  } else if (props.typeProp === "task") {
    confirmationMessage = "Are you sure you want to delete task:";
  } else if (props.typeProp === "product") {
    confirmationMessage = "Are you sure you want to delete product:";
  } else if (props.typeProp === "product-linked") {
    confirmationMessage =
      "This product is linked to tasks. Are you sure you want to delete product:";
  } else if (props.typeProp === "activate") {
    confirmationMessage = "Activate User:";
  } else if (props.typeProp === "deactivate") {
    confirmationMessage = "Deactivate User:";
  } else if (props.typeProp === "reset-password") {
    confirmationMessage = "Reset password for:";
  }

  // Controllers
  // View
  return (
    <div className="modal-background">
      <div className="confirmation-modal-container">
        <h3 className="confirmation-message">{confirmationMessage}</h3>
        <span className="confirmation-modal-name">{props.nameProp}</span>
        {props.isLoading ? (
          <ClipLoader />
        ) : (
          <div className="modal-btn-container">
            <button className="btn" onClick={props.handleConfirmBtn}>
              Confirm
            </button>
            <button className="btn" onClick={props.handleCancelBtn}>
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
