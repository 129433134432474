import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_ENDPOINT } from "../api";

const initialState = {
  contactsList: [],
  assigneesByEmail: [],
  isLoading: false,
};

export const getContactsData = createAsyncThunk(
  "contactsData/getContactsData",
  async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}users/get-contacts`, {
        credentials: "include",
      });
      const result = await response.json();
      if (result.success) {
        return result.data;
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const getAssigneesByEmail = createAsyncThunk(
  "contactsData/getAssigneesByEmail",
  async (email) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT}users/get-users-by-email/${email}`,
        {
          credentials: "include",
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getContactsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContactsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contactsList = action.payload;
      })
      .addCase(getContactsData.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(getAssigneesByEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssigneesByEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.assigneesByEmail = action.payload.data;
      })
      .addCase(getAssigneesByEmail.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

// export const {} = contactsSlice.actions;

export default contactsSlice.reducer;
