import React from "react";
import "./aboutPage.css";
import { PageTemplate } from "../../components/page-template/PageTemplate";
import { Frame } from "../../components/frame/Frame";


export const AboutPage = () => {

  return (
    <PageTemplate pageHeader="About" layout="single-layout">
      <Frame fullSize={true}>
        <span>Ver: 0.8.3</span>
      </Frame>
    </PageTemplate>
  );
};
