import { API_ENDPOINT } from "..";

export const getAllNotifications = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}notifications`, {
      credentials: "include",
    });
    let result = await response.json();
    if (result.success) {
      return result.data;
    }
  } catch (err) {
    console.error(err);
  }
};
