import React from "react";
import "./contactRow.css";
import { ItemRow } from "../../../../components/item-row/ItemRow";

export const ContactRow = (props) => {
  return (
    <ItemRow>
      <div className="contact-row">
        <div className="segment">
          <span>{props.name}</span>
        </div>
        <div className="segment">
          <span>{props.email}</span>
        </div>
      </div>
    </ItemRow>
  );
};
