import React from "react";
import { TextInput } from "../../../../components/input-fields/text-input/TextInput";
import { DateInput } from "../../../../components/input-fields/date-input/DateInput";
import { TimeInput } from "../../../../components/input-fields/time-input/TimeInput";
import { LargeTextInput } from "../../../../components/input-fields/large-text-input/LargeTextInput";
import { ProductSelector } from "../../../../components/selectors/product-selector/ProductSelector";
import { AssigneeSelector } from "../../../../components/selectors/assignee-selector/AssigneeSelector";

export const AddTaskForm = (props) => {
  // Model
  const { taskState } = props;
  // View
  return (
    <div>
      <div className="create-event-form-add-tasks scrollable-frame">
        <div className="create-event-form-add-tasks-inputs">
          <div className="create-event-form-add-tasks-inputs-section">
            <div className="create-event-name-container">
              <TextInput
                labelName="Task Name"
                fieldName="task-name"
                valueProp={taskState.name}
                onChangeFunc={props.taskNameFunc}
                maxLengthProp={60}
                isRequired={true}
              />
            </div>
            <div className="create-event-dates-container">
              <div className="create-event-date-time">
                <DateInput
                  labelName="Start Date"
                  fieldName="task-start-date"
                  valueProp={taskState.startDate}
                  onChangeFunc={props.taskStartDateFunc}
                  isRequired={true}
                  minDate={props.minStartDate}
                  maxDate={props.maxDate}
                />
                <TimeInput
                  labelName="*Start Time"
                  time={taskState.startTime}
                  handleTimeSelect={props.taskStartTimeFunc}
                  isChecked={taskState.startTimeAllDay}
                  handleCheck={props.taskStartTimeCheckFunc}
                />
              </div>
              <div className="create-event-date-time">
                <DateInput
                  labelName="End Date"
                  fieldName="task-end-date"
                  valueProp={taskState.endDate}
                  onChangeFunc={props.taskEndDateFunc}
                  isRequired={true}
                  minDate={props.minEndDate}
                  maxDate={props.maxDate}
                />
                <TimeInput
                  labelName="*End Time"
                  time={taskState.endTime}
                  handleTimeSelect={props.taskEndTimeFunc}
                  isChecked={taskState.endTimeAllDay}
                  handleCheck={props.taskEndTimeCheckFunc}
                />
              </div>
            </div>
          </div>

          <div className="create-event-form-add-tasks-inputs-section">
            <div className="create-event-task-descripion-container">
              <LargeTextInput
                labelName="Task Description"
                fieldName="task-description"
                valueProp={taskState.description}
                onChangeFunc={props.taskDescriptionFunc}
              />
            </div>
          </div>
        </div>

        <div className="create-event-product-selector-container">
          <ProductSelector
            addProductsBtnFunc={props.addProductsBtnFunc}
            productList={props.productList}
            selectedProducts={props.selectedProducts}
            addToSelectedProducts={props.addToSelectedProducts}
            removeFromSelectedProducts={props.removeFromSelectedProducts}
            productTagRemoveFunc={props.productTagRemoveFunc}
          />
        </div>
        <div className="create-event-assignees-list">
          <AssigneeSelector
            assigneeList={props.assigneeList}
            handleAddAssigneeBtn={props.handleAddAssigneeBtn}
            handleRemoveAssigneeBtn={props.handleRemoveAssigneeBtn}
          />
        </div>
      </div>
    </div>
  );
};
