import React, { useEffect, useState } from "react";
import "./productsPage.css";
import { PageTemplate } from "../../components/page-template/PageTemplate";
import { Frame } from "../../components/frame/Frame";
import { ProductSearchBar } from "../../components/search-bars/product-search-bar/ProductSearchBar";
import { ProductsList } from "./products-list/ProductsList";
import { AddProductModal } from "./add-product-modal/AddProductModal";
import { EditProductModal } from "./edit-product-modal/EditProductModal";
import { getAllProducts } from "../../api/product-calls/getAllProducts";
import { getAllProductsByFilter } from "../../api/product-calls/getAllProductsByFilter";
import { BulkUploadProductsModal } from "./bulk-upload-products-modal/BulkUploadProductsModal";
import { AddProductBtn } from "./add-product-btn/AddProductBtn";
import { bulkDownloadProductsData } from "../../api/product-calls/bulkDownloadProductsData";

export const ProductsPage = () => {
  // Model
  const [productsState, setProductsState] = useState([]);
  const [addProductModalState, setAddProductModalState] = useState(false);
  const [bulkUploadModalState, setBulkUploadModalState] = useState(false);
  const [productToEditState, setProductToEditState] = useState({});
  const [editProductModalState, setEditProductModalState] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState([]);

  const productHeaders = [
    { id: "product-name-header", label: "Product Name" },
    { id: "product-primary-id-type-header", label: "Primary ID Type" },
    { id: "product-primary-id-header", label: "Primary ID" },
    { id: "product-fund-manager-header", label: "Fund Manager" },
    { id: "product-status-header", label: "Status" },
    { id: "product-manage-header", label: "Manage" },
  ];

  // Controllers
  const getProductsData = async () => {
    
    setIsLoadingState(true);
    const result = await getAllProducts();
    setProductsState(result);
    setIsLoadingState(false);
  };

  const getProductsByFilterFunc = async (type, term) => {
    setIsLoadingState(true);
    const result = await getAllProductsByFilter(type, term);
    setProductsState(result);
    setIsLoadingState(false);
  };

  const handleAddProduct = () => {
    setAddProductModalState(true);
  };

  const handleCancelAddProduct = () => {
    setAddProductModalState(false);
  };

  const handleBulkUpload = () => {
    setBulkUploadModalState(true);
  };

  const handleCancelBulkUpload = () => {
    setBulkUploadModalState(false);
  };

  const handleEditProductBtn = (product) => {
    setProductToEditState(product);
    setEditProductModalState(true);
  };

  const handleEditModalCancelBtn = () => {
    setProductToEditState({});
    setEditProductModalState(false);
  };

  const handleBulkDownloadBtn = () => {
    bulkDownloadProductsData();
  };

  useEffect(() => {
    getProductsData();
  }, []);

  // View
  return (
    <PageTemplate pageHeader="Products" layout="single-layout">
      {addProductModalState && (
        <AddProductModal getAllProductsFunc={getProductsData} onClickFunc={handleCancelAddProduct} />
      )}
      {bulkUploadModalState && (
        <BulkUploadProductsModal
          getAllProductsFunc={getProductsData}
          handleCancel={handleCancelBulkUpload}
        />
      )}
      {editProductModalState && (
        <EditProductModal
          getAllProductsFunc={getProductsData}
          productDetails={productToEditState}
          onClickFunc={handleEditModalCancelBtn}
        />
      )}
      <Frame fullSize={true}>
        <div className="products-inner-frame-container">
          <div className="top-section-container">
            <ProductSearchBar
              getAllProductsFunc={getProductsData}
              filterProductsFunc={getProductsByFilterFunc}
            />
            <div className="add-products-btn-container">
              <AddProductBtn
                handleAddProduct={handleAddProduct}
                handleBulkUpload={handleBulkUpload}
              />
              <button className="btn" onClick={handleBulkDownloadBtn}>
                Bulk Download
              </button>
            </div>
          </div>
          {/* ------------------------------------ */}
          <div className="products-content-container">
            <div className="products-header-group">
              {productHeaders.map((header) => (
                <h3 className="products-header" id={header.id} key={header.id}>
                  {header.label}
                </h3>
              ))}
            </div>
            <hr className="product-header-divider" />
            <div className="products-list scrollable-frame">
              <ProductsList
                productsData={productsState}
                getProductsFunc={getProductsData}
                getProductsByFilterFunc={getProductsByFilterFunc}
                isLoading={isLoadingState}
                handleEditProductBtn={handleEditProductBtn}
              />
            </div>
          </div>
        </div>
      </Frame>
    </PageTemplate>
  );
};
