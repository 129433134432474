import React, { useState, useEffect } from "react";
import "./calendarPage.css";
import { PageTemplate } from "../../components/page-template/PageTemplate";
import { Frame } from "../../components/frame/Frame";
import { DayViewCalendar } from "./day-view/DayViewCalendar";
import { MonthViewCalendar } from "./month-view/MonthViewCalendar";
import { WeekViewCalendar } from "./week-view/WeekViewCalendar";
import { getAllEventsById } from "../../api/event-calls/getAllEventsById";
import { getAllTasksById } from "../../api/task-calls/getAllTasksById";

export const CalendarPage = () => {
  // Model --------------------
  const [eventsState, setEventsState] = useState([]);
  const [tasksState, setTasksState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [viewState, setViewState] = useState("day");
  const [animationState, setAnimationState] = useState("");

  // Controllers ------------------
  const handleClick = async (e) => {
    const selectedBtn = e.target;
    const currentBtn = document.querySelector(".selected");
    currentBtn.classList.remove("selected");
    selectedBtn.classList.add("selected");

    // sets btn to be disabled
    currentBtn.disabled = false;
    selectedBtn.disabled = true;

    // sets animation to play
    if (
      (viewState === "day" && selectedBtn.value === "week") ||
      (viewState === "day" && selectedBtn.value === "month") ||
      (viewState === "week" && selectedBtn.value === "month")
    ) {
      setAnimationState("slide-out-left");
      await new Promise((resolve) => setTimeout(resolve, 145));
      setViewState(selectedBtn.value);
      setAnimationState("slide-in-left");
      await new Promise((resolve) => setTimeout(resolve, 150));
      setAnimationState("");
    } else {
      setAnimationState("slide-out-right");
      await new Promise((resolve) => setTimeout(resolve, 145));
      setViewState(selectedBtn.value);
      setAnimationState("slide-in-right");
      await new Promise((resolve) => setTimeout(resolve, 150));
      setAnimationState("");
    }
  };

  const viewMode = () => {
    if (viewState === "month") {
      return (
        <div className={`calendar-type-container ${animationState}`}>
          <MonthViewCalendar events={eventsState} tasks={tasksState} />
        </div>
      );
    } else if (viewState === "week") {
      return (
        <div className={`calendar-type-container ${animationState}`}>
          <WeekViewCalendar events={eventsState} tasks={tasksState} />
        </div>
      );
    } else {
      return (
        <div className={`calendar-type-container ${animationState}`}>
          <DayViewCalendar events={eventsState} tasks={tasksState} />
        </div>
      );
    }
  };

  const getEventsAndTasksData = async () => {
    setIsLoadingState(true);
    const eventData = await getAllEventsById();
    const taskData = await getAllTasksById();
    setEventsState(eventData);
    setTasksState(taskData);
    setIsLoadingState(false);
  };

  useEffect(() => {
    getEventsAndTasksData();
  }, []);

  // View --------------------------
  return (
    <PageTemplate pageHeader="Calendar" layout="single-layout">
      <Frame fullSize={true}>
        <div className="calendar-container">
          <div className="calendar-selector-container">
            <div className="calendar-selector-inner-container">
              <button
                className="calendar-selector-btn selected"
                onClick={handleClick}
                value="day"
              >
                Day
              </button>

              <button
                className="calendar-selector-btn"
                onClick={handleClick}
                value="week"
              >
                Week
              </button>
              <button
                className="calendar-selector-btn"
                onClick={handleClick}
                value="month"
              >
                Month
              </button>
            </div>
          </div>
          <div className="calendar-inner-container">{viewMode()}</div>
        </div>
      </Frame>
    </PageTemplate>
  );
};
