import React, { useState, useEffect } from "react";
import "./weekCalendarTile.css";
import { trimStringToMaxLength } from "../../../../functions/trimStringToMaxLength";

export const WeekCalendarTile = (props) => {
  // Model
  const [maxNameLengthState, setMaxNameLengthState] = useState("");
  // Controllers
  useEffect(() => {
    if (window.innerWidth < 1300) {
      setMaxNameLengthState(6);
    } else if (window.innerWidth < 1400) {
      setMaxNameLengthState(9);
    } else if (window.innerWidth < 1624) {
      setMaxNameLengthState(10);
    } else if (window.innerWidth < 1700) {
      setMaxNameLengthState(12);
    } else if (window.innerWidth < 1870) {
      setMaxNameLengthState(16);
    } else {
      setMaxNameLengthState(20);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1300) {
        setMaxNameLengthState(6);
      } else if (window.innerWidth < 1400) {
        setMaxNameLengthState(9);
      } else if (window.innerWidth < 1624) {
        setMaxNameLengthState(10);
      } else if (window.innerWidth < 1700) {
        setMaxNameLengthState(12);
      } else if (window.innerWidth < 1870) {
        setMaxNameLengthState(16);
      } else {
        setMaxNameLengthState(20);
      }
    };
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // View
  return (
    <div className="week-calendar-tile-background">
      <div className={`week-calendar-tile${props.classProp}`}>
        <div className="week-calendar-tile-number-container">
          <span className="week-calendar-tile-number">{props.dayNum}</span>
        </div>
        <div className="week-calendar-tile-details-container">
          {props.events.length > 0 && (
            <div className="week-calendar-tile-events-list-container">
              <h5 className="week-calendar-tile-details-header">Events</h5>
              <hr className="week-calendar-tile-details-divider" />
              <div className="week-calendar-tile-tasks-list  scrollable-frame">
                {props.events.map((event) => {
                  return (
                    <div
                      className={`week-calendar-tile-event${
                        event.dateConflict ? "-expired" : ""
                      }`}
                      key={event.id}
                      onClick={() => props.eventClick(event)}
                    >
                      <span>
                        {trimStringToMaxLength(event.name, maxNameLengthState)}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {props.tasks.length > 0 && (
            <div className="week-calendar-tile-tasks-list-container">
              <h5 className="week-calendar-tile-details-header">Tasks</h5>
              <hr className="week-calendar-tile-details-divider" />
              <div className="week-calendar-tile-tasks-list scrollable-frame">
                {props.tasks.map((task) => {
                  return (
                    <div
                      className={`week-calendar-tile-task${
                        task.dateConflict ? "-expired" : ""
                      }`}
                      key={task.id}
                      onClick={() => props.taskClick(task)}
                    >
                      <span>
                        {trimStringToMaxLength(task.name, maxNameLengthState)}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
