import React, { useState } from "react";
import "./signUpBillingOptions.css";

export const SignUpBillingOptions = (props) => {
  // Model
  const [buttonState, setButtonState] = useState(false);
  const [selectedCardState, setSelectedCardState] = useState(0);
  // Controllers
  const handleCardClick = (num) => {
    if (!buttonState) {
      setButtonState(true);
    }
    setSelectedCardState(num);
  };

  // View
  return (
    <div className="billing-options">
      <div className="billing-options-container">
        <div
          className={`billing-option-card${
            selectedCardState !== 1 && selectedCardState !== 0
              ? " unselected-billing-option"
              : ""
          }${selectedCardState === 1 ? " selected-billing-option" : ""}`}
          onClick={() => handleCardClick(1)}
        >
          <div className="billing-option-card-header">
            <h2>Free</h2>
            <hr className="billing-options-seperator" />
          </div>
          <ul>
            <li className="property">nfdkjldslf dghsogdg dgsd</li>
          </ul>
          <div className="billing-option-price-container">
            <hr className="billing-options-seperator" />
            <span>$0.00/month</span>
          </div>
        </div>
        <div
          className={`billing-option-card${
            selectedCardState !== 2 && selectedCardState !== 0
              ? " unselected-billing-option"
              : ""
          }${selectedCardState === 2 ? " selected-billing-option" : ""}`}
          onClick={() => handleCardClick(2)}
        >
          <div className="billing-option-card-header">
            <h2>Single User</h2>
            <hr className="billing-options-seperator" />
          </div>
          <ul>
            <li className="property">nfdkjldslf dghsogdg dgsd</li>
            <li className="property">nfdkjldslf dghsogdg dgsd</li>
            <li className="property">nfdkjldslf dghsogdg dgsd</li>
          </ul>
          <div className="billing-option-price-container">
            <hr className="billing-options-seperator" />
            <span>$1.99/month</span>
          </div>
        </div>
        <div
          className={`billing-option-card${
            selectedCardState !== 3 && selectedCardState !== 0
              ? " unselected-billing-option"
              : ""
          }${selectedCardState === 3 ? " selected-billing-option" : ""}`}
          onClick={() => handleCardClick(3)}
        >
          <div className="billing-option-card-header">
            <h2>Company</h2>
            <hr className="billing-options-seperator" />
          </div>
          <ul>
            <li className="property">nfdkjldslf dghsogdg dgsd</li>
            <li className="property">nfdkjldslf dghsogdg dgsd</li>
            <li className="property">nfdkjldslf dghsogdg dgsd</li>
            <li className="property">nfdkjldslf dghsogdg dgsd</li>
          </ul>
          <div className="billing-option-price-container">
            <hr className="billing-options-seperator" />
            <span>$14.99/month</span>
          </div>
        </div>
      </div>
      {buttonState ? (
        <button className="btn" onClick={props.handleSubmit}>
          Confirm
        </button>
      ) : (
        <button className="disabled-btn" disabled>
          Confirm
        </button>
      )}
    </div>
  );
};
