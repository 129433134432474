import { API_ENDPOINT } from "..";
import { logProducts } from "../log-calls/logProducts";
import { getTimeStamp } from "../../functions/getTimeStamp";

export const deleteProductById = async (productId) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}products/delete-product/${productId}`,
      {
        method: "DELETE",
      }
    );
    const res = await response.json();
    await logProducts({
      productId,
      action: "Product deleted.",
      response: res.success ? "Success" : "Fail",
      dateTime: getTimeStamp(),
    });
    return res;
  } catch (error) {
    console.error(`There was an error ${error}`);
    throw error;
  }
};
