import React, { useState, useEffect } from "react";
import "./timeSlots.css";
import { getAllEventsToday } from "../../api/event-calls/getAllEventsToday";
import { getAllTasksToday } from "../../api/task-calls/getAllTasksToday";
import { EventDetailsModal } from "../modals/event-details-modal/EventDetailsModal";
import { TaskDetailsModal } from "../modals/task-details-modal/TaskDetailsModal";

export const TimeSlots = () => {
  // Model
  const [eventState, setEventState] = useState([]);
  const [taskState, setTaskState] = useState([]);
  const [eventModalState, setEventModalState] = useState(false);
  const [eventDetailState, setEventDetailState] = useState({});
  const [taskModalState, setTaskModalState] = useState(false);
  const [taskDetailState, setTaskDetailState] = useState({});
  const timeBoxes = [];
  for (let hour = 0; hour <= 23; hour++) {
    // Loop through the minutes (0 and 30)
    for (let minute = 0; minute <= 30; minute += 30) {
      const amOrPm = hour < 12 ? "AM" : "PM";

      // Convert the hour to 12-hour format
      const formattedHour = (hour % 12 === 0 ? 12 : hour % 12)
        .toString()
        .padStart(2, "0");

      // Format the minute as a string with leading zeros if necessary
      const formattedMinute = minute.toString().padStart(2, "0");

      // Create a time string in "hh:mm AM/PM" format
      const timeString = formattedHour + ":" + formattedMinute + " " + amOrPm;

      // Add the time string to the array
      timeBoxes.push(timeString);
    }
  }
  // Controllers
  const getEventData = async () => {
    const response = await getAllEventsToday();
    setEventState(response);
  };

  const getTaskData = async () => {
    const response = await getAllTasksToday();
    setTaskState(response);
  };

  const handleEventClick = (event) => {
    setEventDetailState(event);
    setEventModalState(true);
  };

  const handleEventModalCloseBtn = () => {
    setEventDetailState({});
    setEventModalState(false);
  };

  const handleTaskClick = (task) => {
    setTaskDetailState(task);
    setTaskModalState(true);
  };

  const handleTaskModalCloseBtn = () => {
    setTaskDetailState({});
    setTaskModalState(false);
  };

  useEffect(() => {
    getEventData();
    getTaskData();
  }, []);

  // View
  return (
    <div className="time-slots-container">
      {eventModalState && (
        <EventDetailsModal
          eventDetails={eventDetailState}
          handleCloseBtn={handleEventModalCloseBtn}
        />
      )}
      {taskModalState && (
        <TaskDetailsModal
          taskDetails={taskDetailState}
          handleCloseBtn={handleTaskModalCloseBtn}
        />
      )}
      {timeBoxes.map((time) => (
        <div key={time} className="time-slot">
          <div className="time-details">
            <div className="time-header">{time}</div>
            <hr className="time-header-divider" />
          </div>
          <div className="time-slot-item-list">
            {eventState.map((event) => {
              if (event.start_time === time) {
                return (
                  <div
                    key={event.id}
                    className="item-on-today"
                    onClick={() => handleEventClick(event)}
                  >
                    <span>Event</span>
                    <span className="item-on-today-name">{event.name}</span>
                  </div>
                );
              }
            })}
            {taskState.map((task) => {
              if (task.start_time === time) {
                return (
                  <div
                    key={task.id}
                    className="item-on-today"
                    onClick={() => handleTaskClick(task)}
                  >
                    <span>Task</span>
                    <span className="item-on-today-name">{task.name}</span>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
