import React, { useEffect, useState } from "react";
import "./signUpUserDetails.css";
import { TextInput } from "../../../../components/input-fields/text-input/TextInput";
import validator from "validator";
import { checkEmailAvailability } from "../../../../api/user-calls/checkEmailAvailability";
import { ClipLoader } from "react-spinners";

export const SignUpUserDetails = (props) => {
  // Model
  const { userDetails } = props;
  const [errorEmailInUseState, setErrorEmailInUseState] = useState(false);
  const [errorEmailState, setErrorEmailState] = useState(false);
  const [errorMobileState, setErrorMobileState] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  // Controllers
  const handleNextBtn = async () => {
    if (!validator.isEmail(userDetails.email)) {
      setErrorEmailState(true);
      return;
    }
    if (!validator.isMobilePhone(userDetails.mobile)) {
      setErrorMobileState(true);
      return;
    }
    setIsLoadingState(true);
    const res = await checkEmailAvailability(userDetails.email);
    if (res.success) {
      props.handleClick();
    } else {
      setErrorEmailInUseState(true);
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    if (
      !validator.isEmpty(userDetails.firstName) &&
      !validator.isEmpty(userDetails.lastName) &&
      !validator.isEmpty(userDetails.email) &&
      !validator.isEmpty(userDetails.mobile)
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [userDetails]);

  // View
  return (
    <div className="sign-up-form-frame">
      <div className="sign-up-user-details">
        <h3 className="sign-up-sub-header">Details</h3>
        <div className="sign-up-input-fields-details">
          <TextInput
            labelName="First Name"
            fieldName="sign-up-first-name"
            valueProp={userDetails.firstName}
            onChangeFunc={props.firstNameFunc}
            autoComplete="given-name"
            isRequired={true}
          />
          <TextInput
            labelName="Last Name"
            fieldName="sign-up-last-name"
            valueProp={userDetails.lastName}
            onChangeFunc={props.lastNameFunc}
            autoComplete="family-name"
            isRequired={true}
          />
          <div className="sign-up-email-container">
            <TextInput
              labelName="Email"
              fieldName="sign-up-email"
              valueProp={userDetails.email}
              onChangeFunc={(e) => {
                if (errorEmailInUseState || errorEmailState) {
                  setErrorEmailInUseState(false);
                  setErrorEmailState(false);
                }
                props.emailFunc(e);
              }}
              autoComplete="email"
              isRequired={true}
            />
            {errorEmailState ? (
              <span className="email-not-valid-error error-msg">
                Email not valid.
              </span>
            ) : null}
            {errorEmailInUseState ? (
              <span className="email-in-use-error error-msg">
                Email already in use.
              </span>
            ) : null}
          </div>
          <div className="sign-up-mobile-container">
            <TextInput
              labelName="Mobile"
              fieldName="sign-up-mobile"
              valueProp={userDetails.mobile}
              onChangeFunc={(e) => {
                if (errorMobileState) {
                  setErrorMobileState(false);
                }
                props.mobileFunc(e);
              }}
              autoComplete="tel-local"
              isRequired={true}
            />
            {errorMobileState ? (
              <span className="mobile-not-valid-error error-msg">
                Mobile number not valid.
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {buttonState ? (
        isLoadingState ? (
          <div className="loading-container">
            <ClipLoader
              size={20}
              loading={isLoadingState}
              color="var(--activeGrey)"
            />
          </div>
        ) : (
          <button className="btn" id="sign-up-btn" onClick={handleNextBtn}>
            Next
          </button>
        )
      ) : (
        <button className="disabled-btn" id="sign-up-btn">
          Next
        </button>
      )}
    </div>
  );
};
