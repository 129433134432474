import React, { useEffect, useState } from "react";
import "./tasksList.css";
import { TaskRow } from "./task-row/TaskRow";
import { NoContentMessage } from "../../../components/no-content-messages/no-content-message/NoContentMessage";
import { IsLoadingMessage } from "../../../components/no-content-messages/is-loading-message/IsLoadingMessage";
import { ConfirmationModal } from "../../../components/modals/confirmation-modal/ConfirmationModal";
import { deleteTaskByTaskId } from "../../../api/task-calls/deleteTaskByTaskId";
import { downloadTaskData } from "../../../api/task-calls/downloadTaskData";
import { exportTaskById } from "../../../api/export-calls/exportTaskById";

export const TasksList = (props) => {
  // Model
  const { tasksData } = props;
  const [tasksState, setTasksState] = useState([]);
  const [confirmDeleteModalState, setConfirmDeleteModalState] = useState(false);
  const [taskToDeleteState, setTaskToDeleteState] = useState({});

  // Controllers
  const handleDeleteBtn = (taskId, taskName) => {
    setConfirmDeleteModalState(true);
    setTaskToDeleteState({ id: taskId, name: taskName });
  };

  const handleDownloadBtn = (taskId) => {
    downloadTaskData(taskId);
  };

  const handleExportBtn = (taskId, taskName) => {
    exportTaskById(taskId, taskName);
  };

  // modal btns
  const handleModalCancelBtn = () => {
    setConfirmDeleteModalState(false);
    setTaskToDeleteState({});
  };

  const handleModalConfirmBtn = async () => {
    const response = await deleteTaskByTaskId(taskToDeleteState.id);
    if (response.success) {
      setConfirmDeleteModalState(false);
      setTaskToDeleteState({});
      props.getTasksFunc();
    } else {
      console.log("Server Error!!!!");
    }
  };

  // -------------------------------------------
  useEffect(() => {
    if (tasksData.length > 0) {
      setTasksState(tasksData);
    } else {
      setTasksState([]);
    }
  }, [tasksData]);

  // View
  return (
    <div className="tasks-list-inner">
      {confirmDeleteModalState && (
        <ConfirmationModal
          nameProp={taskToDeleteState.name}
          typeProp="task"
          handleCancelBtn={handleModalCancelBtn}
          handleConfirmBtn={handleModalConfirmBtn}
        />
      )}
      {!props.isLoading ? (
        tasksState.length > 0 ? (
          tasksState.map((task) => {
            return (
              <TaskRow
                key={task.id}
                task={task}
                dateConflict={task.dateConflict}
                handleDownloadBtn={() => {
                  handleDownloadBtn(task.id);
                }}
                handleExportBtn={() => {
                  handleExportBtn(task.id, task.name);
                }}
                handleEditBtn={() => {
                  props.handleEditBtn(task);
                }}
                handleDeleteBtn={() =>
                  handleDeleteBtn(task.id, task.name)
                }
              />
            );
          })
        ) : (
          <NoContentMessage message="No Tasks" />
        )
      ) : (
        <IsLoadingMessage isLoading={props.isLoading} />
      )}
    </div>
  );
};
