import { API_ENDPOINT } from "..";
import { formatDate } from "../../functions/formatDate";

export const getUpcomingEvents = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}events/upcoming-events`, {
      credentials: "include",
    });
    const result = await response.json();
    if (result.success) {
      let events = result.data;
      for (let event of events) {
        const formattedStartDateTime = formatDate(event.start_date)
        const formattedEndDateTime = formatDate(event.end_date)
        event.start_date = formattedStartDateTime.formattedDate;
        event.start_time = formattedStartDateTime.formattedTime;
        event.end_date = formattedEndDateTime.formattedDate;
        event.end_time = formattedEndDateTime.formattedTime;
      }
      return events;
    }
  } catch (err) {
    console.error(err);
  }
};
