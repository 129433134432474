import React, { useState, useEffect } from "react";
import "./eventsPage.css";
import { PageTemplate } from "../../components/page-template/PageTemplate";
import { Frame } from "../../components/frame/Frame";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EventsList } from "./events-list/EventsList";
import { CreateNewEventModal } from "./create-new-event-modal/CreateNewEventModal";
import { EventSearchBar } from "../../components/search-bars/event-search-bar/EventSearchBar";
import { clearSelectedTasksList } from "../../features/tasksSlice";
import { getAllEventsById } from "../../api/event-calls/getAllEventsById";
import { EditEventModal } from "./edit-event-modal/EditEventModal";
import { EventDetailsModal } from "../../components/modals/event-details-modal/EventDetailsModal";
import { getAllEventsByFilter } from "../../api/event-calls/getAllEventsByFilter";
import { bulkDownloadEventsData } from "../../api/event-calls/bulkDownloadEventsData";

export const EventsPage = () => {
  // Model --------------------
  const dispatch = useDispatch();
  const [eventsState, setEventsState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState([]);
  const [createEventModalState, setCreateEventModalState] = useState(false);
  const [viewEventModalState, setViewEventModalState] = useState(false);
  const [eventToViewState, setEventToViewState] = useState({});
  const [editEventModalState, setEditEventModalState] = useState(false);
  const [eventToEditState, setEventToEditState] = useState({});

  const eventHeaders = [
    { id: "event-name-header", label: "Event Name" },
    { id: "event-start-date-header", label: "Start Date" },
    { id: "event-end-header", label: "End Date" },
    { id: "description-header", label: "Description" },
    { id: "event-status-header", label: "Status" },
    { id: "manage-header", label: "Manage" },
  ];

  // Controllers ------------------
  const getEventsData = async () => {
    setIsLoadingState(true);
    const result = await getAllEventsById();
    setEventsState(result);
    setIsLoadingState(false);
  };

  const getEventsByFilterFunc = async (type, term) => {
    setIsLoadingState(true);
    const result = await getAllEventsByFilter(type, term);
    setEventsState(result);
    setIsLoadingState(false);
  };

  const handleClick = () => {
    setCreateEventModalState(true);
  };

  const handleCreateEventCancelBtn = () => {
    setCreateEventModalState(false);
    dispatch(clearSelectedTasksList());
  };

  const handleViewBtn = (event) => {
    setViewEventModalState(true);
    setEventToViewState(event);
  };

  const handleViewEventCloseBtn = () => {
    setViewEventModalState(false);
    setEventToViewState({});
  };

  const handleEditBtn = (event) => {
    setEditEventModalState(true);
    setEventToEditState(event);
  };

  const handleEditEventCancelBtn = () => {
    setEditEventModalState(false);
    setEventToEditState({});
  };

  const handleEditEventSaveBtn = () => {
    setEditEventModalState(false);
    setEventToEditState({});
    getEventsData();
  };

  const handleBulkDownloadBtn = () => {
    bulkDownloadEventsData();
  };

  useEffect(() => {
    getEventsData();
  }, []);

  // View --------------------------
  return (
    <PageTemplate pageHeader="Events" layout="single-layout">
      {createEventModalState && (
        <CreateNewEventModal
          cancelBtnFunc={handleCreateEventCancelBtn}
          getEventsFunc={getEventsData}
        />
      )}
      {viewEventModalState && (
        <EventDetailsModal
          eventDetails={eventToViewState}
          handleCloseBtn={handleViewEventCloseBtn}
        />
      )}
      {editEventModalState && (
        <EditEventModal
          event={eventToEditState}
          handleCancelBtn={handleEditEventCancelBtn}
          handleSaveBtn={handleEditEventSaveBtn}
        />
      )}
      <Frame fullSize={true}>
        <div className="events-page-container">
          <div className="events-selector-container">
            <div className="events-selector-inner-container">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "events-selector-btn selected"
                    : "events-selector-btn"
                }
                to="/events"
              >
                My Events
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "events-selector-btn selected"
                    : "events-selector-btn"
                }
                to="/tasks"
              >
                My Tasks
              </NavLink>
            </div>
          </div>
          <div className="events-inner-container">
            <div className="events-content-container">
              <div className="events-top-section-container">
                <EventSearchBar
                  getAllEventsFunc={getEventsData}
                  filterEventsFunc={getEventsByFilterFunc}
                />
                <div className="event-page-top-btn-container">
                  <button className="btn" onClick={handleClick}>
                    Create New Event
                  </button>
                  {eventsState.length > 0 ? <button className="btn" onClick={handleBulkDownloadBtn}>
                    Bulk Download
                  </button> : <button className="disabled-btn" disabled>
                    Bulk Download
                  </button>}
                </div>
              </div>
              <div className="events-list-container">
                <div className="event-header-group">
                  <div className="event-header-group-inner">
                    {eventHeaders.map((header) => (
                      <h3
                        className="event-header"
                        id={header.id}
                        key={header.id}
                      >
                        {header.label}
                      </h3>
                    ))}
                  </div>
                </div>
                <hr className="event-header-divider" />
                <div className="events-list scrollable-frame">
                  <EventsList
                    eventsData={eventsState}
                    getEventsFunc={getEventsData}
                    isLoading={isLoadingState}
                    handleViewBtn={handleViewBtn}
                    handleEditBtn={handleEditBtn}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Frame>
    </PageTemplate>
  );
};
