import { generateUniqueId } from "./generateUniqueId";
import { getTimeStamp } from "./getTimeStamp";
import { createNewEvent } from "../api/event-calls/createNewEvent";
import { createNewTask } from "../api/task-calls/createNewTask";
import { createTaskUserLink } from "../api/task-calls/createTaskUserLink";
import { createEventUserLink } from "../api/event-calls/createEventUserLink";
import { createNewNotification } from "../api/notification-calls/createNewNotification";
import { logTasks } from "../api/log-calls/logTasks";
import { logEvents } from "../api/log-calls/logEvents";
import { createTaskProductLink } from "../api/task-calls/createTaskProductLink";
import { formatDateTimeForDatabase } from "./formatDateTimeForDatabase";

export const formatAndCreateEvent = async (data) => {
  const { eventData, taskData, userName } = data;
  // Set id property
  const eventId = await generateUniqueId(21, "events");
  eventData.id = eventId;
  // Create timestamp
  const timestamp = getTimeStamp();
  eventData.createdAt = timestamp;
  // Replace empty string with null
  if (eventData.description.trim().length === 0) {
    eventData.description = null;
  }
  const eventResult = await createNewEvent(eventData);
  await logEvents({
    eventId,
    action: "New event created.",
    response: eventResult.success ? "Success" : "Fail",
    dateTime: getTimeStamp(),
  });
  let success = true;
  const assignedUsers = new Set();
  if (eventResult.success) {
    await createEventUserLink({ userId: "", eventId, role: "owner" });
    // Format Tasks --------------------
    for (let task of taskData) {
      try {
        // Set id property
        const taskId = await generateUniqueId(21, "tasks");
        task.id = taskId;
        task.eventId = eventId;
        // Set createdAt
        task.createdAt = timestamp;
        // Remove properties that are not needed
        delete task.isSelected;
        delete task.dateConflict;
        // Format Dates
        task.startDate = formatDateTimeForDatabase(
          task.startDate,
          task.startTime
        );
        task.endDate = formatDateTimeForDatabase(task.endDate, task.endTime);
        // Replace empty string with null
        for (let i in task) {
          if (task[i] === "") {
            task[i] = null;
          }
        }
        const res = await createNewTask(task);
        if (res.success) {
          await logTasks({
            taskId,
            action: "New task created.",
            response: "Success",
            dateTime: timestamp,
          });
          await createTaskUserLink({ userId: "", taskId, role: "owner" });
          for (let user of task.assignees) {
            if (!assignedUsers.has(user.id)) {
              assignedUsers.add(user.id);
              await createEventUserLink({
                userId: user.id,
                eventId,
                role: "assignee",
              });
              const notifId = await generateUniqueId(24, "notifications");
              await createNewNotification({
                id: notifId,
                recipientId: user.id,
                message: `You have been assigned to event ${eventData.name} by ${userName}.`,
                createdAt: timestamp,
              });
            }
            await createTaskUserLink({
              userId: user.id,
              taskId,
              role: "assignee",
            });
            const notifId = await generateUniqueId(24, "notifications");
            await createNewNotification({
              id: notifId,
              recipientId: user.id,
              message: `You have been assigned to task ${task.name} by ${userName}.`,
              createdAt: timestamp,
            });
          }
          await createTaskProductLink({ taskId, products: task.products });
        } else {
          await logTasks({
            taskId,
            action: "New task created.",
            response: "Fail",
            dateTime: timestamp,
          });
        }
      } catch (error) {
        // Handle the error for the specific task
        success = false;
        console.error(`Error creating task: ${error.message}`);
      }
    }
  }
  return success;
};
