import React from "react";
import "./successNotif.css";

export const SuccessNotif = (props) => {
  return (
    <div className="success-notif">
      <span className="success-notif-msg">{props.successMsg}</span>
    </div>
  );
};
