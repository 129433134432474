import React from "react";
import "./productSelectorRow.css";

export const ProductSelectorRow = (props) => {
  // Controllers
  const handleCheck = (e) => {
    if (e.target.checked) {
      props.addToSelectedProducts(props.product);
    } else {
      props.removeFromSelectedProducts(props.product);
    }
  };

  const productName =
    props.productName.length > 44
      ? props.productName.slice(0, 44) + "..."
      : props.productName;

  // View
  return (
    <div className="product-selector-row-container">
      <input
        type="checkbox"
        onChange={handleCheck}
        checked={props.selectedProducts.some(
          (item) => item.productId === props.product.productId
        )}
      />
      <div className="product-selector-row-details">
        <div className="product-selector-row-details-section">
          <span>{props.product.primaryId}</span>
        </div>
        <div className="product-selector-row-details-section">
          <span>{productName}</span>
        </div>
      </div>
    </div>
  );
};
