import { API_ENDPOINT } from "..";

export const getUsersByName = async (name) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}users/get-users-by-name/${name}`,
      {
        credentials: "include",
      }
    );
    const result = await response.json();
    return result;
  } catch (err) {
    console.error(err);
  }
};
