export const convertToDateMinMax = (inputDate, sameDay) => {
  const num = sameDay ? 1 : 2;
  // Split the date string into year, month, and day components
  const dateComponents = inputDate.split("-");
  // Create a new Date object using the components
  const formattedDate = new Date(
    parseInt(dateComponents[0]),
    parseInt(dateComponents[1]) - 1,
    parseInt(dateComponents[2]) + num
  );
  let minDate = null;
  if (!isNaN(formattedDate)) {
    // Convert the formatted date to an ISO date string
    minDate = formattedDate.toISOString().split("T")[0];
  }
  return minDate;
};
