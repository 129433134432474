import React, { useState, useEffect, useRef } from "react";
import "./productOptionBtn.css";

export const ProductOptionBtn = (props) => {
  // Model
  const [showOptionsState, setShowOptionsState] = useState(false);
  const dropdownRef = useRef();
  // Controllers
  const handleClick = () => {
    setShowOptionsState(true);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        showOptionsState &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setShowOptionsState(false);
      }
    };

    if (showOptionsState) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showOptionsState]);
  // View
  return (
    <div className="product-options-btn-container" ref={dropdownRef}>
      <button className="product-options-btn" onClick={handleClick}>
        <svg
          className="product-options-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 3"
        >
          <g>
            <circle cx="1.5" cy="1.5" r="1.5" />
            <circle cx="5" cy="1.5" r="1.5" />
            <circle cx="8.5" cy="1.5" r="1.5" />
          </g>
        </svg>
      </button>
      {showOptionsState && (
        <div className="product-options-list-container">
          <ul>
            <li
              className="product-edit-option"
              onClick={() => {
                setShowOptionsState(false);
                props.handleEdit();
              }}
            >
              <span>Edit</span>
            </li>
            <li
              className="product-delete-option"
              onClick={() => {
                setShowOptionsState(false);
                props.handleDelete();
              }}
            >
              <span>Delete</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
