import React from "react";
import "./noContentMessage.css"

export const NoContentMessage = (props) => {
  return (
    <div className="no-content-msg-container">
      <h4 className="no-content-msg">{props.message}</h4>
    </div>
  );
};
