import { API_ENDPOINT } from "..";

export const logOutUser = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}logout`, {
      credentials: "include",
    });
    const res = await response.json();
    return res.success;
  } catch (error) {
    return error;
  }
};
