import { API_ENDPOINT } from "..";
import { formatDate } from "../../functions/formatDate";

export const getAllTasksByEventId = async (eventId) => {
  try {
    const response = await fetch(`${API_ENDPOINT}tasks/${eventId}`, {
      credentials: "include",
    });
    const result = await response.json();
    if (result.success) {
      let tasks = result.data;
      for (let task of tasks) {
        const formattedStartDateTime = formatDate(task.start_date);
        const formattedEndDateTime = formatDate(task.end_date);
        task.start_date = formattedStartDateTime.formattedDate;
        task.start_time = formattedStartDateTime.formattedTime;
        task.end_date = formattedEndDateTime.formattedDate;
        task.end_time = formattedEndDateTime.formattedTime;
      }
      return tasks;
    }
  } catch (err) {
    console.error(err);
  }
};
