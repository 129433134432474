import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  searchType: "task_id",
  searchTerm: "",
  selectedTasks: [],
  selectedTasksList: [],
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    deleteTask: (state, action) => {
      const taskId = action.payload;
      state.tasksList = state.tasksList.filter(
        (task) => task.task_id !== taskId
      );
    },

    setSearchTerm: (state, action) => {
      if (state.searchType === "status") {
        state.searchTerm = action.payload;
      } else {
        state.searchTerm = action.payload.toLowerCase();
      }
    },

    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },

    setSelectedTasks: (state, action) => {
      state.selectedTasks.push(action.payload);
    },

    removeSelectedTasks: (state, action) => {
      state.selectedTasks = state.selectedTasks.filter(
        (task) => task !== action.payload
      );
    },

    clearSelectedTasks: (state, action) => {
      state.selectedTasks = [];
    },

    setSelectedTasksList: (state, action) => {
      state.selectedTasksList = action.payload;
    },

    clearSelectedTasksList: (state, action) => {
      state.selectedTasksList = [];
    },
  },
});

export const {
  deleteTask,
  setSearchTerm,
  setSearchType,
  setFilteredTasksList,
  setSelectedTasks,
  removeSelectedTasks,
  clearSelectedTasks,
  setSelectedTasksList,
  clearSelectedTasksList,
} = tasksSlice.actions;

export default tasksSlice.reducer;
