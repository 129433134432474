import React, { useState, useEffect } from "react";
import "./productsList.css";
import { ProductRow } from "./product-row/ProductRow";
import { NoContentMessage } from "../../../components/no-content-messages/no-content-message/NoContentMessage";
import { IsLoadingMessage } from "../../../components/no-content-messages/is-loading-message/IsLoadingMessage";
import { ConfirmationModal } from "../../../components/modals/confirmation-modal/ConfirmationModal";
import { deleteProductById } from "../../../api/product-calls/deleteProductById";
import { checkProductTaskLinks } from "../../../api/product-calls/checkProductTaskLinks";

export const ProductsList = (props) => {
  // Model
  const { productsData } = props;
  const [productsState, setProductsState] = useState([]);
  const [productToDeleteState, setProductToDeleteState] = useState({});
  const [confirmDeleteModalState, setConfirmDeleteModalState] = useState(false);
  const [isLinkedState, setIsLinkedState] = useState(false)

  // Controllers
  const handleDeleteBtn = async (product) => {
    setProductToDeleteState(product);
    const response = await checkProductTaskLinks(product.id)
    if(response){
      setIsLinkedState(true)
    } else {
      setIsLinkedState(false)
    }
    setConfirmDeleteModalState(true);
  };

  const handleModalCancelBtn = () => {
    setProductToDeleteState({});
    setConfirmDeleteModalState(false);
  };

  const handleModalConfirmBtn = async () => {
    const response = await deleteProductById(productToDeleteState.id);
    if (response.success) {
      setConfirmDeleteModalState(false);
      setProductToDeleteState({});
      props.getProductsFunc();
    } else {
      console.log("Server Error!!!!");
    }
  };

  useEffect(() => {
    if (productsData.length > 0) {
      setProductsState(productsData);
    } else {
      setProductsState([]);
    }
  }, [productsData]);

  // View
  return (
    <div className="products-list-inner">
      {confirmDeleteModalState && (
        <ConfirmationModal
          nameProp={productToDeleteState.long_name}
          typeProp={isLinkedState ? "product-linked" : "product"}
          handleCancelBtn={handleModalCancelBtn}
          handleConfirmBtn={handleModalConfirmBtn}
        />
      )}
      {!props.isLoading ? (
        productsState.length > 0 ? (
          productsState.map((product) => {
            return (
              <ProductRow
                key={product.id}
                id={product.id}
                product={product}
                handleEditBtn={props.handleEditProductBtn}
                handleDeleteBtn={() => handleDeleteBtn(product)}
              />
            );
          })
        ) : (
          <NoContentMessage message="No Products" />
        )
      ) : (
        <IsLoadingMessage isLoading={props.isLoading} />
      )}
    </div>
  );
};
