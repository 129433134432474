import React, { useState } from "react";
import "./signInForm.css";
import { Frame } from "../../../components/frame/Frame";
import { TextInput } from "../../../components/input-fields/text-input/TextInput";
import { PasswordInput } from "../../../components/input-fields/password-input/PasswordInput";
import { checkUserCredentials } from "../../../api/user-calls/checkUserCredentials";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { getTimeStamp } from "../../../functions/getTimeStamp";
import { updateLastSignIn } from "../../../api/user-calls/updateLastSignIn";

export const SignInForm = () => {
  const navigate = useNavigate();
  //Model-----------------------------------------
  const [emailState, setEmailState] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [errorCredentialsState, setErrorCredentialsState] = useState(false);
  const [errorMsgState, setErrorMsgState] = useState("");
  const [isLoadingState, setIsLoadingState] = useState(false);

  //Controller-----------------------------------------------
  const updateSignInTime = async () => {
    const dateTime = getTimeStamp();
    const res = await updateLastSignIn({signInTime: dateTime});
    if (!res.success) {
      console.log("error");
    }
    return;
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsLoadingState(true);
    const res = await checkUserCredentials({
      email: emailState.toLowerCase(),
      password: passwordState,
    });
    if (res.success) {
      updateSignInTime();
      navigate("/dashboard");
    } else {
      setErrorCredentialsState(true);
      setErrorMsgState(res.message);
      setIsLoadingState(false);
    }
  };

  //View-------------------------------------------------------
  return (
    <Frame>
      <form className="sign-in-form" onSubmit={handleSubmit}>
        <div className="sign-in-input-fields">
          <TextInput
            labelName="Email"
            fieldName="sign-in-email"
            autoComplete="email"
            valueProp={emailState}
            onChangeFunc={(e) => {
              setEmailState(e.target.value);
              if (errorCredentialsState) {
                setErrorCredentialsState(false);
              }
            }}
            isRequired={true}
          />
          <PasswordInput
            labelName="Password"
            fieldName="sign-in-password"
            isRequired={true}
            valueProp={passwordState}
            onChangeFunc={(e) => {
              setPasswordState(e.target.value);
              if (errorCredentialsState) {
                setErrorCredentialsState(false);
              }
            }}
            signIn={true}
            autoComplete="current-password"
            handleSubmit={handleSubmit}
          />
        </div>
        {errorCredentialsState && (
          <span className="sign-in-credentials-match-error error-msg">
            {errorMsgState}
          </span>
        )}
        <div className="sign-in-btn-container">
          {isLoadingState ? (
            <ClipLoader
              size={20}
              loading={isLoadingState}
              color="var(--activeGrey)"
            />
          ) : (
            <input
              className="btn"
              id="sign-in-btn"
              type="submit"
              value="Sign In"
            />
          )}
        </div>
      </form>
    </Frame>
  );
};
