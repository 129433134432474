export const formatDate = (date) => {
  const newDate = new Date(date);
  const formattedDate = newDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  let formattedTime = newDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  formattedTime =
    formattedTime.slice(0, 6) + formattedTime.slice(6, 8).toUpperCase();

  return { formattedDate, formattedTime };
};
