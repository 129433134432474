import React from "react";
import "./largeTextInput.css";

export const LargeTextInput = (props) => {
  return (
    <div className="large-text-input-container">
      <div>
        <label className="input-label" htmlFor={props.fieldName + "-input"}>
          {props.labelName}
        </label>
        {props.valueProp.length === 150 && (
          <span className="max-character-limit">Character Limit 150</span>
        )}
      </div>
      <textarea
        id={props.fieldName + "-input"}
        name={props.fieldName + "-input"}
        className="large-text-input"
        placeholder={props.labelName}
        value={props.valueProp}
        onChange={props.onChangeFunc}
        maxLength={150}
      />
    </div>
  );
};
