import React from "react";
import "./createNewEventFormEventDetails.css";
import { TextInput } from "../../../../../components/input-fields/text-input/TextInput";
import { DateInput } from "../../../../../components/input-fields/date-input/DateInput";
import { TimeInput } from "../../../../../components/input-fields/time-input/TimeInput";
import { LargeTextInput } from "../../../../../components/input-fields/large-text-input/LargeTextInput";

export const CreateNewEventFormEventDetails = (props) => {
  // View
  return (
    <div className="create-event-form">
      <div className="create-event-form-event-details-inputs">
        <div className="create-event-form-event-details-inputs-section">
          <div className="create-event-name-container">
            <TextInput
              labelName="Event Name"
              fieldName="event-name"
              valueProp={props.eventState.name}
              onChangeFunc={props.eventNameFunc}
              maxLengthProp={60}
              isRequired={true}
            />
          </div>
          <div className="create-event-dates-container">
            <div className="create-event-date-time">
              <DateInput
                labelName="Start Date"
                fieldName="start-date"
                valueProp={props.eventState.startDate}
                onChangeFunc={props.eventStartDateFunc}
                isRequired={true}
              />
              <TimeInput
                labelName="*Start Time"
                time={props.eventState.startTime}
                handleTimeSelect={props.eventStartTimeFunc}
                isChecked={props.eventStartTimeCheck}
                handleCheck={props.eventStartTimeCheckFunc}
              />
            </div>
            <div className="create-event-date-time">
              <DateInput
                labelName="End Date"
                fieldName="end-date"
                valueProp={props.eventState.endDate}
                onChangeFunc={props.eventEndDateFunc}
                isRequired={true}
                minDate={props.minDate}
              />
              <TimeInput
                labelName="*End Time"
                time={props.eventState.endTime}
                handleTimeSelect={props.eventEndTimeFunc}
                isChecked={props.eventEndTimeCheck}
                handleCheck={props.eventEndTimeCheckFunc}
              />
            </div>
          </div>
        </div>

        <div className="create-event-form-event-details-inputs-section">
          <div className="create-event-description-container">
            <LargeTextInput
              labelName="Event Description"
              fieldName="event-description"
              valueProp={props.eventState.description}
              onChangeFunc={props.eventDescriptionFunc}
            />
          </div>
        </div>
      </div>
      <div className="create-event-footer">
        <div className="create-event-footer-section"></div>
        <div className="create-event-footer-section">
          {props.continueBtnState ? (
            <button
              className="confirm-new-event-btn btn"
              onClick={props.continueBtnFunc}
            >
              Next
            </button>
          ) : (
            <button className="confirm-new-event-btn disabled-btn" disabled>
              Next
            </button>
          )}
        </div>
        <div className="create-event-footer-section">
          <button
            className="cancel-new-event-btn btn"
            onClick={props.cancelBtnFunc}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
