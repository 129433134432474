import React, { useState, useEffect } from "react";
import "./upcomingEventRow.css";
import { trimStringToMaxLength } from "../../../../functions/trimStringToMaxLength";

export const UpcomingEventRow = (props) => {
  // Model
  const [maxNameLengthState, setMaxNameLengthState] = useState("");
  const eventName = trimStringToMaxLength(props.eventName, maxNameLengthState);
  // Controllers
  useEffect(() => {
    if (window.innerWidth < 1300) {
      setMaxNameLengthState(18);
    } else if (window.innerWidth < 1400) {
      setMaxNameLengthState(25);
    } else if (window.innerWidth < 1624) {
      setMaxNameLengthState(30);
    } else if (window.innerWidth < 1700) {
      setMaxNameLengthState(34);
    } else if (window.innerWidth < 1870) {
      setMaxNameLengthState(38);
    } else {
      setMaxNameLengthState(45);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1300) {
        setMaxNameLengthState(18);
      } else if (window.innerWidth < 1400) {
        setMaxNameLengthState(25);
      } else if (window.innerWidth < 1624) {
        setMaxNameLengthState(30);
      } else if (window.innerWidth < 1700) {
        setMaxNameLengthState(34);
      } else if (window.innerWidth < 1870) {
        setMaxNameLengthState(38);
      } else {
        setMaxNameLengthState(45);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // View
  return (
    <div className="upcoming-event-row-background">
      <div className="upcoming-event-row-container">
        <div className="upcoming-event-row-container-segment">
          <span>{eventName}</span>
        </div>
        <div className="upcoming-event-row-container-segment">
          {props.startDate + " - " + props.endDate}
        </div>
        <div className="upcoming-event-row-container-segment">
          <button className="view-btn" onClick={props.handleClick}>
            <svg
              className="view-btn-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 6"
            >
              <g>
                <path d="m5,0C2.86,0,1.01,1.21,0,3c1.01,1.79,2.86,3,5,3s3.99-1.21,5-3c-1.01-1.79-2.86-3-5-3Zm0,5.5h0c-1.38,0-2.5-1.12-2.5-2.5S3.62.5,5,.5h0c1.38,0,2.5,1.12,2.5,2.5s-1.12,2.5-2.5,2.5Z" />
                <circle cx="5" cy="3" r="1" />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
