// Gets current data and time and formats to GMT timestamp
export const getTimeStamp = () => {
  const now = new Date();
  const gmtTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
  const formattedTime = gmtTime
    .toISOString()
    .replace("T", " ")
    .replace(/\.\d+Z$/, "");
  return formattedTime;
};
