import React, { useEffect, useState } from "react";
import "./taskSelector.css";
import { getAllTasksByEventId } from "../../../api/task-calls/getAllTasksByEventId";
import { TaskTag } from "./task-tag/TaskTag";
import { IsLoadingMessage } from "../../no-content-messages/is-loading-message/IsLoadingMessage";
import { deleteTaskByTaskId } from "../../../api/task-calls/deleteTaskByTaskId";

export const TaskSelector = (props) => {
  // Model
  const { eventDetails } = props;
  const [tasksState, setTasksState] = useState({});
  const [isLoadingState, setIsLoadingState] = useState(false);

  // Controller
  const getTasksData = async () => {
    setIsLoadingState(true);
    const res = await getAllTasksByEventId(eventDetails.id);
    setTasksState(res);
    setIsLoadingState(false);
  };

  const handleDeleteTask = async (taskId) => {
    await deleteTaskByTaskId(taskId);
    getTasksData();
  };

  useEffect(() => {
    getTasksData();
  }, []);

  // View
  return (
    <div className="event-selector-container">
      <div className="event-tasks-selector-btn-container">
        <button
          className="create-event-task-btn btn"
          onClick={props.onClickFunc}
        >
          Create New Task
        </button>
      </div>
      {/* Container to display selected Tasks ---------------------------------- */}
      <div className="selected-tasks">
        <div className="selected-tasks-list-container">
          <div className="selected-tasks-list-container-inner">
            {!isLoadingState ? (
              tasksState.length > 0 ? (
                <div className="selected-tasks-list">
                  {tasksState.map((task) => {
                    return (
                      <TaskTag
                        key={task.id}
                        taskName={task.name}
                        onClickFunc={() => handleDeleteTask(task.id)}
                      />
                    );
                  })}
                </div>
              ) : (
                <span className="no-tasks-msg">No Tasks</span>
              )
            ) : (
              <IsLoadingMessage isLoading={isLoadingState} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
