import React, { useState } from "react";
import "./productSearchBar.css";

export const ProductSearchBar = (props) => {
  // Model
  const [searchState, setSearchState] = useState("");
  const [typeState, setTypeState] = useState("");
  const [showInputState, setShowInputState] = useState("");
  const [btnState, setBtnState] = useState(false);

  // Controller
  const handleTypeChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setTypeState(value);
    setSearchState("");
    if (value === "status") {
      setShowInputState("dropdown");
    } else {
      setShowInputState("text");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeState) {
      props.filterProductsFunc(typeState, searchState);
      setBtnState(true);
    }
    if (!searchState) {
      setBtnState(false);
    }
  };

  const handleClick = () => {
    setBtnState(false);
    setTypeState("");
    setSearchState("");
    document.querySelector("#product-select").value = "DEFAULT";
    props.getAllProductsFunc();
  };

  const handleStatusChange = (e) => {
    props.filterProductsFunc(typeState, e.target.value);
    setBtnState(true);
  };

  // View
  return (
    <div className="products-search-bar-container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="product-select" className="search-by-label">
          Search by:
        </label>
        <select
          id="product-select"
          name="product-select"
          defaultValue={"DEFAULT"}
          onChange={handleTypeChange}
        >
          <option value="DEFAULT" disabled>
            Select type...
          </option>
          <option value="long_name">Product Name</option>
          <option value="primary_identifier">Primary ID</option>
          <option value="fund_manager">Fund Manager</option>
          <option value="status">Status</option>
        </select>
        {showInputState === "text" && (
          <input
            className="text-input"
            type="text"
            value={searchState}
            placeholder="Type here"
            onChange={(e) => setSearchState(e.target.value)}
          />
        )}
        {showInputState === "dropdown" && (
          <select
            id="status-select"
            name="status-select"
            defaultValue={"DEFAULT"}
            onChange={handleStatusChange}
          >
            <option value="DEFAULT" disabled>
              Select Status...
            </option>
            <option value="open">Open</option>
            <option value="closed">Closed</option>
          </select>
        )}
      </form>
      {btnState && (
        <button className="show-all-btn btn" onClick={handleClick}>
          Show All
        </button>
      )}
    </div>
  );
};
