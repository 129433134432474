import React, { useState, useEffect, useRef } from "react";
import "./addProductBtn.css";

export const AddProductBtn = (props) => {
  // Model
  const [showOptionsState, setShowOptionsState] = useState(false);
  const dropdownRef = useRef();
  // Controllers
  const handleClick = () => {
    setShowOptionsState(true);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        showOptionsState &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setShowOptionsState(false);
      }
    };

    if (showOptionsState) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showOptionsState]);
  
  // View
  return (
    <div className="add-product-btn-container" ref={dropdownRef}>
      <button className="btn" onClick={handleClick}>
        Add Product
      </button>
      {showOptionsState && (
        <div className="add-products-btn-options-container">
          <ul>
            <li
              className="add-product-option"
              onClick={() => {
                setShowOptionsState(false);
                props.handleAddProduct();
              }}
            >
              <span>Add New Product</span>
            </li>
            <li
              className="add-product-option"
              onClick={() => {
                setShowOptionsState(false);
                props.handleBulkUpload();
              }}
            >
              <span>Bulk Upload</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
