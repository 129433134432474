import React, { useEffect, useState } from "react";
import "./settingsPage.css";
import { PageTemplate } from "../../components/page-template/PageTemplate";
import { Frame } from "../../components/frame/Frame";
import { getUserDetails } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { EditUserDetailsModal } from "./edit-user-details-modal/EditUserDetailsModal";
import { ChangePasswordModal } from "./change-password-modal/ChangePasswordModal";

export const SettingsPage = () => {
  // Model
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userDetails);
  const isLoading = useSelector((state) => state.user.isLoading);
  const [editUserDetailsState, setEditUserDetailsState] = useState(false);
  const [changePasswordState, setChangePasswordState] = useState(false);

  // Controllers
  const handleEditUserDetailsClick = () => {
    setEditUserDetailsState(true);
  };

  const handleEditUserDetailsCancelBtn = () => {
    setEditUserDetailsState(false);
  };

  const handleChangePasswordClick = () => {
    setChangePasswordState(true);
  };

  const handleChangePasswordCancelClick = () => {
    setChangePasswordState(false);
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  // View
  return (
    <PageTemplate pageHeader="Settings" layout="single-layout">
      {editUserDetailsState && (
        <EditUserDetailsModal
          userDetails={user}
          cancelBtnFunc={handleEditUserDetailsCancelBtn}
        />
      )}
      {changePasswordState && <ChangePasswordModal cancelBtnFunc={handleChangePasswordCancelClick} />}
      <Frame header="User Details" line={true} fullSize={true}>
        <div className="user-details-section">
          <div className="user-first-last-name-fields">
            <div className="user-detail-container">
              <label className="input-label">First Name</label>
              <div className={`user-detail${isLoading ? " skeleton" : ""}`}>
                {!isLoading && user.firstName}
              </div>
            </div>
            <div className="user-detail-container">
              <label className="input-label">Last Name</label>
              <div className={`user-detail${isLoading ? " skeleton" : ""}`}>
                {!isLoading && user.lastName}
              </div>
            </div>
          </div>
          <div className="user-detail-container">
            <label className="input-label">Email</label>
            <div
              id="user-email-container"
              className={`user-detail${isLoading ? " skeleton" : ""}`}
            >
              {!isLoading && user.email}
            </div>
          </div>
          <div className="user-detail-container">
            <label className="input-label">Mobile No.</label>
            <div
              id="user-mobile-container"
              className={`user-detail${isLoading ? " skeleton" : ""}`}
            >
              {!isLoading && user.mobile}
            </div>
          </div>
          <div className="user-detail-container">
            <label className="input-label">Company Name</label>
            <div
              id="user-mobile-container"
              className={`user-detail${isLoading ? " skeleton" : ""}`}
            >
              {!isLoading && user.companyName}
            </div>
          </div>
          <div className="user-detail-container">
            <label className="input-label">Company ABN</label>
            <div
              id="user-mobile-container"
              className={`user-detail${isLoading ? " skeleton" : ""}`}
            >
              {!isLoading && user.companyAbn}
            </div>
          </div>
          <div className="user-details-btn-container">
            <button
              className="edit-user-details-btn btn"
              onClick={handleEditUserDetailsClick}
            >
              Edit
            </button>
            <button
              className="change-password-btn"
              onClick={handleChangePasswordClick}
            >
              Change Password
            </button>
          </div>
        </div>
      </Frame>
    </PageTemplate>
  );
};
