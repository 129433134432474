import React, { useState, useEffect } from "react";
import "./monthCalendarTile.css";

export const MonthCalendarTile = (props) => {
  // Model
  const { dayNum, events, tasks } = props;
  const [isSmall, setIsSmall] = useState(false);
  // Controllers
  useEffect(() => {
    const container = document.getElementById("month-calendar-tile-container");
    if (
      (container && container.offsetWidth < 165) ||
      (container && container.offsetHeight < 75)
    ) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const container = document.getElementById(
        "month-calendar-tile-container"
      );
      if (
        (container && container.offsetWidth < 165) ||
        (container && container.offsetHeight < 75)
      ) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // View
  return (
    <div className="month-calendar-tile-background">
      <div
        id="month-calendar-tile-container"
        className={props.classProp}
        onClick={() =>
          props.handleTileSelect(
            events.eventsList.concat(tasks.tasksList)
          )
        }
      >
        <div className="month-calendar-tile-number-container">
          <span className="month-calendar-tile-number">{dayNum}</span>
        </div>
        <div
          className={`month-calendar-tile-info-${
            isSmall ? "horizontal" : "vertical"
          }`}
        >
          {events.numOfEvents > 0 && (
            <div
              className={`month-calendar-tile-event-num ${
                isSmall ? "small" : ""
              }`}
            >
              {isSmall
                ? "E"
                : `${events.numOfEvents} ${
                    events.numOfEvents > 1 ? "Events Begin" : "Event Begins"
                  }`}
            </div>
          )}
          {events.numOfEvents > 0 && tasks.numOfTasks > 0 && isSmall && (
            <div className="month-calendar-tile-divider"></div>
          )}
          {tasks.numOfTasks > 0 && (
            <div
              className={`month-calendar-tile-task-num ${
                isSmall ? "small" : ""
              }`}
            >
              {isSmall
                ? "T"
                : `${tasks.numOfTasks} ${
                    tasks.numOfTasks > 1 ? "Tasks Begin" : "Task Begins"
                  }`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
