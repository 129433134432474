import React, { useState, useEffect } from "react";
import "./createNewEventModal.css";
import { useDispatch, useSelector } from "react-redux";
import { getProductsData } from "../../../features/productsSlice";
import { convertToDateMinMax } from "../../../functions/convertToDateMinMax";
import { CreateNewEventFormEventDetails } from "./create-new-event-form/create-new-event-form-event-details/CreateNewEventFormEventDetails";
import { CreateNewEventFormAddTasks } from "./create-new-event-form/create-new-event-form-add-tasks/CreateNewEventFormAddTasks";
import validator from "validator";
import { formatAndCreateEvent } from "../../../functions/formatAndCreateEvent";
import { formatDateTimeForDatabase } from "../../../functions/formatDateTimeForDatabase";

export const CreateNewEventModal = (props) => {
  // Model
  const dispatch = useDispatch();
  const eventInitialState = {
    name: "",
    startDate: "",
    startTime: "",
    startTimeAllDay: false,
    endDate: "",
    endTime: "",
    endTimeAllDay: false,
    description: "",
  };

  const taskInitialState = {
    name: "",
    startDate: "",
    startTime: "",
    startTimeAllDay: false,
    endDate: "",
    endTime: "",
    endTimeAllDay: false,
    description: "",
  };

  // ------------------------Event State -------------------------
  const [eventState, setEventState] = useState(eventInitialState);
  // ----------------------Task State---------------------------
  const [taskState, setTaskState] = useState(taskInitialState);
  const [taskListState, setTaskListState] = useState([]);
  const [taskStartDateConflictState, setTaskStartDateConflictState] =
    useState(false);
  const [taskEndDateConflictState, setTaskEndDateConflictState] =
    useState(false);
  // -----------------------Product State--------------------------
  const [selectedProductsState, setSelectedProductsState] = useState([]);
  const [productListState, setProductListState] = useState([]);
  // -----------------------Assignee State--------------------------
  const [assigneeListState, setAssigneeListState] = useState([]);
  // -----------------------Page State----------------------
  const [activePage, setActivePage] = useState("eventDetails");
  const [animationState, setAnimationState] = useState("");
  // -------------------------Buttons State------------------------
  const [continueBtnState, setContinueBtnState] = useState(false);
  const [confirmBtnState, setConfirmBtnState] = useState(false);
  const [addTaskBtnState, setAddTaskBtnState] = useState(true);
  const [editingTaskState, setEditingTaskState] = useState(false);
  const [saveTaskBtnState, setSaveTaskBtnState] = useState(true);
  const [isLoadingState, setIsLoadingState] = useState(false);
  // -----------------------Date Min Max Data--------------------------
  const minEventEndDate = convertToDateMinMax(eventState.startDate);
  const minTaskStartDate = convertToDateMinMax(eventState.startDate, true);
  const minTaskEndDate = convertToDateMinMax(taskState.startDate);
  const maxTaskDate = convertToDateMinMax(eventState.endDate, true);

  const firstName = useSelector((state) => state.user.userDetails.firstName);
  const lastName = useSelector((state) => state.user.userDetails.lastName);
  const fullName = firstName + " " + lastName;

  // Controllers
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingState(true);
    const startDateTime = formatDateTimeForDatabase(
      eventState.startDate,
      eventState.startTime
    );
    const endDateTime = formatDateTimeForDatabase(
      eventState.endDate,
      eventState.endTime
    );
    const data = {
      eventData: {
        id: "",
        name: eventState.name,
        startDate: startDateTime,
        endDate: endDateTime,
        description: eventState.description,
        ownerId: "",
        createdAt: "",
      },
      taskData: taskListState,
      userName: fullName,
    };
    const response = await formatAndCreateEvent(data);
    if (response) {
      props.cancelBtnFunc();
      props.getEventsFunc();
    } else {
      setIsLoadingState(false);
    }
  };

  const handleEventStartDateChange = (e) => {
    const selectedStartDate = new Date(e.target.value);
    setEventState((prevState) => ({
      ...prevState,
      startDate: e.target.value,
      endDate:
        selectedStartDate >= new Date(prevState.endDate)
          ? ""
          : prevState.endDate,
    }));
  };

  const handleTaskStartDateChange = (e) => {
    const selectedStartDate = new Date(e.target.value);
    setTaskState((prevState) => ({
      ...prevState,
      startDate: e.target.value,
      endDate:
        selectedStartDate >= new Date(prevState.endDate)
          ? ""
          : prevState.endDate,
    }));
  };

  const handleContinueBtn = async () => {
    setAnimationState("slide-out-left");
    await new Promise((resolve) => setTimeout(resolve, 145));
    setActivePage("addTasks");
    setAnimationState("slide-in-left");
    await new Promise((resolve) => setTimeout(resolve, 150));
    setAnimationState("");
  };

  const handleBackBtn = async () => {
    const taskToUpdate = taskListState.find((task) => task.isSelected === true);
    if (taskToUpdate) {
      const updatedTask = {
        ...taskToUpdate,
        isSelected: false,
      };
      const updatedTaskList = taskListState.map((task) =>
        task.isSelected ? updatedTask : task
      );

      setTaskListState(updatedTaskList);
    }
    setAnimationState("slide-out-right");
    await new Promise((resolve) => setTimeout(resolve, 145));
    setTaskState(taskInitialState);
    setProductListState([]);
    setEditingTaskState(false);
    setActivePage("eventDetails");
    setAnimationState("slide-in-right");
    await new Promise((resolve) => setTimeout(resolve, 150));
    setAnimationState("");
  };

  const handleAddTaskBtn = () => {
    const newTask = {
      id: "",
      name: taskState.name,
      startDate: taskState.startDate,
      startTime: taskState.startTime,
      startTimeAllDay: taskState.startTimeAllDay,
      endDate: taskState.endDate,
      endTime: taskState.endTime,
      endTimeAllDay: taskState.endTimeAllDay,
      description: taskState.description,
      products: productListState,
      assignees: assigneeListState,
      createdAt: "",
      dateConflict: false,
      isSelected: false,
    };
    setTaskListState((prevState) => [...prevState, newTask]);
    setProductListState([]);
    setAssigneeListState([]);
    setTaskState(taskInitialState);
  };

  const handleRemoveTaskBtn = (taskName) => {
    setTaskListState((prevState) =>
      prevState.filter((task) => task.name !== taskName)
    );
  };

  const handleAddProductsBtn = () => {
    const newList = selectedProductsState.filter(
      (product) =>
        !productListState.some((item) => item.productId === product.productId)
    );
    setProductListState((prevState) => [...prevState, ...newList]);
    setSelectedProductsState([]);
  };

  const handleRemoveFromProductList = (product) => {
    setProductListState((prevState) =>
      prevState.filter((prod) => prod.productId !== product.productId)
    );
  };

  const compareTaskAndEventStartDates = (taskDate) => {
    const selectedStartDate = new Date(taskDate);
    return selectedStartDate >= new Date(eventState.startDate);
  };

  const compareTaskAndEventEndDates = (taskDate) => {
    const selectedStartDate = new Date(taskDate);
    return selectedStartDate <= new Date(eventState.endDate);
  };

  const handleAddAssigneeBtn = (assignee) => {
    if (!assigneeListState.some((item) => item.id === assignee.id)) {
      setAssigneeListState((prevState) => [...prevState, assignee]);
    }
  };

  const handleRemoveFromAssigneeList = (assigneeId) => {
    setAssigneeListState((prevState) =>
      prevState.filter((assignee) => assignee.id !== assigneeId)
    );
  };

  const handleEditTask = (taskName) => {
    const selectedTask = taskListState.find(
      (taskObj) => taskObj.name === taskName
    );

    if (selectedTask) {
      setTaskState({ ...selectedTask });
      setProductListState(selectedTask.products);
      setAssigneeListState(selectedTask.assignees);
      setTaskListState((prevState) =>
        prevState.map((task) => ({
          ...task,
          isSelected: task.name === taskName,
        }))
      );
    }
    setEditingTaskState(true);
  };

  const handleSaveEditTaskBtn = () => {
    setTaskListState((prevState) =>
      prevState.map((task) => {
        if (task.isSelected) {
          return {
            ...task,
            name: taskState.name,
            startDate: taskState.startDate,
            startTime: taskState.startTime,
            endDate: taskState.endDate,
            endTime: taskState.endTime,
            description: taskState.description,
            products: productListState,
            assignees: assigneeListState,
            isSelected: false,
          };
        }
        return task;
      })
    );
    setEditingTaskState(false);
    setTaskState(taskInitialState);
    setProductListState([]);
    setAssigneeListState([]);
  };

  const handleCancelEditTaskBtn = () => {
    taskListState.forEach((task) => {
      if (task.isSelected === true) {
        task.isSelected = false;
      }
    });
    setEditingTaskState(false);
    setTaskState(taskInitialState);
    setProductListState([]);
    setAssigneeListState([]);
  };

  // -----------------------------------------------------
  useEffect(() => {
    dispatch(getProductsData());
  }, []);

  // Sets continue button state
  useEffect(() => {
    const { name, startDate, startTime, endDate, endTime } = eventState;
    if (
      name.trim().length > 0 &&
      validator.isDate(startDate) &&
      !validator.isEmpty(startTime) &&
      validator.isDate(endDate) &&
      !validator.isEmpty(endTime)
    ) {
      setContinueBtnState(true);
    } else {
      setContinueBtnState(false);
    }
  }, [eventState]);

  // Sets confirm button state
  useEffect(() => {
    if (taskListState.length > 0 && !editingTaskState) {
      const hasConflict = taskListState.some((task) => task.dateConflict);
      setConfirmBtnState(!hasConflict);
    } else {
      setConfirmBtnState(false);
    }
  }, [taskListState, editingTaskState]);

  // Sets the state of ADD and SAVE buttons for tasks
  useEffect(() => {
    const { name, startDate, startTime, endDate, endTime } = taskState;
    if (
      name.trim().length > 0 &&
      validator.isDate(startDate) &&
      !validator.isEmpty(startTime) &&
      validator.isDate(endDate) &&
      !validator.isEmpty(endTime) &&
      productListState.length > 0
    ) {
      setAddTaskBtnState(true);
      setSaveTaskBtnState(true);
    } else {
      setAddTaskBtnState(false);
      setSaveTaskBtnState(false);
    }
  }, [taskState, productListState]);

  useEffect(() => {
    const { startDate, endDate } = taskState;
    if (validator.isDate(startDate)) {
      setTaskStartDateConflictState(!compareTaskAndEventStartDates(startDate));
    }
    if (validator.isDate(endDate)) {
      setTaskEndDateConflictState(!compareTaskAndEventEndDates(endDate));
      return;
    }
    setTaskStartDateConflictState(false);
    setTaskEndDateConflictState(false);
  }, [taskState.startDate, taskState.endDate]);

  // Sets task dateCoflict property
  useEffect(() => {
    const updatedTaskList = taskListState.map((task) => ({
      ...task,
      dateConflict:
        !compareTaskAndEventStartDates(task.startDate) ||
        !compareTaskAndEventEndDates(task.endDate),
    }));

    if (!areTaskListsEqual(taskListState, updatedTaskList)) {
      setTaskListState(updatedTaskList);
    }
  }, [eventState.startDate, eventState.endDate, taskListState]);

  // Helper function to compare two task lists
  const areTaskListsEqual = (list1, list2) => {
    if (list1.length !== list2.length) {
      return false;
    }
    for (let i = 0; i < list1.length; i++) {
      if (list1[i].dateConflict !== list2[i].dateConflict) {
        return false;
      }
    }
    return true;
  };

  // View
  return (
    <div className="modal-background">
      <div className="create-new-event-modal-container">
        <div className="create-new-event-modal-header-container">
          <h2 className="modal-header">
            Create Event - {activePage === "eventDetails" && "Event Details"}
            {activePage === "addTasks" && "Add Tasks"}
          </h2>
          <hr className="modal-header-line" />
        </div>

        {activePage === "eventDetails" && (
          <div className={`create-new-event-inner-container ${animationState}`}>
            <CreateNewEventFormEventDetails
              eventState={eventState}
              eventNameFunc={(e) =>
                setEventState((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }
              eventStartDateFunc={handleEventStartDateChange}
              eventStartTimeFunc={(time) =>
                setEventState((prevState) => ({
                  ...prevState,
                  startTime: time,
                }))
              }
              eventStartTimeCheck={eventState.startTimeAllDay}
              eventStartTimeCheckFunc={() => {
                setEventState((prevState) => ({
                  ...prevState,
                  startTimeAllDay: !eventState.startTimeAllDay,
                }));
              }}
              eventEndDateFunc={(e) =>
                setEventState((prevState) => ({
                  ...prevState,
                  endDate: e.target.value,
                }))
              }
              eventEndTimeFunc={(time) =>
                setEventState((prevState) => ({
                  ...prevState,
                  endTime: time,
                }))
              }
              eventEndTimeCheck={eventState.endTimeAllDay}
              eventEndTimeCheckFunc={() => {
                setEventState((prevState) => ({
                  ...prevState,
                  endTimeAllDay: !eventState.endTimeAllDay,
                }));
              }}
              eventDescriptionFunc={(e) =>
                setEventState((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
              eventStatusFunc={(e) =>
                setEventState((prevState) => ({
                  ...prevState,
                  status: e.target.value,
                }))
              }
              minDate={minEventEndDate}
              continueBtnState={continueBtnState}
              continueBtnFunc={handleContinueBtn}
              cancelBtnFunc={props.cancelBtnFunc}
            />
          </div>
        )}
        {activePage === "addTasks" && (
          <div className={`create-new-event-inner-container ${animationState}`}>
            <CreateNewEventFormAddTasks
              taskState={taskState}
              taskNameFunc={(e) =>
                setTaskState((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }
              taskStartDateFunc={handleTaskStartDateChange}
              taskStartTimeFunc={(time) =>
                setTaskState((prevState) => ({
                  ...prevState,
                  startTime: time,
                }))
              }
              taskStartTimeCheck={taskState.startTimeAllDay}
              taskStartTimeCheckFunc={() =>
                setTaskState((prevState) => ({
                  ...prevState,
                  startTimeAllDay: !taskState.startTimeAllDay,
                }))
              }
              taskEndDateFunc={(e) =>
                setTaskState((prevState) => ({
                  ...prevState,
                  endDate: e.target.value,
                }))
              }
              taskEndTimeFunc={(time) =>
                setTaskState((prevState) => ({
                  ...prevState,
                  endTime: time,
                }))
              }
              taskEndTimeCheck={taskState.endTimeAllDay}
              taskEndTimeCheckFunc={() =>
                setTaskState((prevState) => ({
                  ...prevState,
                  endTimeAllDay: !taskState.endTimeAllDay,
                }))
              }
              taskDescriptionFunc={(e) =>
                setTaskState((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
              taskStatusFunc={(e) =>
                setTaskState((prevState) => ({
                  ...prevState,
                  status: e.target.value,
                }))
              }
              selectedProducts={selectedProductsState}
              productList={productListState}
              addToSelectedProducts={(e) =>
                setSelectedProductsState((prevState) => [...prevState, e])
              }
              removeFromSelectedProducts={(e) =>
                setSelectedProductsState((prevState) =>
                  prevState.filter((product) => product !== e)
                )
              }
              addProductsBtnFunc={handleAddProductsBtn}
              productTagRemoveFunc={handleRemoveFromProductList}
              assigneeList={assigneeListState}
              handleAddAssigneeBtn={handleAddAssigneeBtn}
              handleRemoveAssigneeBtn={handleRemoveFromAssigneeList}
              minStartDate={minTaskStartDate}
              minEndDate={minTaskEndDate}
              maxDate={maxTaskDate}
              startDateConflict={taskStartDateConflictState}
              endDateConflict={taskEndDateConflictState}
              taskList={taskListState}
              addTaskBtnState={addTaskBtnState}
              addTaskBtnFunc={handleAddTaskBtn}
              taskTagClickFunc={handleEditTask}
              removeTaskBtn={handleRemoveTaskBtn}
              continueBtnState={continueBtnState}
              continueBtnFunc={handleContinueBtn}
              confirmBtnState={confirmBtnState}
              confirmBtnFunc={handleSubmit}
              cancelBtnFunc={props.cancelBtnFunc}
              backBtnFunc={handleBackBtn}
              editingTask={editingTaskState}
              saveBtnState={saveTaskBtnState}
              saveEditTask={handleSaveEditTaskBtn}
              cancelEditTask={handleCancelEditTaskBtn}
              isLoading={isLoadingState}
            />
          </div>
        )}
      </div>
    </div>
  );
};
