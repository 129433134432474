import React, { useEffect, useState } from "react";
import "./signUpPassword.css";
import { PasswordInput } from "../../../../components/input-fields/password-input/PasswordInput";
import validator from "validator";

export const SignUpPassword = (props) => {
  // Model
  const { password } = props;
  const [errorPasswordState, setErrorPasswordState] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  // Controllers
  const handleNextBtn = async () => {
    if (password.password !== password.confirmPassword) {
      setErrorPasswordState(true);
    } else {
      props.handleClick();
    }
  };

  useEffect(() => {
    if (
      !validator.isEmpty(password.password) &&
      !validator.isEmpty(password.confirmPassword)
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [password]);

  // View
  return (
    <div className="sign-up-form-frame">
      <div className="sign-up-user-details">
        <h3 className="sign-up-sub-header">Password</h3>
        <div className="sign-up-input-fields-details">
          <PasswordInput
            labelName="Password"
            fieldName="sign-up-password"
            valueProp={password.password}
            onChangeFunc={(e) => {
              if (errorPasswordState) {
                setErrorPasswordState(false);
              }
              props.passwordFunc(e);
            }}
          />
          <div className="confirm-password-container">
            <PasswordInput
              labelName="Confirm Password"
              fieldName="sign-up-confirm-password"
              valueProp={password.confirmPassword}
              onChangeFunc={(e) => {
                if (errorPasswordState) {
                  setErrorPasswordState(false);
                }
                props.confirmPasswordFunc(e);
              }}
            />
            {errorPasswordState ? (
              <span className="password-match-error error-msg">
                Password does not match.
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {buttonState ? (
        <button className="btn" id="sign-up-btn" onClick={handleNextBtn}>
          Next
        </button>
      ) : (
        <button className="disabled-btn" id="sign-up-btn">
          Next
        </button>
      )}
    </div>
  );
};
