import React, { useState, useEffect } from "react";
import "./notificationsList.css";
import { NotificationRow } from "./notification-row/NotificationRow";
import { NoContentMessage } from "../../../components/no-content-messages/no-content-message/NoContentMessage";
import { IsLoadingMessage } from "../../../components/no-content-messages/is-loading-message/IsLoadingMessage";

export const NotificationsList = (props) => {
  // Model
  const { notificationsData } = props;
  const [notificationsState, setNotificationsState] = useState([]);

  // Controllers
  useEffect(() => {
    if (notificationsData.length > 0) {
      setNotificationsState(notificationsData);
    } else {
      setNotificationsState([]);
    }
  }, [notificationsData]);

  // View
  return (
    <div className="notifications-list-inner">
      {!props.isLoading ? (
        notificationsState.length > 0 ? (
          notificationsState.map((notification) => {
            return (
              <NotificationRow
                key={notification.id}
                id={notification.id}
                message={notification.message}
              />
            );
          })
        ) : (
          <NoContentMessage message="No Notifications" />
        )
      ) : (
        <IsLoadingMessage isLoading={props.isLoading} />
      )}
    </div>
  );
};
