import React from "react";
import "./errorNotif.css";

export const ErrorNotif = (props) => {
  return (
    <div className="error-notif">
      <span className="error-notif-msg">ERROR: {props.errMsg}</span>
    </div>
  );
};
