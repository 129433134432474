import { API_ENDPOINT } from "..";

export const checkCompanyAbn = async (companyAbn) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}companies/check-abn/${companyAbn}`,
      {
        credentials: "include",
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
};
