import { API_ENDPOINT } from "..";

export const checkEmailAvailability = async (email) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}users/check-email-availability/${email}`
    );
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
};
