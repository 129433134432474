import React, { useEffect, useState } from "react";
import "./productSelector.css";
import { ProductSelectorRow } from "./product-selector-row/ProductSelectorRow";
import { getAllProducts } from "../../../api/product-calls/getAllProducts";
import { ProductTag } from "./product-tag/ProductTag";
import { NoContentMessage } from "../../no-content-messages/no-content-message/NoContentMessage";
import { IsLoadingMessage } from "../../no-content-messages/is-loading-message/IsLoadingMessage";

export const ProductSelector = (props) => {
  // Model
  const [productsState, setProductsState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [filteredListState, setFilteredListState] = useState(productsState);
  const [showModalState, setShowModalState] = useState(false);
  const [filterState, setFilterState] = useState("");

  // Controllers
  const handleClick = async (e) => {
    e.preventDefault();
    setShowModalState(true);
    setIsLoadingState(true);
    const result = await getAllProducts();
    setProductsState(result);
    setIsLoadingState(false);
  };

  const handleCancelBtn = () => {
    setShowModalState(false);
    setFilterState("");
  };

  const handleAddBtn = () => {
    props.addProductsBtnFunc();
    setShowModalState(false);
    setFilterState("");
  };

  // -------------------------------------
  useEffect(() => {
    setFilteredListState(
      productsState.filter((product) => {
        return product.primary_identifier
          .toLowerCase()
          .includes(filterState.toLowerCase());
      })
    );
  }, [filterState, productsState]);

  // View
  return (
    <div className="product-selector-container">
      {showModalState && (
        <div className="modal-background">
          <div className="add-products-modal">
            <div className="product-id-search-container">
              <label className="input-label" htmlFor="products-id-search">
                Search for product by primary id:
              </label>
              <input
                id="products-id-search"
                type="text"
                placeholder="Primary ID"
                value={filterState}
                onChange={(e) => setFilterState(e.target.value)}
              />
            </div>
            <hr className="add-products-modal-divider" />
            <div className="add-products-modal-list scrollable-frame">
              {!isLoadingState ? (
                filteredListState.length > 0 ? (
                  filteredListState.map((product) => {
                    return (
                      <ProductSelectorRow
                        key={product.primary_identifier}
                        product={{
                          productId: product.id,
                          primaryId: product.primary_identifier,
                        }}
                        productName={product.long_name}
                        selectedProducts={props.selectedProducts}
                        addToSelectedProducts={props.addToSelectedProducts}
                        removeFromSelectedProducts={
                          props.removeFromSelectedProducts
                        }
                      />
                    );
                  })
                ) : (
                  <NoContentMessage message="No Products" />
                )
              ) : (
                <IsLoadingMessage />
              )}
            </div>
            <div className="add-products-modal-btns-container">
              <div className="add-products-modal-btns-container-inner">
                <button
                  id="add-to-selected-products-btn"
                  className="btn"
                  onClick={handleAddBtn}
                >
                  Add
                </button>
                <button
                  id="cancel-product-selector-btn"
                  className="btn"
                  onClick={handleCancelBtn}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Container to display selected products ---------------------------------- */}
      <button className="select-task-products-btn btn" onClick={handleClick}>
        Select Products
      </button>
      <div className="selected-products">
        <div className="selected-products-list-container">
          <div className="selected-products-list-container-inner horizontal-scroll">
            {props.productList.length > 0 ? (
              <div className="selected-products-list">
                {props.productList.map((product) => {
                  return (
                    <ProductTag
                      key={product.primaryId}
                      product={product}
                      onClickFunc={props.productTagRemoveFunc}
                    />
                  );
                })}
              </div>
            ) : (
              <NoContentMessage message="No Products" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
