import { API_ENDPOINT } from "..";

export const checkPasswordMatch = async (password) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}users/check-password-match/${password}`,
      {
        credentials: "include",
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
};
