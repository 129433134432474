import React, { useEffect, useState } from "react";
import "./changePasswordModal.css";
import { PasswordInput } from "../../../components/input-fields/password-input/PasswordInput";
import validator from "validator";
import { sanitizePasswordInput } from "../../../functions/input-sanitizers/santizePasswordInput";
import { ClipLoader } from "react-spinners";
import { checkPasswordMatch } from "../../../api/user-calls/checkPasswordMatch";
import { updateUserPassword } from "../../../api/user-calls/updateUserPassword";

export const ChangePasswordModal = (props) => {
  // Model
  const [currentPasswordState, setCurrentPasswordState] = useState("");
  const [newPasswordState, setNewPasswordState] = useState("");
  const [confirmNewPasswordState, setConfirmNewPasswordState] = useState("");
  const [nextBtnState, setNextBtnState] = useState(false);
  const [confirmBtnState, setConfirmBtnState] = useState(false);
  const [stepState, setStepState] = useState(1);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [animationState, setAnimationState] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [errorMsgState, setErrorMsgState] = useState(""); 
  // Controllers
  const handleNext = async () => {
    if (stepState === 1) {
      setIsLoadingState(true);
      const res = await checkPasswordMatch(currentPasswordState);
      setIsLoadingState(false);
      if (!res.success) {
        setErrorState(true);
        setErrorMsgState("Incorrect password.")
        return;
      }
    }
    setAnimationState("slide-out-left");
    await new Promise((resolve) => setTimeout(resolve, 145));
    setStepState(stepState + 1);
    setAnimationState("slide-in-left");
    await new Promise((resolve) => setTimeout(resolve, 150));
    setAnimationState("");
  };

  const handleConfirm = async () => {
    if (newPasswordState !== confirmNewPasswordState) {
      setErrorState(true);
      setErrorMsgState("Password does not match.")
    } else {
      setIsLoadingState(true)
      const res = await updateUserPassword({ password: newPasswordState });
      if(res.success) {
        props.cancelBtnFunc()
      } else {
        setIsLoadingState(false)
        setErrorState(true);
        setErrorMsgState("Server error.")
      }
    }
  };

  const handleBackBtn = async () => {
    setAnimationState("slide-out-right");
    await new Promise((resolve) => setTimeout(resolve, 145));
    setStepState(2);
    setAnimationState("slide-in-right");
    await new Promise((resolve) => setTimeout(resolve, 150));
    setAnimationState("");
    setConfirmNewPasswordState("");
    if (errorState) {
      setErrorState(false);
    }
  };

  useEffect(() => {
    if (
      (stepState == 1 && validator.isEmpty(currentPasswordState)) ||
      (stepState === 2 && validator.isEmpty(newPasswordState))
    ) {
      setNextBtnState(false);
    } else {
      setNextBtnState(true);
    }
    if (stepState === 3 && validator.isEmpty(confirmNewPasswordState)) {
      setConfirmBtnState(false);
    } else {
      setConfirmBtnState(true);
    }
  }, [
    stepState,
    currentPasswordState,
    newPasswordState,
    confirmNewPasswordState,
  ]);
  // View
  return (
    <div className="modal-background">
      <div className="change-password-modal-container">
        <div className="change-password-header-container">
          <h2 className="modal-header">Change Password</h2>
          <hr className="modal-header-line" />
        </div>
        <div className="change-password-inner-container">
          {stepState === 1 && (
            <div id="current-password-container" className={animationState}>
              <PasswordInput
                labelName="Current password"
                fieldName="current-password"
                valueProp={currentPasswordState}
                onChangeFunc={(e) => {
                  if (errorState) {
                    setErrorState(false);
                  }
                  setCurrentPasswordState(
                    sanitizePasswordInput(e.target.value)
                  );
                }}
                autoComplete="new-password"
              />
              {errorState && (
                <span className="change-password-match-error error-msg">
                  {errorMsgState}
                </span>
              )}
            </div>
          )}
          {stepState === 2 && (
            <div className={animationState}>
              <PasswordInput
                labelName="New password"
                fieldName="new-password"
                valueProp={newPasswordState}
                onChangeFunc={(e) =>
                  setNewPasswordState(sanitizePasswordInput(e.target.value))
                }
                autoComplete="new-password"
              />
            </div>
          )}
          {stepState === 3 && (
            <div id="confirm-new-password-container" className={animationState}>
              <PasswordInput
                labelName="Confirm new password"
                fieldName="confirm-new-password"
                valueProp={confirmNewPasswordState}
                onChangeFunc={(e) =>
                  setConfirmNewPasswordState(
                    sanitizePasswordInput(e.target.value)
                  )
                }
                autoComplete="new-password"
              />
              {errorState && (
                <span className="change-password-confirm-error error-msg">
                  {errorMsgState}
                </span>
              )}
            </div>
          )}
          <div className="change-password-btn-container">
            {stepState === 3 && (
              <button className="btn" onClick={handleBackBtn}>
                Back
              </button>
            )}
            {!isLoadingState ? (
              stepState < 3 ? (
                nextBtnState ? (
                  <button className="btn" onClick={handleNext}>
                    Next
                  </button>
                ) : (
                  <button className="disabled-btn" disabled>
                    Next
                  </button>
                )
              ) : confirmBtnState ? (
                <button className="btn" onClick={handleConfirm}>
                  Confirm
                </button>
              ) : (
                <button className="disabled-btn" disabled>
                  Confirm
                </button>
              )
            ) : (
              <div className="change-password-loading-container">
                <ClipLoader
                  size={20}
                  loading={isLoadingState}
                  color="var(--activeGrey)"
                />
              </div>
            )}
            <button className="btn" onClick={props.cancelBtnFunc}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
