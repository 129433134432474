export const formatDateTimeForDatabase = (dateString, timeString) => {
  const dateParts = dateString.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Months are zero-based
  const day = parseInt(dateParts[2]);

  const timeParts = timeString.match(/(\d+):(\d+) ([ap]m)/i);
  let hours = parseInt(timeParts[1]);
  const minutes = parseInt(timeParts[2]);
  const meridiem = timeParts[3].toLowerCase();

  if (meridiem === "pm" && hours !== 12) {
    hours += 12;
  } else if (meridiem === "am" && hours === 12) {
    hours = 0;
  }

  return new Date(year, month, day, hours, minutes, 0, 0);
}
