import React from "react";
import "./productTag.css";

export const ProductTag = (props) => {
  return (
    <div className="product-tag">
      <div className="product-id-container">
        <span>{props.product.primaryId}</span>
      </div>
      <div className="remove-product-tag-btn-container">
        <button className="remove-product-tag-btn" onClick={() => props.onClickFunc(props.product)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.43 13.43" className="remove-icon">
            <g>
              <polygon points="13.43 2.83 10.61 0 6.72 3.89 2.83 0 0 2.83 3.89 6.72 0 10.61 2.83 13.43 6.72 9.55 10.61 13.43 13.43 10.61 9.55 6.72 13.43 2.83" />
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};
