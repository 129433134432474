import React, { useState } from "react";
import "./monthViewCalendar.css";
import { MonthCalendarTile } from "./month-calendar-tile/MonthCalendarTile";
import { trimStringToMaxLength } from "../../../functions/trimStringToMaxLength";

export const MonthViewCalendar = (props) => {
  const [listOfEventTaskState, setListOfEventTaskState] = useState([]);
  const [detailsState, setDetailsState] = useState(null);
  const [selectedMonthState, setSelectedMonthState] = useState(new Date());
  const currentDate = new Date().toLocaleDateString(undefined, {
    month: "long",
    weekday: "long",
    day: "numeric",
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const selectedMonthName = months[selectedMonthState.getMonth()];
  const currentYear = selectedMonthState.getFullYear();
  const days = [];

  // Controllers
  const handleTileSelect = (arr) => {
    if (JSON.stringify(arr) !== JSON.stringify(listOfEventTaskState)) {
      setListOfEventTaskState(arr);
      setDetailsState(null);
    }
  };

  const handleTagSelect = (obj) => {
    setDetailsState(obj);
  };

  const handlePrevMonth = () => {
    setSelectedMonthState((prevMonth) => {
      const newMonth = new Date(prevMonth);
      newMonth.setMonth(newMonth.getMonth() - 1);
      return newMonth;
    });
  };

  const handleNextMonth = () => {
    setSelectedMonthState((prevMonth) => {
      const newMonth = new Date(prevMonth);
      newMonth.setMonth(newMonth.getMonth() + 1);
      return newMonth;
    });
  };

  const handleGoToCurrentMonth = () => {
    setSelectedMonthState(new Date());
  };

  const renderCalendar = () => {
    const firstDayOfMonth = new Date(
      selectedMonthState.getFullYear(),
      selectedMonthState.getMonth(),
      1
    );
    const daysInMonth = new Date(
      selectedMonthState.getFullYear(),
      selectedMonthState.getMonth() + 1,
      0
    ).getDate();
    const firstDayOfWeek = (firstDayOfMonth.getDay() + 6) % 7; // Convert Sunday (0) to Monday (6)
    const currentDate = new Date();

    // Render days of previous month
    for (let i = firstDayOfWeek - 1; i >= 0; i--) {
      const day = new Date(
        selectedMonthState.getFullYear(),
        selectedMonthState.getMonth(),
        0 - i
      ).getDate();
      days.push(
        <MonthCalendarTile
          key={`prev-${i}`}
          classProp="month-calendar-tile prev-month"
          dayNum={day}
          events={renderEventsForDay(
            day,
            selectedMonthState.getMonth() - 1,
            selectedMonthState.getFullYear()
          )}
          tasks={renderTasksForDay(
            day,
            selectedMonthState.getMonth() - 1,
            selectedMonthState.getFullYear()
          )}
          handleTileSelect={handleTileSelect}
        />
      );
    }

    // Render days of current month
    for (let day = 1; day <= daysInMonth; day++) {
      const isActive =
        day === currentDate.getDate() &&
        selectedMonthState.getMonth() === currentDate.getMonth() &&
        selectedMonthState.getFullYear() === currentDate.getFullYear();
      const classNames = `month-calendar-tile ${
        isActive ? "current-date" : ""
      }`;
      days.push(
        <MonthCalendarTile
          key={day}
          classProp={classNames}
          dayNum={day}
          events={renderEventsForDay(
            day,
            selectedMonthState.getMonth(),
            selectedMonthState.getFullYear()
          )}
          tasks={renderTasksForDay(
            day,
            selectedMonthState.getMonth(),
            selectedMonthState.getFullYear()
          )}
          handleTileSelect={handleTileSelect}
        />
      );
    }

    // Render days of next month if needed
    const lastDayOfWeek = new Date(
      selectedMonthState.getFullYear(),
      selectedMonthState.getMonth(),
      daysInMonth
    ).getDay();
    const remainingDays = 7 - lastDayOfWeek;
    for (let day = 1; day <= remainingDays; day++) {
      days.push(
        <MonthCalendarTile
          key={`next-${day}`}
          classProp="month-calendar-tile next-month"
          dayNum={day}
          events={renderEventsForDay(
            day,
            selectedMonthState.getMonth() + 1,
            selectedMonthState.getFullYear()
          )}
          tasks={renderTasksForDay(
            day,
            selectedMonthState.getMonth() + 1,
            selectedMonthState.getFullYear()
          )}
          handleTileSelect={handleTileSelect}
        />
      );
    }

    return <div className="calendar-days-container">{days}</div>;
  };

  const renderEventsForDay = (day, month, year) => {
    const eventsForDay = props.events.filter((event) => {
      event.type = "event";
      const startDate = event.start_date.split("/");
      const eventDay = parseInt(startDate[0]);
      const eventMonth = parseInt(startDate[1]) - 1; // Months are zero-based in JavaScript Date
      const eventYear = parseInt(startDate[2]);
      return eventDay === day && eventMonth === month && eventYear === year;
    });
    const eventsObj = {
      numOfEvents: eventsForDay.length,
      eventsList: eventsForDay,
    };
    return eventsObj;
  };

  const renderTasksForDay = (day, month, year) => {
    const tasksForDay = props.tasks.filter((task) => {
      task.type = "task";
      const startDate = task.start_date.split("/");
      const taskDay = parseInt(startDate[0]);
      const taskMonth = parseInt(startDate[1]) - 1; // Months are zero-based in JavaScript Date
      const taskYear = parseInt(startDate[2]);
      return taskDay === day && taskMonth === month && taskYear === year;
    });
    const tasksObj = { numOfTasks: tasksForDay.length, tasksList: tasksForDay };
    return tasksObj;
  };

  // View
  return (
    <div className="month-view-container">
      <div className="month-view-details-column">
        <div className="month-view-details-container">
          <div className="month-view-list-of-selected-events-tasks-container">
            <h4 className="month-view-details-header">Selected:</h4>
            <div className="month-view-list-of-selected-events-tasks-container-inner">
              {listOfEventTaskState.some((item) => item.type === "event") && (
                <div>
                  <h4 className="month-view-list-header">Events:</h4>
                  <div className="month-view-list-of-selected-events-tasks scrollable-frame">
                    <div className="month-view-list-of-selected-events">
                      {listOfEventTaskState.length > 0 &&
                        listOfEventTaskState.map((item) => {
                          if (item.type === "event") {
                            return (
                              <div
                                key={item.id}
                                className={
                                  item.dateConflict
                                    ? "month-view-event-tag-expired"
                                    : "month-view-event-tag"
                                }
                                onClick={() => handleTagSelect(item)}
                              >
                                {trimStringToMaxLength(item.name, 25)}
                              </div>
                            );
                          }
                          return null;
                        })}
                    </div>
                  </div>
                </div>
              )}
              {listOfEventTaskState.some((item) => item.type === "task") && (
                <div>
                  <h4 className="month-view-list-header">Tasks:</h4>
                  <div className="month-view-list-of-selected-events-tasks scrollable-frame">
                    <div className="month-view-list-of-selected-tasks">
                      {listOfEventTaskState.length > 0 &&
                        listOfEventTaskState.map((item) => {
                          if (item.type === "task") {
                            return (
                              <div
                                className={
                                  item.dateConflict
                                    ? "month-view-task-tag-expired"
                                    : "month-view-task-tag"
                                }
                                onClick={() => handleTagSelect(item)}
                                key={item.id}
                              >
                                {trimStringToMaxLength(item.name, 25)}
                              </div>
                            );
                          }
                          return null;
                        })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="month-view-event-task-details-container">
            <h4 className="month-view-event-task-details-header">Details:</h4>
            <div className="month-view-event-task-details-container-inner scrollable-frame">
              {detailsState &&
                (detailsState.type === "event" ? (
                  <div className="month-view-all-details-container">
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">
                        Event Name:
                      </label>
                      <span className="month-view-detail">
                        {detailsState.name}
                      </span>
                    </div>
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">
                        Start Date:
                      </label>
                      <span className="month-view-detail">
                        {detailsState.start_date}
                      </span>
                    </div>
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">
                        End Date:
                      </label>
                      <span className="month-view-detail">
                        {detailsState.end_date}
                      </span>
                    </div>
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">Status:</label>
                      <span className="month-view-detail">
                        {detailsState.status}
                      </span>
                    </div>
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">
                        Description:
                      </label>
                      <span className="month-view-detail">
                        {detailsState.description
                          ? detailsState.description
                          : "null"}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="month-view-all-details-container">
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">
                        Task Name:
                      </label>
                      <span className="month-view-detail">
                        {detailsState.name}
                      </span>
                    </div>
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">
                        Event Name:
                      </label>
                      <span className="month-view-detail">
                        {detailsState.event_name}
                      </span>
                    </div>
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">
                        Start Date:
                      </label>
                      <span className="month-view-detail">
                        {detailsState.start_date}
                      </span>
                    </div>
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">
                        End Date:
                      </label>
                      <span className="month-view-detail">
                        {detailsState.end_date}
                      </span>
                    </div>
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">Status:</label>
                      <span className="month-view-detail">
                        {detailsState.status}
                      </span>
                    </div>
                    <div className="month-view-detail-group">
                      <label className="month-view-detail-label">
                        Description:
                      </label>
                      <span className="month-view-detail">
                        {detailsState.description
                          ? detailsState.description
                          : "null"}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="month-view-calendar">
        <div className="month-view-header">
          <button
            className="current-month-date"
            onClick={handleGoToCurrentMonth}
          >
            {currentDate}
          </button>
          <div className="month-view-header-selector">
            <div className="month-view-selector-btn-container">
              <button
                className="month-view-selector-btn"
                onClick={handlePrevMonth}
              >
                <svg
                  className="month-view-selector-btn-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 5.33 7"
                >
                  <g>
                    <path d="m0,3.5c0-.31.15-.61.4-.8L3.73.2c.44-.33,1.07-.24,1.4.2.33.44.24,1.07-.2,1.4l-2.27,1.7,2.27,1.7c.44.33.53.96.2,1.4-.33.44-.96.53-1.4.2L.4,4.3c-.25-.19-.4-.48-.4-.8Z" />
                  </g>
                </svg>
              </button>
            </div>

            <h4 className="selected-month-header">
              {selectedMonthName} {currentYear}
            </h4>
            <div className="month-view-selector-btn-container">
              <button
                className="month-view-selector-btn"
                onClick={handleNextMonth}
              >
                <svg
                  className="month-view-selector-btn-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 5.33 7"
                >
                  <g>
                    <path d="m5.33,3.5c0-.31-.15-.61-.4-.8L1.6.2C1.16-.13.53-.04.2.4-.13.84-.04,1.47.4,1.8l2.27,1.7L.4,5.2c-.44.33-.53.96-.2,1.4.33.44.96.53,1.4.2l3.33-2.5c.25-.19.4-.48.4-.8Z" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="week-days-header-container">
          <div className="week-days-header">
            {daysOfWeek.map((day) => (
              <div key={day} className="week-day">
                {day}
              </div>
            ))}
          </div>
          <hr className="week-days-header-divider" />
        </div>
        {renderCalendar()}
      </div>
    </div>
  );
};
