import React, { useState, useEffect, useRef } from "react";
import "./timeInput.css";

export const TimeInput = (props) => {
  // Model
  const { time } = props;
  const [showOptionsState, setShowOptionsState] = useState(false);
  const [disabledState, setDisabledState] = useState(false);
  const dropdownRef = useRef();
  const timeOptions = [
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ];
  const allDayTime = props.labelName.toLowerCase().includes("end")
    ? "11:30 PM"
    : "12:00 AM";
  // Controllers

  const handleClick = () => {
    setShowOptionsState(true);
  };

  const handleCheck = () => {
    props.handleCheck();
    if (!props.isChecked) {
      props.handleTimeSelect(allDayTime);
    }
  };

  useEffect(() => {
    if (props.isChecked) {
      setDisabledState(true);
    } else {
      setDisabledState(false);
    }
  }, [props.isChecked]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        showOptionsState &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setShowOptionsState(false);
      }
    };

    if (showOptionsState) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showOptionsState]);
  // View
  return (
    <div className="time-input-container">
      <label className="input-label">{props.labelName}</label>
      <div className="time-input-inner-container">
        <div
          className={disabledState ? "disabled-time-input" : ""}
          ref={dropdownRef}
        >
          <div
            className="time-input-field"
            onClick={() => {
              if (!disabledState) {
                handleClick();
              }
            }}
          >
            <div>{time === "" ? "- - : - - " : time}</div>
            <div>
              <svg
                className="time-input-arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 7 5.33"
              >
                <g>
                  <path d="m3.5,5.33c-.31,0-.61-.15-.8-.4L.2,1.6C-.13,1.16-.04.53.4.2.84-.13,1.47-.04,1.8.4l1.7,2.27L5.2.4c.33-.44.96-.53,1.4-.2.44.33.53.96.2,1.4l-2.5,3.33c-.19.25-.48.4-.8.4Z" />
                </g>
              </svg>
            </div>
          </div>
          {showOptionsState && (
            <div className="time-input-selector">
              <div className="time-input-selector-inner scrollable-frame">
                {timeOptions.map((option) => {
                  return (
                    <span
                      key={option}
                      className="time-input-option"
                      onClick={() => {
                        props.handleTimeSelect(option);
                        setShowOptionsState(false);
                      }}
                    >
                      {option}
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="all-day-checkbox">
          <input
            type="checkbox"
            checked={props.isChecked}
            onChange={handleCheck}
          />
          <label>All Day</label>
        </div>
      </div>
    </div>
  );
};
