import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { SignInPage } from "./pages/sign-in-page/SignInPage";
import { SignUpPage } from "./pages/sign-up-page/SignUpPage";
import { DashboardPage } from "./pages/dashboard-page/DashboardPage";
import { CalendarPage } from "./pages/calendar-page/CalendarPage";
import { EventsPage } from "./pages/events-page/EventsPage";
import { TasksPage } from "./pages/tasks-page/TasksPage";
import { ProductsPage } from "./pages/products-page/ProductsPage";
import { CommunityPage } from "./pages/community-page/CommunityPage";
import { ManageUsersPage } from "./pages/manage-users-page/ManageUsersPage";
import { AboutPage } from "./pages/about-page/AboutPage";
import { SettingsPage } from "./pages/settings-page/SettingsPage";

export const App = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<Navigate replace to="/sign-in" />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/calendar" element={<CalendarPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/tasks" element={<TasksPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/community" element={<CommunityPage />} />
        <Route path="/manage-users" element={<ManageUsersPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Routes>
    </>
  );
};
