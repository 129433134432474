import { API_ENDPOINT } from "..";

export const addNewProduct = async (data) => {
  try {
    const response = await fetch(`${API_ENDPOINT}products/add-product`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include"
    });
    const res = await response.json();
    if (res === "success") {
      return "success"
    } else {
      return res
    }
  } catch(error) {
    console.log(error)
  }
};
