import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./signUpForm.css";
import { getTimeStamp } from "../../../functions/getTimeStamp";
import { createNewUser } from "../../../api/user-calls/createNewUser";
import { createNewCompany } from "../../../api/company-calls/createNewCompany";
import { generateUniqueId } from "../../../functions/generateUniqueId";
import { sanitizeNumberInput } from "../../../functions/input-sanitizers/sanitizeNumberInput";
import { sanitizeTextInput } from "../../../functions/input-sanitizers/sanitizeTextInput";
import { checkUserCredentials } from "../../../api/user-calls/checkUserCredentials";
import { SignUpUserDetails } from "./sign-up-user-details/SignUpUserDetails";
import { SignUpPassword } from "./sign-up-password/SignUpPassword";
import { SignUpCompanyDetails } from "./sign-up-company-details/SignUpCompanyDetails";
import { SignUpBillingOptions } from "./sign-up-billing-options/SignUpBillingOptions";
import { sanitizePasswordInput } from "../../../functions/input-sanitizers/santizePasswordInput";
import { IsLoadingMessage } from "../../../components/no-content-messages/is-loading-message/IsLoadingMessage";

export const SignUpForm = () => {
  const navigate = useNavigate();
  const [isLoadingState, setIsLoadingState] = useState(false);
  //Model-----------------------------------------
  const initialUserDetailsState = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  };
  const initialPasswordState = {
    password: "",
    confirmPassword: "",
  };

  const initialCompanyState = {
    companyName: "",
    companyAbn: "",
    role: "",
  };
  const [userDetailsState, setUserDetailsState] = useState(
    initialUserDetailsState
  );
  const [passwordState, setPasswordState] = useState(initialPasswordState);
  const [companyDetailsState, setCompanyDetailsState] =
    useState(initialCompanyState);
  const [stepState, setStepState] = useState(1);

  const checkIcon = (
    <svg
      className="sign-up-step-completed-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.07 7.36"
    >
      <g>
        <path d="m3.57,7.36h0c-.3,0-.58-.13-.77-.36L.23,3.94c-.35-.42-.3-1.05.12-1.41.42-.35,1.05-.3,1.41.12l1.81,2.15L7.3.36c.35-.42.99-.48,1.41-.12.42.35.48.99.12,1.41l-4.5,5.36c-.19.23-.47.36-.77.36Z" />
      </g>
    </svg>
  );

  //Controller-----------------------------------------------
  const setStepClassName = (step) => {
    let className;
    if (step < stepState) {
      className = "step-completed";
    } else if (step === stepState) {
      className = "step-active";
    } else {
      className = "step-inactive";
    }
    return `sign-up-step-counter ${className}`;
  };

  const handleStepChange = (num) => {
    setStepState(num);
  };

  const handleSubmit = async () => {
    setIsLoadingState(true);
    // gets current data and time
    const currentDateTime = getTimeStamp();
    // gets unique ID
    const userId = await generateUniqueId(16, "users");

    const userData = {
      id: userId,
      password: passwordState.password,
      email: userDetailsState.email.toLowerCase(),
      mobile: userDetailsState.mobile,
      firstName: userDetailsState.firstName,
      lastName: userDetailsState.lastName,
      companyAbn: companyDetailsState.companyAbn,
      role: companyDetailsState.role,
      createdAt: currentDateTime,
      lastSignIn: currentDateTime,
    };

    if (companyDetailsState.role === "company_admin") {
      const companyId = await generateUniqueId(8, "companies");
      const companyData = {
        id: companyId,
        name: companyDetailsState.companyName,
        abn: companyDetailsState.companyAbn,
        createdAt: currentDateTime,
      };
      const response = await createNewCompany(companyData);
      if (!response.success) {
        console.log("error error");
      }
    }
    // add user data to database
    const response = await createNewUser(userData);
    if (response.success) {
      const res = await checkUserCredentials({
        email: userDetailsState.email,
        password: passwordState.password,
      });
      if (res) {
        navigate("/dashboard");
      } else {
        navigate("/sign-in");
      }
    } else {
      setIsLoadingState(false);
    }
  };
  //View-------------------------------------------------
  return (
    <div className="sign-up-form">
      <div className="sign-up-step-counter-container">
        <div className={setStepClassName(1)}>
          {stepState > 1 ? checkIcon : "1"}
        </div>
        <div className={setStepClassName(2)}>
          {stepState > 2 ? checkIcon : "2"}
        </div>
        <div className={setStepClassName(3)}>
          {stepState > 3 ? checkIcon : "3"}
        </div>
        <div className={setStepClassName(4)}>4</div>
      </div>
      <div className="sign-up-form-main-container">
        {stepState === 1 && (
          <SignUpUserDetails
            userDetails={userDetailsState}
            firstNameFunc={(e) => {
              setUserDetailsState((prevState) => ({
                ...prevState,
                firstName: sanitizeTextInput(e.target.value),
              }));
            }}
            lastNameFunc={(e) => {
              setUserDetailsState((prevState) => ({
                ...prevState,
                lastName: sanitizeTextInput(e.target.value),
              }));
            }}
            emailFunc={(e) => {
              setUserDetailsState((prevState) => ({
                ...prevState,
                email: e.target.value,
              }));
            }}
            mobileFunc={(e) => {
              setUserDetailsState((prevState) => ({
                ...prevState,
                mobile: sanitizeNumberInput(e.target.value),
              }));
            }}
            handleClick={() => {
              handleStepChange(2);
            }}
          />
        )}
        {stepState === 2 && (
          <SignUpPassword
            password={passwordState}
            passwordFunc={(e) => {
              setPasswordState((prevState) => ({
                ...prevState,
                password: sanitizePasswordInput(e.target.value),
              }));
            }}
            confirmPasswordFunc={(e) => {
              setPasswordState((prevState) => ({
                ...prevState,
                confirmPassword: sanitizePasswordInput(e.target.value),
              }));
            }}
            handleClick={() => {
              handleStepChange(3);
            }}
          />
        )}
        {stepState === 3 && (
          <SignUpCompanyDetails
            companyDetails={companyDetailsState}
            companyNameFunc={(e) => {
              setCompanyDetailsState((prevState) => ({
                ...prevState,
                companyName: e.target.value,
              }));
            }}
            companyMatchFunc={(name) => {
              setCompanyDetailsState((prevState) => ({
                ...prevState,
                companyName: name,
              }));
            }}
            companyAbnFunc={(e) => {
              setCompanyDetailsState((prevState) => ({
                ...prevState,
                companyAbn: sanitizeNumberInput(e.target.value),
              }));
            }}
            userRoleFunc={(role) => {
              setCompanyDetailsState((prevState) => ({
                ...prevState,
                role: role,
              }));
            }}
            handleClick={() => {
              handleStepChange(4);
            }}
          />
        )}
        {!isLoadingState ? (
          stepState === 4 && (
            <SignUpBillingOptions handleSubmit={handleSubmit} />
          )
        ) : (
          <IsLoadingMessage isLoading={isLoadingState} />
        )}
      </div>
    </div>
  );
};
