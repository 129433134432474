import { API_ENDPOINT } from "..";

export const createNewCompany = async (data) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}companies/create-new-company`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    if (!response.ok) {
      throw new Error("Failed to create company");
    }
    const res = await response.json();
    return res;
  } catch (error) {
    console.error(error);
  }
};
