import React from "react";
import "./navBar.css";
import { NavLink } from "react-router-dom";
import OpusIcon from "../../images/Opus_Logo_Icon.svg";
import { useSelector } from "react-redux";

export const NavBar = () => {
  // Model
  const userRole = useSelector((state) => state.user.userDetails.role);

  // View
  return (
    <nav className="nav-container">
      <div className="opus-logo-container">
        <img className="opus-logo-icon" src={OpusIcon} alt="opus" />
      </div>
      <div className="nav-icon-list-container">
        <div className="nav-icon-list">
          {/* --Dashboard-- */}
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? "nav-icon-active" : "nav-icon"
            }
          >
            <div className="nav-icon-container">
              <svg
                className="nav-icon-img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 25"
                aria-label="dashboard button icon"
              >
                <g>
                  <path d="M21,25h-7c-.55,0-1-.45-1-1v-6.99h-4v6.99c0,.55-.45,1-1,1H1c-.55,0-1-.45-1-1V10.21c0-.28,.12-.55,.32-.74L10.32,.26c.38-.35,.97-.35,1.36,0l10,9.21c.21,.19,.32,.46,.32,.74v13.79c0,.55-.45,1-1,1Zm-6-2h5V10.65L11,2.36,2,10.65v12.35H7v-6.99c0-.55,.45-1,1-1h6c.55,0,1,.45,1,1v6.99Z" />
                </g>
              </svg>
            </div>
            <span className="nav-title">Dashboard</span>
          </NavLink>
          {/* --Calendar-- */}
          <NavLink
            to="/calendar"
            className={({ isActive }) =>
              isActive ? "nav-icon-active" : "nav-icon"
            }
          >
            <div className="nav-icon-container">
              <svg
                className="nav-icon-img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
                aria-label="calendar button icon"
              >
                <g>
                  <path d="M24,4h-3.95V1c0-.55-.45-1-1-1s-1,.45-1,1v3H7.05V1c0-.55-.45-1-1-1s-1,.45-1,1v3H1c-.55,0-1,.45-1,1v15c0,2.76,2.24,5,5,5h15c2.76,0,5-2.24,5-5V5c0-.55-.45-1-1-1Zm-1,2v4H2V6H23Zm-13.45,10.5v-4.5h6v4.5h-6Zm6,2v4.5h-6v-4.5h6Zm-8-2H2v-4.5H7.55v4.5Zm10-4.5h5.45v4.5h-5.45v-4.5ZM2,20v-1.5H7.55v4.5h-2.55c-1.65,0-3-1.35-3-3Zm18,3h-2.45v-4.5h5.45v1.5c0,1.65-1.35,3-3,3Z" />
                </g>
              </svg>
            </div>
            <span className="nav-title">Calendar</span>
          </NavLink>

          {/* --Events-- */}
          <NavLink
            to="/events"
            className={({ isActive }) =>
              isActive ? "nav-icon-active" : "nav-icon"
            }
          >
            <div className="nav-icon-container">
              <svg
                className="nav-icon-img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
                aria-label="events button icon"
              >
                <g>
                  <g>
                    <path d="M24,4h-3.95V1c0-.55-.45-1-1-1s-1,.45-1,1v3H7.05V1c0-.55-.45-1-1-1s-1,.45-1,1v3H1c-.55,0-1,.45-1,1v15c0,2.76,2.24,5,5,5h15c2.76,0,5-2.24,5-5V5c0-.55-.45-1-1-1Zm-1,2v4H2V6H23Zm-3,17H5c-1.65,0-3-1.35-3-3V12H23v8c0,1.65-1.35,3-3,3Z" />
                    <path d="M16.47,16.36l-2.56-.4-1.17-2.31c-.1-.19-.38-.19-.47,0l-1.17,2.31-2.56,.4c-.22,.03-.3,.3-.15,.45l1.84,1.83-.41,2.56c-.03,.21,.19,.38,.38,.28l2.31-1.18,2.31,1.18c.19,.1,.42-.06,.38-.28l-.41-2.56,1.84-1.83c.15-.15,.07-.42-.15-.45Z" />
                  </g>
                </g>
              </svg>
            </div>
            <span className="nav-title">Events</span>
          </NavLink>

          {/* --Tasks-- */}
          <NavLink
            to="/tasks"
            className={({ isActive }) =>
              isActive ? "nav-icon-active" : "nav-icon"
            }
          >
            <div className="nav-icon-container">
              <svg
                className="nav-icon-img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 25"
                aria-label="tasks button icon"
              >
                <g>
                  <path d="m19.01,2.5h-4.01v-1.5c0-.55-.44-1-1-1H6c-.55,0-1,.45-1,1v1.5H.99c-.54,0-.99.44-.99.99v20.52c0,.55.45.99.99.99h18.02c.55,0,.99-.44.99-.99V3.49c0-.55-.44-.99-.99-.99Zm-12.01-.5h6v3h-6v-3Zm11,21H2V4.5h3v1.5c0,.55.45,1,1,1h8c.56,0,1-.45,1-1v-1.5h3v18.5ZM7,11.55h-2v-2h2v2Zm8,0h-6v-2h6v2Zm-8,8h-2v-2h2v2Zm8,0h-6v-2h6v2Zm-8-4h-2v-2h2v2Zm8,0h-6v-2h6v2Z" />
                </g>
              </svg>
            </div>
            <span className="nav-title">Tasks</span>
          </NavLink>

          {/* --Products-- */}
          <NavLink
            to="/products"
            className={({ isActive }) =>
              isActive ? "nav-icon-active" : "nav-icon"
            }
          >
            <div className="nav-icon-container">
              <svg
                className="nav-icon-img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22.7 25"
                aria-label="products button icon"
              >
                <g>
                  <g>
                    <path d="M20.63,5.24L11.74,.1c-.12-.07-.25-.1-.39-.1s-.27,.03-.39,.1L2.07,5.24c-.52,.3-.52,1.04,0,1.34l8.89,5.13c.12,.07,.25,.1,.39,.1s.27-.03,.39-.1l8.89-5.13c.52-.3,.52-1.04,0-1.34Zm-9.28,4.39l-6.44-3.72,6.44-3.72,6.44,3.72-6.44,3.72Z" />
                    <path d="M10.05,13.29L1.16,8.15c-.12-.07-.26-.1-.39-.1-.4,0-.77,.32-.77,.77v10.27c0,.28,.15,.53,.39,.67l8.89,5.13c.12,.07,.26,.1,.39,.1,.4,0,.77-.32,.77-.77V13.96c0-.28-.15-.53-.39-.67Zm-1.61,8.81l-6.44-3.72v-7.44l6.44,3.72v7.44Z" />
                    <path d="M21.92,8.05c-.13,0-.26,.03-.39,.1l-8.89,5.13c-.24,.14-.39,.39-.39,.67v10.27c0,.45,.37,.77,.77,.77,.13,0,.26-.03,.39-.1l8.89-5.13c.24-.14,.39-.39,.39-.67V8.82c0-.45-.37-.77-.77-.77Zm-1.23,10.34l-6.44,3.72v-7.44l6.44-3.72v7.44Z" />
                  </g>
                </g>
              </svg>
            </div>
            <span className="nav-title">Products</span>
          </NavLink>

          {/* --Community-- */}
          <NavLink
            to="/community"
            className={({ isActive }) =>
              isActive ? "nav-icon-active" : "nav-icon"
            }
          >
            <div className="nav-icon-container">
              <svg
                className="nav-icon-img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
                aria-label="community button icon"
              >
                <g>
                  <path d="M24.05,19.45c-.64-.63-1.47-.95-2.3-.95-.46,0-.92,.1-1.34,.3l-4.36-4.78c.8-1.02,1.28-2.3,1.28-3.7,0-.88-.19-1.71-.53-2.46l2.47-1.66c.65,.53,1.43,.8,2.23,.8,.9,0,1.79-.34,2.47-1.02,1.37-1.37,1.37-3.59,0-4.95-.68-.69-1.57-1.03-2.47-1.03s-1.79,.34-2.47,1.03c-.95,.95-1.24,2.31-.87,3.51l-2.48,1.66c-1.08-1.16-2.63-1.88-4.35-1.88-1.43,0-2.75,.5-3.78,1.34l-1.77-1.54h0c.44-1.09,.21-2.37-.66-3.24-.58-.58-1.35-.88-2.12-.88S1.46,.3,.88,.88C-.29,2.05-.29,3.95,.88,5.12c.58,.59,1.35,.88,2.12,.88,.51,0,1.02-.13,1.47-.38l1.76,1.55c-.57,.91-.9,1.99-.9,3.15,0,1.99,.97,3.76,2.47,4.85l-.96,1.92c-.27-.06-.55-.09-.84-.09-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4c0-1.21-.54-2.29-1.38-3.02l.96-1.92c.56,.17,1.14,.26,1.75,.26,1.2,0,2.31-.35,3.24-.96l4.36,4.78c-.72,1.24-.54,2.85,.52,3.91,.64,.63,1.47,.95,2.3,.95s1.66-.32,2.3-.95c1.27-1.27,1.27-3.33,0-4.6ZM20.02,3.29c.04-.32,.19-.62,.42-.85,.28-.28,.66-.44,1.06-.44s.78,.16,1.06,.44c.28,.28,.44,.66,.44,1.06s-.16,.78-.44,1.06c-.28,.29-.66,.44-1.06,.44-.12,0-.24-.01-.36-.05-.26-.06-.51-.19-.7-.39-.28-.28-.44-.66-.44-1.06,0-.07,0-.14,.02-.21ZM3.71,3.71c-.26,.25-.56,.29-.71,.29-.14,0-.41-.04-.66-.25-.02-.01-.03-.02-.05-.04-.39-.39-.39-1.03,0-1.41,.26-.26,.56-.3,.71-.3,.14,0,.41,.04,.66,.25l.05,.05c.39,.38,.39,1.02,0,1.41Zm2.29,19.29c-1.1,0-2-.89-2-2s.84-1.94,1.89-1.99c.03,0,.07,0,.11,0,.7,0,1.31,.36,1.66,.9,.22,.32,.34,.7,.34,1.1,0,1.11-.9,2-2,2ZM14.68,12.51c-.37,.56-.88,1.03-1.48,1.34-.56,.3-1.19,.47-1.87,.47-.29,0-.56-.03-.83-.09-.68-.14-1.29-.46-1.79-.89-.85-.74-1.38-1.82-1.38-3.02,0-.65,.16-1.26,.44-1.8,.3-.61,.75-1.12,1.31-1.51,.64-.43,1.42-.69,2.25-.69,1.02,0,1.95,.38,2.66,1.02,.5,.44,.89,1.01,1.11,1.66,.15,.41,.23,.86,.23,1.32,0,.81-.24,1.56-.65,2.19Zm7.95,10.13c-.23,.23-.55,.36-.88,.36s-.65-.13-.88-.36c-.21-.21-.33-.47-.36-.76-.01-.04-.01-.09-.01-.13,0-.33,.13-.65,.37-.88,.23-.24,.55-.37,.88-.37,.08,0,.16,0,.23,.02,.25,.05,.48,.17,.65,.35,.49,.49,.49,1.28,0,1.77Z" />
                </g>
              </svg>
            </div>
            <span className="nav-title">Community</span>
          </NavLink>
          {/* -- COMPANY_ADMIN ONLY - manage users-- */}
          {userRole === "company_admin" && (
            <NavLink
              to="/manage-users"
              className={({ isActive }) =>
                isActive ? "nav-icon-active" : "nav-icon"
              }
            >
              <div className="nav-icon-container">
                <svg
                  className="nav-icon-img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 25"
                  aria-label="manage users button icon"
                >
                  <g>
                    <path
                      d="m12,2c2.21,0,4,1.79,4,4s-1.79,4-4,4-4-1.79-4-4,1.79-4,4-4m0-2c-3.31,0-6,2.69-6,6s2.69,6,6,6,6-2.69,6-6S15.31,0,12,0h0Z"
                    />
                    <path
                      d="m19.92,16c-2.11-1.86-4.88-3-7.92-3C5.37,13,0,18.37,0,25h2c0-5.51,4.49-10,10-10,1.55,0,3.02.37,4.33,1h3.59Z"
                    />
                    <path
                      d="m24,17h-7c-.55,0-1,.45-1,1s.45,1,1,1h7c.55,0,1-.45,1-1s-.45-1-1-1h0Z"
                    />
                    <path
                      d="m24,20h-7c-.55,0-1,.45-1,1s.45,1,1,1h7c.55,0,1-.45,1-1s-.45-1-1-1h0Z"
                    />
                    <path
                      d="m24,23h-7c-.55,0-1,.45-1,1s.45,1,1,1h7c.55,0,1-.45,1-1s-.45-1-1-1h0Z"
                    />
                  </g>
                </svg>
              </div>
              <span className="nav-title">Manage Users</span>
            </NavLink>
          )}

          {/* --About-- */}
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? "nav-icon-active" : "nav-icon"
            }
          >
            <div className="nav-icon-container">
              <svg
                className="nav-icon-img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
                aria-label="about button icon"
              >
                <g>
                  <g>
                    <path d="M12.5,0h0C5.6,0,0,5.6,0,12.5H0c0,6.9,5.6,12.5,12.5,12.5h0c6.9,0,12.5-5.6,12.5-12.5h0C25,5.6,19.4,0,12.5,0Zm10.5,12.5c0,5.8-4.7,10.5-10.5,10.5h0c-5.8,0-10.5-4.7-10.5-10.5h0C2,6.7,6.7,2,12.5,2h0c5.8,0,10.5,4.7,10.5,10.5h0Z" />
                    <g>
                      <path d="M14.18,17.8h-.56v-6.73c0-.62-.5-1.12-1.12-1.12h-1.12c-.62,0-1.12,.5-1.12,1.12s.5,1.12,1.12,1.12v5.61h-.56c-.62,0-1.12,.5-1.12,1.12s.5,1.12,1.12,1.12h3.36c.62,0,1.12-.5,1.12-1.12s-.5-1.12-1.12-1.12Z" />
                      <path d="M12.5,8.32c.93,0,1.68-.75,1.68-1.68s-.75-1.68-1.68-1.68-1.68,.75-1.68,1.68,.75,1.68,1.68,1.68Z" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <span className="nav-title">About</span>
          </NavLink>
        </div>
      </div>
      <NavLink
        to="/settings"
        className={({ isActive }) =>
          isActive ? "nav-icon-active" : "nav-icon"
        }
      >
        <div className="nav-icon-container" id="settings-icon-container">
          <svg
            className="nav-icon-img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25 25"
            aria-label="settings button icon"
          >
            <g>
              <path d="m24.23,10.26l-2.67-.63c-.17-.52-.38-1.02-.63-1.5l1.45-2.34c.24-.39.18-.91-.14-1.23l-1.79-1.79c-.33-.33-.84-.39-1.23-.14l-2.34,1.45c-.48-.25-.98-.46-1.5-.63l-.63-2.67c-.11-.45-.51-.77-.97-.77h-2.53c-.46,0-.87.32-.97.77l-.63,2.67c-.52.17-1.02.38-1.5.63l-2.34-1.45c-.39-.24-.91-.18-1.23.14l-1.79,1.79c-.33.33-.39.84-.14,1.23l1.45,2.34c-.25.48-.46.98-.63,1.5l-2.67.63c-.45.11-.77.51-.77.97v2.53c0,.46.32.87.77.97l2.67.63c.17.52.38,1.02.63,1.5l-1.45,2.34c-.24.39-.18.91.14,1.23l1.79,1.79c.33.33.84.39,1.23.14l2.34-1.45c.48.25.98.46,1.5.63l.63,2.67c.11.45.51.77.97.77h2.53c.46,0,.87-.32.97-.77l.63-2.67c.52-.17,1.02-.38,1.5-.63l2.34,1.45c.39.24.91.18,1.23-.14l1.79-1.79c.33-.33.39-.84.14-1.23l-1.45-2.34c.25-.48.46-.98.63-1.5l2.67-.63c.45-.11.77-.51.77-.97v-2.53c0-.46-.32-.87-.77-.97Zm-11.73,6.74c-2.48,0-4.5-2.02-4.5-4.5s2.02-4.5,4.5-4.5,4.5,2.02,4.5,4.5-2.02,4.5-4.5,4.5Z" />
            </g>
          </svg>
        </div>
      </NavLink>
    </nav>
  );
};
