import React, { useState } from "react";
import "./weekViewCalendar.css";
import { WeekCalendarTile } from "./week-calendar-tile/WeekCalendarTile";
import { EventDetailsModal } from "../../../components/modals/event-details-modal/EventDetailsModal";
import { TaskDetailsModal } from "../../../components/modals/task-details-modal/TaskDetailsModal";

export const WeekViewCalendar = (props) => {
  // Model
  const [eventDetailsState, setEventDetailsState] = useState({});
  const [eventViewState, setEventViewState] = useState(false);
  const [taskDetailsState, setTaskDetailsState] = useState({});
  const [taskViewState, setTaskViewState] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const currentDate = new Date().toLocaleDateString(undefined, {
    month: "long",
    weekday: "long",
    day: "numeric",
  });

  // Controller
  const handleGoToCurrentWeek = () => {
    setSelectedDate(new Date());
  };

  const handlePrevWeek = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 7);
      return newDate;
    });
  };

  const handleNextWeek = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 7);
      return newDate;
    });
  };

  const getWeekDays = (date) => {
    const weekStart = new Date(date);
    weekStart.setDate(date.getDate() - ((date.getDay() + 6) % 7)); // Set week start to Monday

    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(weekStart);
      day.setDate(weekStart.getDate() + i);
      weekDays.push(day);
    }

    return weekDays;
  };

  const formatDate = (date) => {
    const monthName = date.toLocaleDateString(undefined, { month: "short" });
    const dateNum = date.toLocaleDateString(undefined, { day: "numeric" });
    return dateNum + " " + monthName;
  };

  const weekDays = getWeekDays(selectedDate);

  const renderEventsForDay = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const eventsForDay = props.events.filter((event) => {
      event.type = "event";
      const startDate = event.start_date.split("/");
      const eventDay = parseInt(startDate[0]);
      const eventMonth = parseInt(startDate[1]) - 1; // Months are zero-based in JavaScript Date
      const eventYear = parseInt(startDate[2]);
      return eventDay === day && eventMonth === month && eventYear === year;
    });
    return eventsForDay;
  };

  const renderTasksForDay = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const tasksForDay = props.tasks.filter((task) => {
      task.type = "task";
      const startDate = task.start_date.split("/");
      const taskDay = parseInt(startDate[0]);
      const taskMonth = parseInt(startDate[1]) - 1; // Months are zero-based in JavaScript Date
      const taskYear = parseInt(startDate[2]);
      return taskDay === day && taskMonth === month && taskYear === year;
    });
    return tasksForDay;
  };

  const handleEventClick = (event) => {
    setEventViewState(true);
    setEventDetailsState(event);
  };

  const handleEventViewClose = () => {
    setEventViewState(false);
    setEventDetailsState({});
  };

  const handleTaskClick = (task) => {
    setTaskViewState(true);
    setTaskDetailsState(task);
  };

  const handleTaskViewClose = () => {
    setTaskViewState(false);
    setTaskDetailsState({});
  };

  // View
  return (
    <div className="week-view-calendar">
      {eventViewState && (
        <EventDetailsModal
          eventDetails={eventDetailsState}
          handleCloseBtn={handleEventViewClose}
        />
      )}
      {taskViewState && (
        <TaskDetailsModal
          taskDetails={taskDetailsState}
          handleCloseBtn={handleTaskViewClose}
        />
      )}
      <div className="week-view-header">
        <button className="current-month-date" onClick={handleGoToCurrentWeek}>
          {currentDate}
        </button>
        <div className="week-view-header-selector">
          <div className="week-view-selector-btn-container">
            <button className="week-view-selector-btn" onClick={handlePrevWeek}>
              <svg
                className="month-view-selector-btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 5.33 7"
              >
                <g>
                  <path d="m0,3.5c0-.31.15-.61.4-.8L3.73.2c.44-.33,1.07-.24,1.4.2.33.44.24,1.07-.2,1.4l-2.27,1.7,2.27,1.7c.44.33.53.96.2,1.4-.33.44-.96.53-1.4.2L.4,4.3c-.25-.19-.4-.48-.4-.8Z" />
                </g>
              </svg>
            </button>
          </div>
          <h4 className="selected-week-header">
            {formatDate(weekDays[0])} - {formatDate(weekDays[6])}
          </h4>
          <div className="week-view-selector-btn-container">
            <button className="week-view-selector-btn" onClick={handleNextWeek}>
              <svg
                className="week-view-selector-btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 5.33 7"
              >
                <g>
                  <path d="m5.33,3.5c0-.31-.15-.61-.4-.8L1.6.2C1.16-.13.53-.04.2.4-.13.84-.04,1.47.4,1.8l2.27,1.7L.4,5.2c-.44.33-.53.96-.2,1.4.33.44.96.53,1.4.2l3.33-2.5c.25-.19.4-.48.4-.8Z" />
                </g>
              </svg>
            </button>
          </div>
        </div>
        <></>
      </div>
      <div className="week-days-header-container">
        <div className="week-days-header">
          {daysOfWeek.map((day) => (
            <div key={day} className="week-day">
              {day}
            </div>
          ))}
        </div>
        <hr className="week-days-header-divider" />
      </div>
      <div className="week-view-calendar-grid">
        {weekDays.map((day, index) => (
          <WeekCalendarTile
            key={index}
            dayNum={day.getDate()}
            classProp={
              day.toDateString() === new Date().toDateString()
                ? " current-date"
                : ""
            }
            events={renderEventsForDay(day)}
            tasks={renderTasksForDay(day)}
            eventClick={handleEventClick}
            taskClick={handleTaskClick}
          />
        ))}
      </div>
    </div>
  );
};
