import React, { useState, useEffect } from "react";
import "./editEventModal.css";
import { useSelector } from "react-redux";
import { ErrorNotif } from "../../../components/notifications/error-notif/ErrorNotif";
import { TextInput } from "../../../components/input-fields/text-input/TextInput";
import { DateInput } from "../../../components/input-fields/date-input/DateInput";
import { TimeInput } from "../../../components/input-fields/time-input/TimeInput";
import { LargeTextInput } from "../../../components/input-fields/large-text-input/LargeTextInput";
import { TaskSelector } from "../../../components/selectors/task-selector/TaskSelector";
import { updateEventByEventId } from "../../../api/event-calls/updateEventByEventId";
import { convertToDateMinMax } from "../../../functions/convertToDateMinMax";
import validator from "validator";
import { formatDateTimeForDatabase } from "../../../functions/formatDateTimeForDatabase";
import { AddTaskForm } from "./add-task-form/AddTaskForm";
import { formatAndCreateTask } from "../../../functions/formatAndCreateTask";

export const EditEventModal = (props) => {
  // Model
  const initialTaskState = {
    name: "",
    startDate: "",
    startTime: "",
    startTimeAllDay: false,
    endDate: "",
    endTime: "",
    endTimeAllDay: false,
    description: "",
  };
  const [eventState, setEventState] = useState(props.event);
  const [taskState, setTaskState] = useState(initialTaskState);
  const [selectedProductsState, setSelectedProductsState] = useState([]);
  const [productListState, setProductListState] = useState([]);
  const [assigneeListState, setAssigneeListState] = useState([]);
  const [startTimeAllDayCheckState, setStartTimeAllDayCheckState] = useState(
    eventState.start_time === "12:00 AM"
  );
  const [endTimeAllDayCheckState, setEndTimeAllDayCheckState] = useState(
    eventState.end_time === "11:30 PM"
  );
  const [minDateState, setMinDateState] = useState("");
  const [errNotifState, setErrNotifState] = useState(false);
  const [saveBtnState, setSaveBtnState] = useState(true);
  const [addTaskFormState, setAddTaskFormState] = useState(false);
  const [addTaskBtnState, setAddTaskBtnState] = useState(false);
  const minTaskStartDate = convertToDateMinMax(eventState.start_date, true);
  const minTaskEndDate = convertToDateMinMax(taskState.startDate);
  const maxTaskDate = convertToDateMinMax(eventState.end_date, true);
  const [animationState, setAnimationState] = useState("");
  const firstName = useSelector((state) => state.user.userDetails.firstName);
  const lastName = useSelector((state) => state.user.userDetails.lastName);
  const fullName = firstName + " " + lastName;

  // Controllers
  const handleSaveBtn = async () => {
    const startDateTime = formatDateTimeForDatabase(
      eventState.start_date,
      eventState.start_time
    );
    const endDateTime = formatDateTimeForDatabase(
      eventState.end_date,
      eventState.end_time
    );
    const data = {
      name: eventState.name,
      startDate: startDateTime,
      endDate: endDateTime,
      description: eventState.description,
    };
    const result = await updateEventByEventId(eventState.id, data);
    if (result.success) {
      props.handleSaveBtn();
    } else {
      setErrNotifState(true);
      setTimeout(() => setErrNotifState(false), 3500);
    }
  };

  const handleAddTaskForm = async () => {
    setAnimationState(" slide-out-left");
    await new Promise((resolve) => setTimeout(resolve, 145));
    setAddTaskFormState(true);
    setAnimationState(" slide-in-left");
    await new Promise((resolve) => setTimeout(resolve, 150));
    setAnimationState("");
  };

  const handleTaskSubmit = async () => {
    const taskData = {
      id: "",
      name: taskState.name,
      startDate: taskState.startDate,
      startTime: taskState.startTime,
      endDate: taskState.endDate,
      endTime: taskState.endTime,
      description: taskState.description,
      products: productListState,
      assignees: assigneeListState,
      eventId: eventState.id,
      eventName: eventState.name,
    };
    const res = await formatAndCreateTask(taskData, fullName);
    if (res.success) {
      handleBackBtn();
    }
  };

  const handleBackBtn = async () => {
    setAnimationState(" slide-out-right");
    await new Promise((resolve) => setTimeout(resolve, 145));
    setAddTaskFormState(false);
    setAnimationState(" slide-in-right");
    await new Promise((resolve) => setTimeout(resolve, 150));
    setAnimationState("");
  };

  const handleTaskStartDateChange = (e) => {
    const selectedStartDate = new Date(e.target.value);
    setTaskState((prevState) => ({
      ...prevState,
      startDate: e.target.value,
      endDate:
        selectedStartDate >= new Date(prevState.endDate)
          ? ""
          : prevState.endDate,
    }));
  };

  const handleAddProductsBtn = () => {
    const newList = selectedProductsState.filter(
      (product) =>
        !productListState.some((item) => item.productId === product.productId)
    );
    setProductListState((prevState) => [...prevState, ...newList]);
    setSelectedProductsState([]);
  };

  const handleRemoveFromProductList = (product) => {
    setProductListState((prevState) =>
      prevState.filter((prod) => prod.productId !== product.productId)
    );
  };

  const handleAddAssigneeBtn = (assignee) => {
    if (!assigneeListState.some((item) => item.id === assignee.id)) {
      setAssigneeListState((prevState) => [...prevState, assignee]);
    }
  };

  const handleRemoveFromAssigneeList = (assigneeId) => {
    setAssigneeListState((prevState) =>
      prevState.filter((assignee) => assignee.id !== assigneeId)
    );
  };

  useEffect(() => {
    // formats date to be used for date input values
    const formatDate = (date) => {
      const dateSplit = date.split("/");
      const dateFormatted = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
      return dateFormatted;
    };
    setEventState((prevState) => ({
      ...prevState,
      start_date: formatDate(eventState.start_date),
      end_date: formatDate(eventState.end_date),
    }));
    let minDate = new Date(formatDate(eventState.start_date));
    setMinDateState(minDate.toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    if (new Date(eventState.start_date) > new Date(eventState.end_date)) {
      setEventState((prevState) => ({
        ...prevState,
        end_date: "",
      }));
    }
  }, [eventState.start_date]);

  useEffect(() => {
    const { name, start_date, end_date, start_time, end_time } = eventState;
    if (
      name.trim().length > 0 &&
      validator.isDate(start_date) &&
      !validator.isEmpty(start_time) &&
      validator.isDate(end_date) &&
      !validator.isEmpty(end_time)
    ) {
      setSaveBtnState(true);
    } else {
      setSaveBtnState(false);
    }
  }, [eventState]);

  useEffect(() => {
    const { name, startDate, endDate, startTime, endTime } = taskState;
    if (
      name.trim().length > 0 &&
      validator.isDate(startDate) &&
      !validator.isEmpty(startTime) &&
      validator.isDate(endDate) &&
      !validator.isEmpty(endTime) &&
      productListState.length > 0
    ) {
      setAddTaskBtnState(true);
    } else {
      setAddTaskBtnState(false);
    }
  }, [taskState, productListState]);

  // View
  return (
    <div className="modal-background">
      {errNotifState && <ErrorNotif errMsg="Failed To Update." />}
      <div className="edit-event-modal-container">
        <div className="edit-event-modal-header-container">
          <h2 className="modal-header">
            {addTaskFormState ? "Add Task" : "Edit Event"}
          </h2>
          <hr className="modal-header-line" />
        </div>
        <div className="edit-event-inner-container">
          {!addTaskFormState ? (
            <div className={`edit-event-form${animationState}`}>
              <div>
                <div className="edit-event-form-inputs">
                  <div className="edit-event-form-event-section">
                    <div className="edit-event-name-container">
                      <TextInput
                        labelName="Event Name"
                        fieldName="event-name"
                        valueProp={eventState.name}
                        onChangeFunc={(e) =>
                          setEventState((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                          }))
                        }
                        maxLengthProp={60}
                        isRequired={true}
                      />
                    </div>
                    <div className="edit-event-dates-container">
                      <DateInput
                        labelName="Start Date"
                        fieldName="start-date"
                        valueProp={eventState.start_date}
                        onChangeFunc={(e) =>
                          setEventState((prevState) => ({
                            ...prevState,
                            start_date: e.target.value,
                          }))
                        }
                        isRequired={true}
                        minDate={minDateState}
                      />
                      <TimeInput
                        labelName="*Start Time"
                        time={eventState.start_time}
                        handleTimeSelect={(time) =>
                          setEventState((prevState) => ({
                            ...prevState,
                            start_time: time,
                          }))
                        }
                        isChecked={startTimeAllDayCheckState}
                        handleCheck={() => {
                          setStartTimeAllDayCheckState(
                            !startTimeAllDayCheckState
                          );
                        }}
                      />
                      <DateInput
                        labelName="End Date"
                        fieldName="end-date"
                        valueProp={eventState.end_date}
                        onChangeFunc={(e) =>
                          setEventState((prevState) => ({
                            ...prevState,
                            end_date: e.target.value,
                          }))
                        }
                        isRequired={true}
                        minDate={convertToDateMinMax(eventState.start_date)}
                      />
                      <TimeInput
                        labelName="*End Time"
                        time={eventState.end_time}
                        handleTimeSelect={(time) =>
                          setEventState((prevState) => ({
                            ...prevState,
                            end_time: time,
                          }))
                        }
                        isChecked={endTimeAllDayCheckState}
                        handleCheck={() => {
                          setEndTimeAllDayCheckState(!endTimeAllDayCheckState);
                        }}
                      />
                    </div>
                  </div>

                  <div className="edit-event-form-event-section">
                    <div className="edit-event-description-container">
                      <LargeTextInput
                        labelName="Event Description"
                        fieldName="event-description"
                        valueProp={eventState.description || ""}
                        onChangeFunc={(e) =>
                          setEventState((prevState) => ({
                            ...prevState,
                            description: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
                <TaskSelector
                  eventDetails={eventState}
                  onClickFunc={handleAddTaskForm}
                />
              </div>
              <div className="edit-event-footer">
                <div className="edit-event-footer-section"></div>
                <div className="edit-event-footer-section">
                  {saveBtnState ? (
                    <button className="btn" onClick={handleSaveBtn}>
                      Save
                    </button>
                  ) : (
                    <button className="disabled-btn" disabled>
                      Save
                    </button>
                  )}
                </div>
                <div className="edit-event-footer-section">
                  <button
                    className="cancel-new-event-btn btn"
                    onClick={props.handleCancelBtn}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className={`edit-event-form${animationState}`}>
              <AddTaskForm
                taskState={taskState}
                taskNameFunc={(e) => {
                  setTaskState((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
                taskStartDateFunc={handleTaskStartDateChange}
                taskStartTimeFunc={(time) => {
                  setTaskState((prevState) => ({
                    ...prevState,
                    startTime: time,
                  }));
                }}
                taskStartTimeCheckFunc={() =>
                  setTaskState((prevState) => ({
                    ...prevState,
                    startTimeAllDay: !taskState.startTimeAllDay,
                  }))
                }
                taskEndDateFunc={(e) => {
                  setTaskState((prevState) => ({
                    ...prevState,
                    endDate: e.target.value,
                  }));
                }}
                taskEndTimeFunc={(time) => {
                  setTaskState((prevState) => ({
                    ...prevState,
                    endTime: time,
                  }));
                }}
                taskEndTimeCheckFunc={() =>
                  setTaskState((prevState) => ({
                    ...prevState,
                    endTimeAllDay: !taskState.endTimeAllDay,
                  }))
                }
                taskDescriptionFunc={(e) => {
                  setTaskState((prevState) => ({
                    ...prevState,
                    description: e.target.value,
                  }));
                }}
                minStartDate={minTaskStartDate}
                minEndDate={minTaskEndDate}
                maxDate={maxTaskDate}
                productList={productListState}
                selectedProducts={selectedProductsState}
                addToSelectedProducts={(e) =>
                  setSelectedProductsState((prevState) => [...prevState, e])
                }
                removeFromSelectedProducts={(e) =>
                  setSelectedProductsState((prevState) =>
                    prevState.filter((product) => product !== e)
                  )
                }
                addProductsBtnFunc={handleAddProductsBtn}
                productTagRemoveFunc={handleRemoveFromProductList}
                assigneeList={assigneeListState}
                handleAddAssigneeBtn={handleAddAssigneeBtn}
                handleRemoveAssigneeBtn={handleRemoveFromAssigneeList}
              />
              <div className="edit-event-footer">
                <div className="edit-event-footer-section">
                  <button
                    className="back-to-edit-event-btn btn"
                    onClick={handleBackBtn}
                  >
                    Back
                  </button>
                </div>
                <div className="edit-event-footer-section">
                  {addTaskBtnState ? (
                    <button className="btn" onClick={handleTaskSubmit}>
                      Add
                    </button>
                  ) : (
                    <button className="disabled-btn" disabled>
                      Add
                    </button>
                  )}
                </div>
                <div className="edit-event-footer-section">
                  <button
                    className="cancel-new-event-btn btn"
                    onClick={props.handleCancelBtn}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
