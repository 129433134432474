import { API_ENDPOINT } from "..";

export const getTaskAssignees = async (taskId) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}tasks/get-assignees/${taskId}`
    );
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
};
