import { API_ENDPOINT } from "..";

export const createNewNotification = async (data) => {
  try {
    const response = await fetch(`${API_ENDPOINT}notifications/create-notification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include"
    });
    const res = await response.json();
    return res;
  } catch(error) {
    console.log(error)
  }
};