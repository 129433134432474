import React, { useEffect, useState } from "react";
import "./editUserDetailsModal.css";
import { TextInput } from "../../../components/input-fields/text-input/TextInput";
import validator from "validator";
import { updateUserDetails } from "../../../api/user-calls/updateUserDetails";
import { updateCompanyDetails } from "../../../api/company-calls/updateCompanyDetails";
import { ErrorNotif } from "../../../components/notifications/error-notif/ErrorNotif";
import { sanitizeTextInput } from "../../../functions/input-sanitizers/sanitizeTextInput";
import { sanitizeNumberInput } from "../../../functions/input-sanitizers/sanitizeNumberInput";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../../../features/userSlice";
import { checkCompanyAbn } from "../../../api/company-calls/checkCompanyAbn";

export const EditUserDetailsModal = (props) => {
  // Model
  const dispatch = useDispatch();
  const { userDetails } = props;
  const initialUserDetailsState = {
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    email: userDetails.email,
    mobile: userDetails.mobile,
    companyName: userDetails.companyName,
    companyAbn: userDetails.companyAbn,
  };
  const [userDetailsState, setUserDetailsState] = useState(
    initialUserDetailsState
  );
  const [saveBtnState, setSaveBtnState] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorMsgState, setErrorMsgState] = useState("");
  // Controllers
  const handleSubmit = async () => {
    const { firstName, lastName, email, mobile, companyName, companyAbn } =
      userDetailsState;
    const data = {
      firstName,
      lastName,
      email,
      mobile,
    };
    const response = await updateUserDetails(data);
    if (response.success) {
      if (userDetails.role === "company_admin") {
        const companyData = {
          id: userDetails.companyId,
          name: companyName,
          abn: companyAbn,
        };
        if (companyAbn === userDetails.companyAbn) {
          const res = await updateCompanyDetails(companyData);
          if (res.success) {
            props.cancelBtnFunc();
            dispatch(getUserDetails());
          } else {
            setErrorMsgState("Server Error")
            setErrorState(true);
            setTimeout(() => setErrorState(false), 3500);
          }
        } else {
          const abnRes = await checkCompanyAbn({ companyAbn: companyAbn });
          if (abnRes.match) {
            const res = await updateCompanyDetails(companyData);
            if (res.success) {
              props.cancelBtnFunc();
              dispatch(getUserDetails());
            } else {
              setErrorMsgState("Server Error")
              setErrorState(true);
              setTimeout(() => setErrorState(false), 3500);
            }
          } else {
            setErrorMsgState("ABN is already taken.")
            setErrorState(true);
            setTimeout(() => setErrorState(false), 3500);
          }
        }
      } else {
        props.cancelBtnFunc();
        dispatch(getUserDetails());
      }
    } else {
      setErrorState(true);
      setTimeout(() => setErrorState(false), 3500);
    }
  };

  useEffect(() => {
    let changesMade;
    if (JSON.stringify(userDetailsState) === JSON.stringify(userDetails)) {
      changesMade = false;
    } else {
      changesMade = true;
    }
    if (!changesMade) {
      setSaveBtnState(false);
      return;
    }
    const { firstName, lastName, email, mobile, companyName, companyAbn } =
      userDetailsState;
    if (
      validator.isEmpty(firstName) ||
      validator.isEmpty(lastName) ||
      !validator.isEmail(email) ||
      !validator.isMobilePhone(mobile) ||
      validator.isEmpty(companyName) ||
      validator.isEmpty(companyAbn)
    ) {
      setSaveBtnState(false);
    } else {
      setSaveBtnState(true);
    }
  }, [userDetailsState]);
  // View
  return (
    <div className="modal-background">
      <div className="edit-user-details-modal-container">
        <div className="edit-user-details-header-container">
          <h2 className="modal-header">Edit Details</h2>
          <hr className="modal-header-line" />
        </div>
        <div className="user-details-inner-container">
          <div className="user-details-inputs-container">
            <TextInput
              fieldName="user-first-name"
              labelName="First Name"
              valueProp={userDetailsState.firstName}
              onChangeFunc={(e) =>
                setUserDetailsState({
                  ...userDetailsState,
                  firstName: sanitizeTextInput(e.target.value),
                })
              }
            />
            <TextInput
              fieldName="user-last-name"
              labelName="Last Name"
              valueProp={userDetailsState.lastName}
              onChangeFunc={(e) =>
                setUserDetailsState({
                  ...userDetailsState,
                  lastName: sanitizeTextInput(e.target.value),
                })
              }
            />
            <TextInput
              fieldName="user-email"
              labelName="Email"
              valueProp={userDetailsState.email}
              onChangeFunc={(e) =>
                setUserDetailsState({
                  ...userDetailsState,
                  email: e.target.value,
                })
              }
            />
            <TextInput
              fieldName="user-mobile"
              labelName="Mobile"
              valueProp={userDetailsState.mobile || ""}
              onChangeFunc={(e) =>
                setUserDetailsState({
                  ...userDetailsState,
                  mobile: sanitizeNumberInput(e.target.value),
                })
              }
            />
            {userDetails.role === "company_admin" && (
              <TextInput
                fieldName="user-company-name"
                labelName="Company Name"
                valueProp={userDetailsState.companyName}
                onChangeFunc={(e) =>
                  setUserDetailsState({
                    ...userDetailsState,
                    companyName: e.target.value,
                  })
                }
              />
            )}
            {userDetails.role === "company_admin" && (
              <TextInput
                fieldName="user-company-abn"
                labelName="Company ABN"
                valueProp={userDetailsState.companyAbn}
                onChangeFunc={(e) =>
                  setUserDetailsState({
                    ...userDetailsState,
                    companyAbn: sanitizeNumberInput(e.target.value),
                  })
                }
              />
            )}
            {errorState && <ErrorNotif errMsg={errorMsgState} />}
          </div>
          <div className="edit-user-details-btn-container">
            {saveBtnState ? (
              <button className="btn" onClick={handleSubmit}>
                Save
              </button>
            ) : (
              <button className="disabled-btn" disabled>
                Save
              </button>
            )}
            <button className="btn" onClick={props.cancelBtnFunc}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
