import React, { useEffect, useState } from "react";
import "./dayViewCalendar.css";
import { EventDetailsModal } from "../../../components/modals/event-details-modal/EventDetailsModal";
import { TaskDetailsModal } from "../../../components/modals/task-details-modal/TaskDetailsModal";

export const DayViewCalendar = (props) => {
  // Model
  const [eventsForDayState, setEventsForDayState] = useState([]);
  const [tasksForDayState, setTasksForDayState] = useState([]);
  const [dateState, setDateState] = useState(new Date());
  const [eventDetailsState, setEventDetailsState] = useState({});
  const [eventViewState, setEventViewState] = useState(false);
  const [taskDetailsState, setTaskDetailsState] = useState({});
  const [taskViewState, setTaskViewState] = useState(false);

  const currentDate = new Date().toLocaleDateString(undefined, {
    month: "long",
    weekday: "long",
    day: "numeric",
  });

  const times = [];
  for (let hour = 0; hour <= 23; hour++) {
    // Loop through the minutes (0 and 30)
    for (let minute = 0; minute <= 30; minute += 30) {
      const amOrPm = hour < 12 ? "AM" : "PM";

      // Convert the hour to 12-hour format
      const formattedHour = (hour % 12 === 0 ? 12 : hour % 12)
        .toString()
        .padStart(2, "0");

      // Format the minute as a string with leading zeros if necessary
      const formattedMinute = minute.toString().padStart(2, "0");

      // Create a time string in "hh:mm AM/PM" format
      const timeString = formattedHour + ":" + formattedMinute + " " + amOrPm;

      // Add the time string to the array
      times.push(timeString);
    }
  }

  // Controllers
  const handleGoToCurrentDate = () => {
    setDateState(new Date());
  };

  const handleDateChange = (days) => {
    const newDate = new Date(dateState);
    newDate.setDate(dateState.getDate() + days);
    setDateState(newDate);
  };

  const updateEventsAndTasks = (newDate) => {
    const eventsForDay = handleEventsForDay(newDate);
    const tasksForDay = handleTasksForDay(newDate);
    setEventsForDayState(eventsForDay);
    setTasksForDayState(tasksForDay);
  };

  const handleEventsForDay = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const events = props.events.filter((event) => {
      const startDate = event.start_date.split("/");
      const eventDay = parseInt(startDate[0]);
      const eventMonth = parseInt(startDate[1]) - 1; // Months are zero-based in JavaScript Date
      const eventYear = parseInt(startDate[2]);
      return eventDay === day && eventMonth === month && eventYear === year;
    });
    return events;
  };

  const handleTasksForDay = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const tasks = props.tasks.filter((task) => {
      const startDate = task.start_date.split("/");
      const taskDay = parseInt(startDate[0]);
      const taskMonth = parseInt(startDate[1]) - 1; // Months are zero-based in JavaScript Date
      const taskYear = parseInt(startDate[2]);
      return taskDay === day && taskMonth === month && taskYear === year;
    });
    return tasks;
  };

  const handleEventClick = (event) => {
    setEventViewState(true);
    setEventDetailsState(event);
  };

  const handleEventViewClose = () => {
    setEventViewState(false);
    setEventDetailsState({});
  };

  const handleTaskClick = (task) => {
    setTaskViewState(true);
    setTaskDetailsState(task);
  };

  const handleTaskViewClose = () => {
    setTaskViewState(false);
    setTaskDetailsState({});
  };

  useEffect(() => {
    updateEventsAndTasks(dateState);
  }, [dateState, props.events, props.tasks]);

  // View
  return (
    <div className="day-view-calendar">
      {eventViewState && (
        <EventDetailsModal
          eventDetails={eventDetailsState}
          handleCloseBtn={handleEventViewClose}
        />
      )}
      {taskViewState && (
        <TaskDetailsModal
          taskDetails={taskDetailsState}
          handleCloseBtn={handleTaskViewClose}
        />
      )}
      <div className="day-view-calendar-header">
        <button className="current-day-date" onClick={handleGoToCurrentDate}>
          {currentDate}
        </button>
        <div className="day-view-header-selector">
          <div className="day-view-selector-btn-container">
            <button
              className="day-view-selector-btn"
              onClick={() => handleDateChange(-1)}
            >
              <svg
                className="day-view-selector-btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 5.33 7"
              >
                <g>
                  <path d="m0,3.5c0-.31.15-.61.4-.8L3.73.2c.44-.33,1.07-.24,1.4.2.33.44.24,1.07-.2,1.4l-2.27,1.7,2.27,1.7c.44.33.53.96.2,1.4-.33.44-.96.53-1.4.2L.4,4.3c-.25-.19-.4-.48-.4-.8Z" />
                </g>
              </svg>
            </button>
          </div>
          <h4 className="selected-day-header">
            {dateState.toLocaleDateString(undefined, {
              month: "short",
              weekday: "short",
              day: "numeric",
            })}
          </h4>
          <div className="day-view-selector-btn-container">
            <button
              className="day-view-selector-btn"
              onClick={() => handleDateChange(1)}
            >
              <svg
                className="day-view-selector-btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 5.33 7"
              >
                <g>
                  <path d="m5.33,3.5c0-.31-.15-.61-.4-.8L1.6.2C1.16-.13.53-.04.2.4-.13.84-.04,1.47.4,1.8l2.27,1.7L.4,5.2c-.44.33-.53.96-.2,1.4.33.44.96.53,1.4.2l3.33-2.5c.25-.19.4-.48.4-.8Z" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="day-view-calendar-container scrollable-frame">
        <div className="day-view-calendar-inner-container">
          {times.map((time) => {
            return (
              <div key={time}>
                <div className="time-details">
                  <span className="time-header">{time}</span>
                  <hr className="time-header-divider" />
                </div>
                <div className="day-view-calendar-list-container">
                  {eventsForDayState.map((event) => {
                    if (event.start_time === time) {
                      return (
                        <div
                          key={event.id}
                          className="day-view-event-task-container"
                          onClick={() => handleEventClick(event)}
                        >
                          <span className="day-view-event-task-label">
                            Event
                          </span>
                          <span>{event.name}</span>
                        </div>
                      );
                    }
                  })}
                  {tasksForDayState.map((task) => {
                    if (task.start_time === time) {
                      return (
                        <div
                          key={task.id}
                          className="day-view-event-task-container"
                          onClick={() => handleTaskClick(task)}
                        >
                          <span className="day-view-event-task-label">
                            Task
                          </span>
                          <span>{task.name}</span>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
