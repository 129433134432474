import React, { useState, useEffect } from "react";
import "./eventRow.css";
import { trimStringToMaxLength } from "../../../../functions/trimStringToMaxLength";
import { getAllTasksByEventId } from "../../../../api/task-calls/getAllTasksByEventId";
import { IsLoadingMessage } from "../../../../components/no-content-messages/is-loading-message/IsLoadingMessage";
import { TaskForEventRow } from "./task-for-event-row/TaskForEventRow";
import { OptionButton } from "../../../../components/option-button/OptionButton";

export const EventRow = (props) => {
  // Model
  const { event } = props;
  const [maxNameLength, setMaxNameLength] = useState("");
  const eventName = trimStringToMaxLength(event.name, maxNameLength);
  const [maxDescriptionLength, setMaxDescriptionLength] = useState("");
  const eventDescription = trimStringToMaxLength(
    event.description,
    maxDescriptionLength
  );
  const [eventStatusState, setEventStatusState] = useState(event.status);
  const [eventStatusClassState, setEventStatusClassState] = useState("");

  // -----------------------------------------------------
  const [tasksState, setTasksState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [showTasksState, setShowTasksState] = useState(false);

  const taskHeaders = [
    { id: "task-name-header", label: "Task Name" },
    { id: "task-start-date-header", label: "Start Date" },
    { id: "task-end-header", label: "End Date" },
    { id: "task-status", label: "Status" },
  ];

  // Controllers
  const handleShowTasks = async () => {
    setShowTasksState(false);
    setTasksState([]);
    props.handleExpand();
    if (!props.isExpanded) {
      await new Promise((resolve) => setTimeout(resolve, 150));
      setShowTasksState(true);
      setIsLoadingState(true);
      const results = await getAllTasksByEventId(event.id);
      setTasksState(results);
      setIsLoadingState(false);
    }
  };

  const taskStatusState = (task) => {
    const status = task.task_status;
    if (status === "Upcoming") {
      return { className: " status-upcoming", status };
    } else if (status == "In Progress") {
      return { className: " status-in-progress", status };
    } else if (status === "Not Completed") {
      return { className: " status-incompleted", status };
    } else if (status === "Completed") {
      return { className: " status-completed", status };
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1300) {
      setMaxNameLength(8);
      setMaxDescriptionLength(15);
    } else if (window.innerWidth < 1400) {
      setMaxNameLength(15);
      setMaxDescriptionLength(35);
    } else if (window.innerWidth < 1624) {
      setMaxNameLength(20);
      setMaxDescriptionLength(45);
    } else if (window.innerWidth < 1700) {
      setMaxNameLength(30);
      setMaxDescriptionLength(60);
    } else if (window.innerWidth < 1870) {
      setMaxNameLength(30);
      setMaxDescriptionLength(70);
    } else {
      setMaxNameLength(40);
      setMaxDescriptionLength(80);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the maximum length based on the window size
      if (window.innerWidth < 1300) {
        setMaxNameLength(8);
        setMaxDescriptionLength(15);
      } else if (window.innerWidth < 1400) {
        setMaxNameLength(15);
        setMaxDescriptionLength(35);
      } else if (window.innerWidth < 1624) {
        setMaxNameLength(20);
        setMaxDescriptionLength(45);
      } else if (window.innerWidth < 1700) {
        setMaxNameLength(30);
        setMaxDescriptionLength(60);
      } else if (window.innerWidth < 1870) {
        setMaxNameLength(30);
        setMaxDescriptionLength(70);
      } else {
        setMaxNameLength(40);
        setMaxDescriptionLength(80);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const status = event.status;
    setEventStatusState(status);
    if (status === "Upcoming") {
      setEventStatusClassState(" status-upcoming");
    } else if (status === "In Progress") {
      setEventStatusClassState(" status-in-progress");
    } else if (status === "Not Completed") {
      setEventStatusClassState(" status-incompleted");
    } else if (status === "Completed") {
      setEventStatusClassState(" status-completed");
    }
  }, []);

  useEffect(() => {
    if (!props.isExpanded) {
      setShowTasksState(false);
    }
  }, [props.isExpanded]);

  // View
  return (
    <div
      className={`event-row-background${
        props.isExpanded ? " is-expanded" : ""
      }`}
    >
      <div className="event-row">
        <div className={"event-row-event-details"}>
          <div className="segment">
            <button className="expand-event-btn" onClick={handleShowTasks}>
              <svg
                className={`expand-event-icon${
                  props.isExpanded ? " down-position" : " up-position"
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 7 5.33"
                aria-label="expand event button icon"
              >
                <g>
                  <path d="m3.5,5.33c-.31,0-.61-.15-.8-.4L.2,1.6C-.13,1.16-.04.53.4.2.84-.13,1.47-.04,1.8.4l1.7,2.27L5.2.4c.33-.44.96-.53,1.4-.2.44.33.53.96.2,1.4l-2.5,3.33c-.19.25-.48.4-.8.4Z" />
                </g>
              </svg>
            </button>
          </div>
          <div className="segment">
            <span>{eventName}</span>
          </div>
          <div className="segment">
            <span>{event.start_date}</span>
          </div>
          <div className="segment">
            <span>{event.end_date}</span>
          </div>
          <div className="segment">
            <span>{eventDescription}</span>
          </div>
          <div className="segment">
            <div className={`status-tag${eventStatusClassState}`}>
              <span>{eventStatusState}</span>
            </div>
          </div>
          <div className="segment">
            <div className="event-manage-btn-row">
              <button className="view-btn" onClick={props.handleViewBtn}>
                <svg
                  className="view-btn-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 10 6"
                >
                  <g>
                    <path d="m5,0C2.86,0,1.01,1.21,0,3c1.01,1.79,2.86,3,5,3s3.99-1.21,5-3c-1.01-1.79-2.86-3-5-3Zm0,5.5h0c-1.38,0-2.5-1.12-2.5-2.5S3.62.5,5,.5h0c1.38,0,2.5,1.12,2.5,2.5s-1.12,2.5-2.5,2.5Z" />
                    <circle cx="5" cy="3" r="1" />
                  </g>
                </svg>
              </button>
              <OptionButton
                type="event"
                handleEdit={props.handleEditBtn}
                handleDownload={props.handleDownloadBtn}
                handleDelete={props.handleDeleteBtn}
              />
            </div>
          </div>
        </div>
        {showTasksState && (
          <div className="tasks-for-event-row">
            <div className="tasks-for-event-row-inner">
              <div className="tasks-for-event-header-group">
                <div className="tasks-for-event-header-group-inner">
                  {taskHeaders.map((header) => (
                    <h3 className="task-header" id={header.id} key={header.id}>
                      {header.label}
                    </h3>
                  ))}
                </div>
              </div>
              <hr className="tasks-for-event-divider" />
              <div className="tasks-for-event-list scrollable-frame">
                {!isLoadingState ? (
                  tasksState.map((task) => {
                    const taskStatus = taskStatusState(task);
                    return (
                      <TaskForEventRow
                        key={task.id}
                        task={task}
                        taskStatus={taskStatus}
                      />
                    );
                  })
                ) : (
                  <IsLoadingMessage isLoading={isLoadingState} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
