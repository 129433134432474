import React, { useState, useEffect, useRef } from "react";
import "./optionButton.css";

export const OptionButton = (props) => {
  // Model
  const [showOptionsState, setShowOptionsState] = useState(false);
  const dropdownRef = useRef();
  // Controllers
  const handleClick = () => {
    setShowOptionsState(true);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        showOptionsState &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setShowOptionsState(false);
      }
    };

    if (showOptionsState) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showOptionsState]);
  // View
  return (
    <div className="options-btn-container" ref={dropdownRef}>
      <button className="options-btn" onClick={handleClick}>
        <svg
          className="options-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 3"
        >
          <g>
            <circle cx="1.5" cy="1.5" r="1.5" />
            <circle cx="5" cy="1.5" r="1.5" />
            <circle cx="8.5" cy="1.5" r="1.5" />
          </g>
        </svg>
      </button>
      {showOptionsState && (
        <div className="options-list-container">
          {props.type === "event" && (
            <ul>
              <li
                className="download-option"
                onClick={() => {
                  setShowOptionsState(false);
                  props.handleDownload();
                }}
              >
                <span>Download .xls</span>
              </li>
              <li
                className="edit-option"
                onClick={() => {
                  setShowOptionsState(false);
                  props.handleEdit();
                }}
              >
                <span>Edit</span>
              </li>
              <li
                className="delete-option"
                onClick={() => {
                  setShowOptionsState(false);
                  props.handleDelete();
                }}
              >
                <span>Delete</span>
              </li>
            </ul>
          )}
          {props.type === "task" && (
            <ul>
              <li
                className="download-option"
                onClick={() => {
                  setShowOptionsState(false);
                  props.handleDownload();
                }}
              >
                <span>Download .xls</span>
              </li>
              <li
                className="download-option"
                onClick={() => {
                  setShowOptionsState(false);
                  props.handleExport();
                }}
              >
                <span>Export .ics</span>
              </li>
              <li
                className="edit-option"
                onClick={() => {
                  setShowOptionsState(false);
                  props.handleEdit();
                }}
              >
                <span>Edit</span>
              </li>
              <li
                className="delete-option"
                onClick={() => {
                  setShowOptionsState(false);
                  props.handleDelete();
                }}
              >
                <span>Delete</span>
              </li>
            </ul>
          )}
          {props.type === "user" && (
            <ul>
              <li
                className="reset-password-option"
                onClick={() => {
                  setShowOptionsState(false);
                  props.handleResetPassword();
                }}
              >
                <span>Reset Password</span>
              </li>
              {/* <li
                className="delete-option"
                onClick={() => {
                  setShowOptionsState(false);
                  props.handleDelete();
                }}
              >
                <span>Delete</span>
              </li> */}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
