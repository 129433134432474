import React, { useState, useEffect } from "react";
import "./editTaskModal.css";
import { useSelector } from "react-redux";
import { TextInput } from "../../../components/input-fields/text-input/TextInput";
import { DateInput } from "../../../components/input-fields/date-input/DateInput";
import { TimeInput } from "../../../components/input-fields/time-input/TimeInput";
import { LargeTextInput } from "../../../components/input-fields/large-text-input/LargeTextInput";
import { AssigneeSelector } from "../../../components/selectors/assignee-selector/AssigneeSelector";
import { ErrorNotif } from "../../../components/notifications/error-notif/ErrorNotif";
import { updateTaskByTaskId } from "../../../api/task-calls/updateTaskByTaskId";
import { convertToDateMinMax } from "../../../functions/convertToDateMinMax";
import validator from "validator";
import { IsLoadingMessage } from "../../../components/no-content-messages/is-loading-message/IsLoadingMessage";
import { getTaskAssignees } from "../../../api/task-calls/getTaskAssignees";
import { updateAssigneesForTask } from "../../../functions/updateAssigneesForTask";
import { formatDateTimeForDatabase } from "../../../functions/formatDateTimeForDatabase";

export const EditTaskModal = (props) => {
  // Model
  const [taskState, setTaskState] = useState(props.task);
  const [startTimeAllDayCheckState, setStartTimeAllDayCheckState] = useState(
    taskState.task_start_time === "12:00 AM"
  );
  const [endTimeAllDayCheckState, setEndTimeAllDayCheckState] = useState(
    taskState.task_end_time === "11:30 PM"
  );
  const [errNotifState, setErrNotifState] = useState(false);
  const [saveBtnState, setSaveBtnState] = useState(true);
  const [originalAssigneeListState, setOriginalAssigneeListState] = useState(
    []
  );
  const [assigneeListState, setAssigneeListState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const firstName = useSelector((state) => state.user.userDetails.firstName);
  const lastName = useSelector((state) => state.user.userDetails.lastName);
  const fullName = firstName + " " + lastName;

  // Controllers
  const handleSaveBtn = async () => {
    const startDateTime = formatDateTimeForDatabase(
      taskState.task_start_date,
      taskState.task_start_time
    );
    const endDateTime = formatDateTimeForDatabase(
      taskState.task_end_date,
      taskState.task_end_time
    );
    const data = {
      name: taskState.task_name,
      startDate: startDateTime,
      endDate: endDateTime,
      description: taskState.tasK_description,
    };
    const result = await updateTaskByTaskId(taskState.task_id, data);
    if (result.success) {
      updateAssigneesForTask(
        originalAssigneeListState,
        assigneeListState,
        taskState.task_id,
        taskState.task_name,
        fullName
      );
      props.handleSaveBtn();
    } else {
      setErrNotifState(true);
      setTimeout(() => setErrNotifState(false), 3500);
    }
  };

  const handleAddAssigneeBtn = (assignee) => {
    if (!assigneeListState.some((item) => item.id === assignee.id)) {
      setAssigneeListState((prevState) => [...prevState, assignee]);
    }
  };

  const handleRemoveAssigneeBtn = (assigneeId) => {
    setAssigneeListState((prevState) =>
      prevState.filter((assignee) => assignee.id !== assigneeId)
    );
  };

  const getAssignees = async (taskId) => {
    setIsLoadingState(true);
    const response = await getTaskAssignees(taskId);
    if (response.success) {
      setOriginalAssigneeListState(response.data);
      setAssigneeListState(response.data);
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    getAssignees(taskState.task_id);
  }, []);

  useEffect(() => {
    // formats date to be used for date input values
    const formatDate = (date) => {
      const dateSplit = date.split("/");
      const dateFormatted = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
      return dateFormatted;
    };
    setTaskState((prevState) => ({
      ...prevState,
      task_start_date: formatDate(taskState.task_start_date),
      task_end_date: formatDate(taskState.task_end_date),
    }));
  }, []);

  useEffect(() => {
    if (
      new Date(taskState.task_start_date) > new Date(taskState.task_end_date)
    ) {
      setTaskState((prevState) => ({
        ...prevState,
        task_end_date: "",
      }));
    }
  }, [taskState.task_start_date]);

  useEffect(() => {
    const {
      task_name,
      task_start_date,
      task_end_date,
      task_start_time,
      task_end_time,
    } = taskState;
    if (
      task_name.trim().length > 0 &&
      validator.isDate(task_start_date) &&
      !validator.isEmpty(task_start_time) &&
      validator.isDate(task_end_date) &&
      !validator.isEmpty(task_end_time)
    ) {
      setSaveBtnState(true);
    } else {
      setSaveBtnState(false);
    }
  }, [taskState]);

  // View
  return (
    <div className="modal-background">
      {errNotifState && <ErrorNotif errMsg="Failed To Update." />}
      <div className="edit-task-modal-container">
        <div className="edit-task-modal-header-container">
          <h2 className="modal-header">Edit Task</h2>
          <hr className="modal-header-line" />
        </div>
        {!isLoadingState ? (
          <div className="edit-task-inner-container">
            <div className="edit-task-form">
              <div className="edit-task-form-inputs">
                <div className="edit-task-form-inputs-section">
                  <div className="edit-task-name-container">
                    <TextInput
                      labelName="Task Name"
                      fieldName="task-name"
                      valueProp={taskState.task_name}
                      onChangeFunc={(e) =>
                        setTaskState((prevState) => ({
                          ...prevState,
                          task_name: e.target.value,
                        }))
                      }
                      maxLengthProp={60}
                      isRequired={true}
                    />
                  </div>
                  <div className="edit-task-dates-container">
                    <DateInput
                      labelName="Start Date"
                      fieldName="start-date"
                      valueProp={taskState.task_start_date}
                      onChangeFunc={(e) =>
                        setTaskState((prevState) => ({
                          ...prevState,
                          task_start_date: e.target.value,
                        }))
                      }
                      isRequired={true}
                      minDate={convertToDateMinMax(taskState.event_start_date)}
                      maxDate={convertToDateMinMax(taskState.event_end_date)}
                    />
                    <TimeInput
                      labelName="*Start Time"
                      time={taskState.task_start_time}
                      handleTimeSelect={(time) =>
                        setTaskState((prevState) => ({
                          ...prevState,
                          task_start_time: time,
                        }))
                      }
                      isChecked={startTimeAllDayCheckState}
                      handleCheck={() => {
                        setStartTimeAllDayCheckState(
                          !startTimeAllDayCheckState
                        );
                      }}
                    />
                    <DateInput
                      labelName="End Date"
                      fieldName="end-date"
                      valueProp={taskState.task_end_date}
                      onChangeFunc={(e) =>
                        setTaskState((prevState) => ({
                          ...prevState,
                          task_end_date: e.target.value,
                        }))
                      }
                      isRequired={true}
                      minDate={convertToDateMinMax(taskState.task_start_date)}
                      maxDate={convertToDateMinMax(taskState.event_end_date)}
                    />
                    <TimeInput
                      labelName="*End Time"
                      time={taskState.task_end_time}
                      handleTimeSelect={(time) =>
                        setTaskState((prevState) => ({
                          ...prevState,
                          task_end_time: time,
                        }))
                      }
                      isChecked={endTimeAllDayCheckState}
                      handleCheck={() => {
                        setEndTimeAllDayCheckState(!endTimeAllDayCheckState);
                      }}
                    />
                  </div>
                </div>
                <div className="edit-task-form-inputs-section">
                  <div className="edit-task-description-container">
                    <LargeTextInput
                      labelName="Task Description"
                      fieldName="task-description"
                      valueProp={taskState.task_description || ""}
                      onChangeFunc={(e) =>
                        setTaskState((prevState) => ({
                          ...prevState,
                          task_description: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
              <AssigneeSelector
                assigneeList={assigneeListState}
                handleAddAssigneeBtn={handleAddAssigneeBtn}
                handleRemoveAssigneeBtn={handleRemoveAssigneeBtn}
              />
            </div>
            <div className="edit-task-footer">
              <div className="edit-task-footer-section"></div>
              <div className="edit-task-footer-section">
                {saveBtnState ? (
                  <button className="btn" onClick={handleSaveBtn}>
                    Save
                  </button>
                ) : (
                  <button className="disabled-btn" disabled>
                    Save
                  </button>
                )}
              </div>
              <div className="edit-event-footer-section">
                <button className="btn" onClick={props.handleCancelBtn}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : (
          <IsLoadingMessage isLoading={isLoadingState} />
        )}
      </div>
    </div>
  );
};
