export const trimStringToMaxLength = (stringValue, maxLength) => {
  if (stringValue === null) {
    return;
  }
  if (stringValue.length <= maxLength) {
    return stringValue;
  }

  let slicedString = stringValue.slice(0, maxLength);

  // Find the index of the last space character within the maximum length
  const lastSpaceIndex = slicedString.lastIndexOf(" ");
  if (lastSpaceIndex !== -1) {
    slicedString = slicedString.slice(0, lastSpaceIndex);
  }

  if (stringValue.length > maxLength) {
    slicedString += "...";
  }
  return slicedString;
};
