import React, { useState } from "react";
import "./passwordInput.css";

export const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (props.signIn) {
        // Allow form submission when "Enter" is pressed inside the password input
        const inputElement = document.getElementById(
          props.fieldName + "-input"
        );
        inputElement.blur(); // Remove focus from the input field to avoid triggering the toggle
        props.handleSubmit();
      }
    }
  };

  return (
    <div className="password-input-container">
      <label className="input-label" htmlFor={props.fieldName + "-input"}>
        {`*${props.labelName}`}
      </label>
      <div className="password-input-container-inner">
        <input
          type={showPassword ? "text" : "password"}
          id={props.fieldName + "-input"}
          name={props.fieldName + "-input"}
          className="password-input"
          placeholder={props.labelName}
          value={props.valueProp}
          onChange={props.onChangeFunc}
          onKeyDown={handleKeyPress}
          autoComplete={props.autoComplete || "new-password"}
          required
        />
        <button className="visibility-button" onClick={handleTogglePassword}>
          <svg
            className={
              showPassword
                ? "visibility-button-icon hidden"
                : "visibility-button-icon visible"
            }
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 6"
          >
            <g>
              <path d="m5,0C2.86,0,1.01,1.21,0,3c1.01,1.79,2.86,3,5,3s3.99-1.21,5-3c-1.01-1.79-2.86-3-5-3Zm0,5.5h0c-1.38,0-2.5-1.12-2.5-2.5S3.62.5,5,.5h0c1.38,0,2.5,1.12,2.5,2.5s-1.12,2.5-2.5,2.5Z" />
              <circle cx="5" cy="3" r="1" />
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};
