import { createTaskUserLink } from "../api/task-calls/createTaskUserLink";
import { deleteTaskUserLink } from "../api/task-calls/deleteTaskUserLink";
import { logTasks } from "../api/log-calls/logTasks";
import { getTimeStamp } from "../functions/getTimeStamp";
import { createNewNotification } from "../api/notification-calls/createNewNotification";
import { generateUniqueId } from "./generateUniqueId";

export const updateAssigneesForTask = async (
  originalList,
  newList,
  taskId,
  taskName,
  userName
) => {
  if (JSON.stringify(originalList) === JSON.stringify(newList)) {
    return;
  }

  // handles removal of assignees
  for (let user of originalList) {
    if (!newList.some((item) => item.id === user.id)) {
      const res = await deleteTaskUserLink(taskId, { userId: user.id });
      await logTasks({
        taskId,
        action: `Unassigned user ${user.id} from task.`,
        response: res.success ? "Success" : "Fail",
        dateTime: getTimeStamp(),
      });
      if (res.success) {
        const notifId = await generateUniqueId(24, "notifications");
        await createNewNotification({
          id: notifId,
          recipientId: user.id,
          message: `You have been unassigned from task ${taskName} by ${userName}.`,
          createdAt: getTimeStamp(),
        });
      }
    }
  }
  // handles new assignees
  for (let user of newList) {
    if (!originalList.some((item) => item.id === user.id)) {
      const res = await createTaskUserLink({
        taskId,
        userId: user.id,
        role: "assignee",
      });
      await logTasks({
        taskId,
        action: `Assigned user ${user.id} to task.`,
        response: res.success ? "Success" : "Fail",
        dateTime: getTimeStamp(),
      });
      if (res.success) {
        const notifId = await generateUniqueId(24, "notifications");
        await createNewNotification({
          id: notifId,
          recipientId: user.id,
          message: `You have been assigned to task ${taskName} by ${userName}.`,
          createdAt: getTimeStamp(),
        });
      }
    }
  }
};
