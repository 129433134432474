import React, { useEffect, useState } from "react";
import "./tasksPage.css";
import { PageTemplate } from "../../components/page-template/PageTemplate";
import { Frame } from "../../components/frame/Frame";
import { NavLink } from "react-router-dom";
import { TaskSearchBar } from "../../components/search-bars/task-search-bar/TaskSearchBar";
import { TasksList } from "./tasks-list/TasksList";
import { EditTaskModal } from "./edit-task-modal/EditTaskModal";
import { getAllTasksById } from "../../api/task-calls/getAllTasksById";
import { getAllTasksByFilter } from "../../api/task-calls/getAllTasksByFilter";
import { bulkDownloadTasksData } from "../../api/task-calls/bulkDownloadTasksData";

export const TasksPage = () => {
  // Model
  const [tasksState, setTasksState] = useState([]);
  const [taskToEditState, setTaskToEditState] = useState({});
  const [editTaskModalState, setEditTaskModalState] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState([]);

  const taskHeaders = [
    { id: "task-name-header", label: "Task Name" },
    { id: "task-start-date-header", label: "Start Date" },
    { id: "task-end-header", label: "End Date" },
    { id: "task-event-name-header", label: "Event Name" },
    { id: "task-status-header", label: "Status" },
    { id: "task-manage-header", label: "Manage" },
  ];

  // Controllers
  const getTasksData = async () => {
    setIsLoadingState(true);
    const result = await getAllTasksById();
    setTasksState(result);
    setIsLoadingState(false);
  };

  const getTasksByFilterFunc = async (type, term) => {
    setIsLoadingState(true);
    const result = await getAllTasksByFilter(type, term);
    setTasksState(result);
    setIsLoadingState(false);
  };

  const handleEditBtn = (task) => {
    setEditTaskModalState(true);
    setTaskToEditState(task);
  };

  const handleEditTaskCancelBtn = () => {
    setEditTaskModalState(false);
    setTaskToEditState({});
  };

  const handleEditTaskSaveBtn = () => {
    setEditTaskModalState(false);
    setTaskToEditState({});
    getTasksData();
  };

  const handleBulkDownloadBtn = () => {
    bulkDownloadTasksData();
  };

  useEffect(() => {
    getTasksData();
  }, []);

  // View
  return (
    <PageTemplate pageHeader="Tasks" layout="single-layout">
      {editTaskModalState && (
        <EditTaskModal
          task={taskToEditState}
          handleCancelBtn={handleEditTaskCancelBtn}
          handleSaveBtn={handleEditTaskSaveBtn}
        />
      )}
      <Frame fullSize={true}>
        <div className="tasks-page-container">
          <div className="tasks-selector-container">
            <div className="tasks-selector-inner-container">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "tasks-selector-btn selected"
                    : "tasks-selector-btn"
                }
                to="/events"
              >
                My Events
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "tasks-selector-btn selected"
                    : "tasks-selector-btn"
                }
                to="/tasks"
              >
                My Tasks
              </NavLink>
            </div>
          </div>
          <div className="tasks-inner-container">
            <div className="tasks-content-container">
              <div className="tasks-top-section-container">
                <TaskSearchBar
                  getAllTasksFunc={getTasksData}
                  filterTasksFunc={getTasksByFilterFunc}
                />
                {tasksState.length > 0 ? (
                  <button className="btn" onClick={handleBulkDownloadBtn}>
                    Bulk Download
                  </button>
                ) : (
                  <button className="disabled-btn" disabled>
                    Bulk Download
                  </button>
                )}
              </div>
              <div className="tasks-list-container">
                <div className="tasks-header-group-container">
                  <div className="tasks-header-group">
                    <div className="tasks-header-group-inner ">
                      {taskHeaders.map((header) => (
                        <h3
                          className="event-header"
                          id={header.id}
                          key={header.id}
                        >
                          {header.label}
                        </h3>
                      ))}
                    </div>
                  </div>
                </div>
                <hr className="task-header-divider" />
                <div className="tasks-list scrollable-frame">
                  <TasksList
                    tasksData={tasksState}
                    getTasksFunc={getTasksData}
                    getTasksByFilterFunc={getTasksByFilterFunc}
                    isLoading={isLoadingState}
                    handleEditBtn={handleEditBtn}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Frame>
    </PageTemplate>
  );
};
