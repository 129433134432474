import React, { useState, useEffect } from "react";
import "./productRow.css";
import { ItemRow } from "../../../../components/item-row/ItemRow";
import { ProductOptionBtn } from "./product-option-btn/ProductOptionBtn";
import { trimStringToMaxLength } from "../../../../functions/trimStringToMaxLength";

export const ProductRow = (props) => {
  // Model
  const { product } = props;
  const [maxProductNameLength, setMaxProductNameLength] = useState("");
  const productName = trimStringToMaxLength(
    product.long_name,
    maxProductNameLength
  );
  // Controllers
  useEffect(() => {
    if (window.innerWidth < 1300) {
      setMaxProductNameLength(10);
    } else if (window.innerWidth < 1400) {
      setMaxProductNameLength(20);
    } else if (window.innerWidth < 1624) {
      setMaxProductNameLength(30);
    } else if (window.innerWidth < 1824) {
      setMaxProductNameLength(40);
    } else {
      setMaxProductNameLength(60);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the maximum length based on the window size
      if (window.innerWidth < 1300) {
        setMaxProductNameLength(10);
      } else if (window.innerWidth < 1400) {
        setMaxProductNameLength(20);
      } else if (window.innerWidth < 1624) {
        setMaxProductNameLength(30);
      } else if (window.innerWidth < 1824) {
        setMaxProductNameLength(40);
      } else {
        setMaxProductNameLength(60);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // View
  return (
    <ItemRow>
      <div className="product-row">
        <div className="segment">
          <span>{productName}</span>
        </div>
        <div className="segment">
          <span>{product.primary_identifier_type}</span>
        </div>
        <div className="segment">
          <span>{product.primary_identifier}</span>
        </div>
        <div className="segment">
          <span>{product.fund_manager}</span>
        </div>
        <div className="segment">
          <span>{product.status}</span>
        </div>
        <div className="segment">
          <ProductOptionBtn
            handleEdit={() => props.handleEditBtn(product)}
            handleDelete={() => props.handleDeleteBtn(product.id)}
          />
        </div>
      </div>
    </ItemRow>
  );
};
