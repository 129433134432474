import { API_ENDPOINT } from "..";

export const getTaskStatusByTaskId = async (taskId) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}tasks/get-task-status/${taskId}`,
      {
        credentials: "include",
      }
    );
    let result = await response.json();
    if (result.success) {
      return result.data;
    }
  } catch (err) {
    console.error(err);
  }
};
