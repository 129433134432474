import React, { useState } from "react";
import "./inviteUserForm.css";
import { TextInput } from "../../../components/input-fields/text-input/TextInput";
import { useSelector } from "react-redux";
import { sendEmailInvite } from "../../../api/email-calls/sendEmailInvite";
import { ErrorNotif } from "../../../components/notifications/error-notif/ErrorNotif";
import { SuccessNotif } from "../../../components/notifications/success-notif/SuccessNotif";
import { ClipLoader } from "react-spinners";
import validator from "validator";

export const InviteUserForm = () => {
  // Model
  const [inviteEmailState, setInviteEmailState] = useState("");
  const firstName = useSelector((state) => state.user.userDetails.firstName);
  const lastName = useSelector((state) => state.user.userDetails.lastName);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [errNotifState, setErrNotifState] = useState(false);
  const [successNotifState, setSuccessNotifState] = useState(false);
  const [msgState, setMsgState] = useState("");

  // Controllers
  const handleSubmit = async () => {
    setIsLoadingState(true);
    const fullName = firstName + " " + lastName;
    const data = { forEmail: inviteEmailState, user: fullName };
    const response = await sendEmailInvite(data);
    if (response.success) {
      setInviteEmailState("");
      setIsLoadingState(false);
      setMsgState(response.message);
      setSuccessNotifState(true);
      setTimeout(() => setSuccessNotifState(false), 3500);
    } else {
      setIsLoadingState(false);
      setMsgState(response.message);
      setErrNotifState(true);
      setTimeout(() => setErrNotifState(false), 3500);
    }
  };
  // View
  return (
    <div className="invite-user-form-container ">
      {errNotifState && <ErrorNotif errMsg={msgState} />}
      {successNotifState && <SuccessNotif successMsg={msgState} />}
      <div className="invite-email-container">
        <TextInput
          labelName="Email"
          fieldName="invite-email"
          valueProp={inviteEmailState}
          onChangeFunc={(e) => setInviteEmailState(e.target.value)}
        />
      </div>
      {isLoadingState ? (
        <ClipLoader
          size={20}
          loading={isLoadingState}
          color="var(--activeGrey)"
        />
      ) : validator.isEmail(inviteEmailState) ? (
        <button className="btn" onClick={handleSubmit}>
          Send Invite
        </button>
      ) : (
        <button className="disabled-btn">Send Invite</button>
      )}
    </div>
  );
};
