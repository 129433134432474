import { API_ENDPOINT } from "..";

export const createNewUser = async (data) => {
  try {
    const response = await fetch(`${API_ENDPOINT}users/create-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
};
