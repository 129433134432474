import { API_ENDPOINT } from "..";

export const createEventUserLink = async (data) => {
  try {
    const response = await fetch(`${API_ENDPOINT}events/events-users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error("Failed to create event/user link");
    }
    const res = await response.json();
    return res;
  } catch (error) {
    console.error(error);
  }
};