import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import opusLogo from "../../images/opus-logo.png";
import { SignUpForm } from "./sign-up-form/SignUpForm";
import "./signUpPage.css";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../api/user-calls/isLoggedIn";

export const SignUpPage = () => {
  // Model
  const navigate = useNavigate();
  // Controller
  const isUserLoggedIn = async () => {
    const result = await isLoggedIn();
    if (result.loggedIn) {
      navigate("/dashboard");
    }
  };
  useEffect(() => {
    isUserLoggedIn();
  }, []);
  // View
  return (
    <div className="sign-up-page-container">
      <section className="sign-up-page-left-container">
        <div className="sign-up-page-logo-container">
          <img className="sign-up-page-logo" src={opusLogo} alt="opus" />
        </div>
        <h1>Sign Up</h1>     
        <SignUpForm />
        <div className="sign-in-link-container">
          <span>Have an account?</span>
          <Link to="/sign-in" id="sign-in-link-alt">
            Sign In
          </Link>
        </div>
      </section>
      <section className="sign-up-page-right-container">
        <h2>Have an Account?</h2>
        <Link to="/sign-in" className="btn" id="sign-in-link">
          Sign In
        </Link>
      </section>
    </div>
  );
};
